import { MOBILE_TYPE_OPTIONS } from '../common/utilities/constants';
import { startsWith } from 'lodash';

export const getCookie = (key) => {
  const name = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return null;
};

export const setCookie = (key, value, expires = 0, domain = null) => {
  const expireStr = expires ? `;expires=${expires.toUTCString()}` : '';
  const pathStr = domain ? `;path=/;domain=${domain}` : ';path=/';
  document.cookie = `${key}=${value}${expireStr}${pathStr}`;
};

export const removeCookie = (key, removeSubDomain = false) => {
  const pathStr = ';path=/;';
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC${pathStr}`;

  if (removeSubDomain) {
    const pathStrWithDomain = `;path=/;domain=${`localhost`}`;
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC${pathStrWithDomain}`;
  }
};

export function formatCurrency(value, scale = 2) {
  return !Number.isNaN(parseFloat(value))
    ? `${Number(value).toFixed(scale)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '0';
}

export function formatCurrencyWithSymbol(value, sym = '₩', scale = 2, showPlus = true) {
  const isNegative = value < 0;
  const val = !Number.isNaN(parseFloat(value)) ? (!isNegative ? value : value * -1) : 0;
  return `${showPlus ? (isNegative ? '-' : '+') : ''}${sym}${formatCurrency(val, scale)}`;
}

export function truncateString(str, maxLength = 220) {
  if (str.length <= maxLength) return str;
  const subString = str.slice(0, maxLength - 1);
  return subString + '...';
}

export const regX_password = /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

export const CONDITION_PASSWORD_DEFAULT = [
  {
    key: 1,
    description: '8~20자',
    regX: /.{8,}$/,
    isValid: false
  },
  {
    key: 2,
    description: '숫자',
    regX: /\d/,
    isValid: false
  },
  {
    key: 3,
    description: '특수문자',
    regX: /.*[!@#$%^&*(),.?":{}|<>]/,
    isValid: false
  }
];

export const getOptionsValidPassword = (value) => {
  const v = value || '';
  return CONDITION_PASSWORD_DEFAULT.map((item) => ({
    ...item,
    isValid: item.regX?.test(v)
  }));
};

export const getPhoneNumber = (code, phone) => {
  if (!phone?.includes('+')) {
    const codeValue = MOBILE_TYPE_OPTIONS.filter((item) => item.value === code)?.[0]?.code;
    const subPhone = phone[0] === '0' ? phone.slice(1, phone.length) : phone;
    return codeValue + subPhone;
  } else {
    return phone;
  }
};

export const getArea = (phone) => {
  return MOBILE_TYPE_OPTIONS.filter((item) => startsWith(phone, item.code))?.[0];
};
