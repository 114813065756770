import { Button, Checkbox, Col, Divider, Form, InputNumber, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { NoteIcon } from '../../../../asset/icons/Icons';
import { formatCurrency } from '../../../../utility/utils';
import { EditOutlined } from '@ant-design/icons';
import { fetchRefundAggs, fetchDepositAggs } from '../../../../common/api';
import { CURRENCIES } from '../../../../common/utilities/constants';
import './totalForm.style.scss';

const TotalForm = ({ mode = 'edit', form, onEditMode, role, setTotalPrice }) => {
  // const currencyUnit = role === 'free_t' ? '유로' : '원';
  const currency = role === 'free_t' ? CURRENCIES.EUR : CURRENCIES.KRW;
  const [depositAmount, setDepositAmount] = useState(form.getFieldValue('deposit_amount') || 0);
  const [refundAmount, setRefundAmount] = useState(form.getFieldValue('use_refund_amount') || 0);
  const [refundAvailable, setRefundAvailable] = useState(0);
  const [refundRemaining, setRefundRemaining] = useState(0);
  const [depositAvailable, setDepositAvailable] = useState(0);
  const [depositRemaining, setDepositRemaining] = useState(0);
  const [tempTotalPrice, setTempTotalPrice] = useState(0);

  useEffect(() => {
    fetchRefundAggs().then((agg) => {
      setRefundRemaining(agg?.remaining || 0);
    });
    fetchDepositAggs().then((agg) => {
      setDepositRemaining(agg?.remaining || 0);
    });
  }, []);

  useEffect(() => {
    const newRefund = tempTotalPrice < refundRemaining ? tempTotalPrice : refundRemaining;
    const newDeposit = tempTotalPrice < depositRemaining ? tempTotalPrice : depositRemaining;
    setRefundAvailable(newRefund - depositAmount > 0 ? newRefund - depositAmount : 0);
    setDepositAvailable(newDeposit - refundAmount > 0 ? newDeposit - refundAmount : 0);
  }, [refundRemaining, depositRemaining, tempTotalPrice, refundAmount, depositAmount]);

  function sumTotal(value, use_refund_amount, deposit_amount) {
    const totalPrice = sumProductPrice(value);
    const extraPrice = sumServicePrice();
    setTempTotalPrice(totalPrice + extraPrice);
    const total = totalPrice + extraPrice - (use_refund_amount || 0) - (deposit_amount || 0);
    // Free-T business default currency is EUR
    // Convert it to KRW to set the price
    setTotalPrice(total);
    return total;
  }

  function sumServicePrice() {
    let extraPrice = 0;
    // Free-T business default currency is EUR
    if (role === 'free_t') {
      if (form.getFieldValue('photo_inspection')) extraPrice += 2; // EUR
      if (form.getFieldValue('store_pickup')) extraPrice += 20; // EUR
    } else {
      if (form.getFieldValue('photo_inspection')) extraPrice += 3000; // KRW
      if (form.getFieldValue('fta_doc_issuance')) extraPrice += 5000; // KRW
      if (form.getFieldValue('store_pickup')) extraPrice += 35000; // KRW
      if (form.getFieldValue('invoice_document')) extraPrice += 35000; // KRW
      if (form.getFieldValue('export_report')) extraPrice += 35000; // KRW
    }
    return extraPrice;
  }

  function sumProductPrice(value) {
    const totalPrice = value?.reduce((acc, cur) => {
      // Free-T business default currency is EUR
      const price = role === 'free_t' ? cur.eur_price : cur.krw_price;
      return acc + price * cur.quantity;
    }, 0);
    return totalPrice;
  }

  function sumTotalRefund(value) {
    const totalRefund = value?.reduce((acc, cur) => {
      // Free-T business default currency is EUR
      const refundPrice = role === 'free_t' ? cur.eur_refunded_price : cur.krw_refunded_price;
      return acc + refundPrice;
    }, 0);

    return totalRefund;
  }
  return (
    <>
      <div className="py-4 px-3 bg-white mt-3">
        {/* <a href="https://www.waveon.io/apps/20442/pages/1682008016702" target="_blank" rel="noopener noreferrer">
          <Space>
            <div className="text-14 gray-900 fw-5">해외 배송료는 환급 예정금액에서 자동 차감됩니다.</div>
            <NoteIcon />
          </Space>
        </a> */}
        <div className="w-100 d-flex justify-content-between">
          <div style={{ fontSize: '18px', color: '#344054', fontWeight: '700', margin: '20px 0' }}>부가서비스</div>
          {mode === 'view' && <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />}
        </div>
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '13px' }}>기본 검수</b>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="basic_inspection" valuePropName="checked" noStyle>
              <Checkbox></Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="text-12 fw-5" style={{ color: '#667085', paddingRight: '20px' }}>
          프리티고에서 제공하는 무상 서비스로 색상, 수량, 사이즈 검수가 진행됩니다. 제품의 품질을 확인하는 작업이 아닌,
          주문 건과 해당 제품이 일치하는지 검수합니다.
        </div>
        <Divider className="my-2" />
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '13px' }}>검수 사진 요청</b>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="photo_inspection" valuePropName="checked" noStyle>
              <Checkbox></Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="py-2" style={{ fontWeight: 700, color: '#F63D68' }}>
          {role === 'free_t' ? '+2유로' : '+3,000원'}
        </div>
        <div className="text-12 fw-5" style={{ color: '#667085', paddingRight: '20px' }}>
          기본 검수와 제품의 사진을 제공합니다. 고객은 이를 확인한 후 제품을 수령하거나 교환할 수 있습니다. 프랑스
          현지에서의 교환 또는 환불을 원하면, 교환 및 환불 수수료 5,000원이 발생합니다.
        </div>
        {role === 'free_t_customer' && (
          <>
            <Divider className="my-2" />
            <Row justify="space-between" gutter={[10]}>
              <Col span={23}>
                <b style={{ fontSize: '13px' }}>FTA 서류 발급 신청, 150달러 이상 제품 필수</b>
              </Col>
              <Col span={1} className="d-flex justify-content-end align-items-baseline">
                <Form.Item name="fta_doc_issuance" valuePropName="checked" noStyle>
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div className="py-2" style={{ fontWeight: 700, color: '#F63D68' }}>
              +5,000원
            </div>
            <div className="text-12 fw-5" style={{ color: '#667085', paddingRight: '20px' }}>
              제품이 EU에서 생산된 경우, 관세 면제를 위해 관련 서류를 발급받을 수 있습니다.
            </div>
          </>
        )}
        <Divider className="my-2" />
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '13px' }}>에르메스, 셀린느 브랜드 구매 시 제품 매장 픽업 필수 신청</b>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="store_pickup" valuePropName="checked" noStyle>
              <Checkbox></Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="py-2" style={{ fontWeight: 700, color: '#F63D68' }}>
          {role === 'free_t' ? '+20유로' : '+35,000원'}
        </div>
        <div className="text-12 fw-5" style={{ color: '#667085', paddingRight: '20px' }}>
          에르메스와 셀린느 브랜드의 온라인 주문은 본사에서의 취소로 인해 매장에서 직접 구매만 가능합니다.
        </div>
        <div className="text-14 fw-5" style={{ color: 'red', paddingRight: '20px' }}>
          부티크 방문 요청 구매일 경우 약 1~4주 사이로 사무실 수령 기간이 소요되며, 중간에 제품 품절 및 실질 재고
          없음으로 인해 구매가 불가할 수 있습니다.
        </div>
        <div className="text-14 fw-5" style={{ color: 'red', paddingRight: '20px' }}>
          에르메스 브랜드에서 pop h, bouncing, rodeo, focus 모델은 결제가 불가합니다.
        </div>
        {role === 'free_t_customer' && (
          <>
            <Divider className="my-2" />
            <Row justify="space-between" gutter={[10]}>
              <Col span={23}>
                <b style={{ fontSize: '13px' }}>사업자 인보이스 서류 발급 </b>
              </Col>
              <Col span={1} className="d-flex justify-content-end align-items-baseline">
                <Form.Item name="invoice_document" valuePropName="checked" noStyle>
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div className="py-2" style={{ fontWeight: 700, color: '#F63D68' }}>
              +35,000원
            </div>
            <div className="text-12 fw-5" style={{ color: '#667085', paddingRight: '20px' }}>
              수출 신고 이후 청구 불가합니다. 해당 수수료를 납부하지 않기 위해서는 사업자 회원으로 가입 문의주세요.
            </div>
            <Divider className="my-2" />
            <Row justify="space-between" gutter={[10]}>
              <Col span={23}>
                <b style={{ fontSize: '13px' }}>사업자 수출 신고 대행</b>
              </Col>
              <Col span={1} className="d-flex justify-content-end align-items-baseline">
                <Form.Item name="export_report" valuePropName="checked" noStyle>
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div className="py-2" style={{ fontWeight: 700, color: '#F63D68' }}>
              +35,000원
            </div>
            <div className="text-12 fw-5" style={{ color: '#667085', paddingRight: '20px' }}>
              수출 신고 이후 청구 불가합니다. 해당 수수료를 납부하지 않기 위해서는 사업자 회원으로 가입 문의주세요.
            </div>
          </>
        )}
      </div>
      <div className="py-4 px-3 bg-white mt-3">
        {role === 'free_t' && (
          <Form.Item
            label="보유 예치금 사용"
            name="deposit_amount"
            rules={[{ required: false }, { type: 'number', min: 0, message: 'The minimum deposit amount is 0!' }]}
            extra={
              <Row justify="end">
                사용가능 예치금 : {formatCurrency(depositRemaining - depositAmount, currency?.scale || 0)}
                {currency?.label}
              </Row>
            }>
            <InputNumber
              className="input-number-w-button-after"
              controls={false}
              size={'large'}
              min={0}
              max={depositAvailable}
              suffix={currency?.label}
              formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
              parser={(value) => value.replace(/,/g, '')}
              onChange={setDepositAmount}
              addonAfter={
                <Button
                  onClick={() => {
                    form.setFieldValue('deposit_amount', depositAvailable);
                    setDepositAmount(depositAvailable);
                  }}
                  style={{
                    fontSize: 15,
                    height: 'auto',
                    backgroundColor: '#4BBF9F',
                    borderColor: '#4BBF9F'
                  }}>
                  전체사용
                </Button>
              }
              style={{ width: '100%' }}
            />
          </Form.Item>
        )}
        <Form.Item
          label="보유 환급금 사용"
          name="use_refund_amount"
          rules={[{ required: false }, { type: 'number', min: 0, message: 'The minimum refund amount is 0!' }]}
          extra={
            <Row justify="end">
              사용가능 환급금 : {formatCurrency(refundRemaining - refundAmount, currency?.scale || 0)}
              {currency?.label}
            </Row>
          }>
          <InputNumber
            className="input-number-w-button-after"
            controls={false}
            min={0}
            size={'large'}
            max={refundAvailable}
            suffix={currency?.label}
            formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
            parser={(value) => value.replace(/,/g, '')}
            onChange={setRefundAmount}
            addonAfter={
              <Button
                onClick={() => {
                  form.setFieldValue('use_refund_amount', refundAvailable);
                  setRefundAmount(refundAvailable);
                }}
                style={{
                  fontSize: 15,
                  height: 'auto',
                  backgroundColor: '#4BBF9F',
                  borderColor: '#4BBF9F'
                }}>
                전체사용
              </Button>
            }
            style={{ width: '100%' }}
          />
        </Form.Item>
      </div>
      <div className="py-4 px-3 bg-white mt-3">
        <Row justify="space-between">
          <Col>제품 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Col>
                  {formatCurrency(sumProductPrice(getFieldValue('products'))?.toFixed(2), currency?.scale || 0)}
                  {currency.label}
                </Col>
              );
            }}
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Col>부가서비스 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Col>
                  {formatCurrency(sumServicePrice()?.toFixed(2), currency?.scale || 0)}
                  {currency.label}
                </Col>
              );
            }}
          </Form.Item>
        </Row>
        {role === 'free_t' && (
          <Row justify="space-between">
            <Col>사용 예치금</Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                return (
                  <Col style={{ color: '#F63D68' }}>
                    -{formatCurrency(getFieldValue('deposit_amount')?.toFixed(2), currency?.scale || 0)}
                    {currency.label}
                  </Col>
                );
              }}
            </Form.Item>
          </Row>
        )}
        <Row justify="space-between">
          <Col>사용 환급금</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Col style={{ color: '#F63D68' }}>
                  -{formatCurrency(getFieldValue('use_refund_amount')?.toFixed(2), currency?.scale || 0)}
                  {currency.label}
                </Col>
              );
            }}
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Col>총 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Col>
                  {formatCurrency(
                    sumTotal(
                      getFieldValue('products'),
                      getFieldValue('use_refund_amount'),
                      getFieldValue('deposit_amount')
                    )?.toFixed(2),
                    currency?.scale || 0
                  )}
                  {currency.label}
                </Col>
              );
            }}
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Col>
            <b>환급 예정금액</b>
          </Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Col>
                  <b>
                    {formatCurrency(sumTotalRefund(getFieldValue('products'))?.toFixed(2), currency?.scale || 0)}
                    {currency.label}
                  </b>
                </Col>
              );
            }}
          </Form.Item>
        </Row>
      </div>
    </>
  );
};

export default TotalForm;
