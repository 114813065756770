import { Button, Card, Col, Image, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExternalLinkBlack, RightOutlined } from '../../../../asset/icons/Icons';
import { CURRENCIES, ORDER_STATUS, PURCHASE_REQUEST_STATUS } from '../../../../common/utilities/constants';
import StatusTag from '../../../../common/components/StatusTag';
import { formatCurrency } from '../../../../utility/utils';
import ClickPreviewImages from '../../orderProduct/components/ClickPreviewImages';
import { SHIPPING_COST_STATUS } from '../../../../common/utilities/constants';
const { Meta } = Card;

const ProductCard = ({ data, handleOpenRejectModal }) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const refundCurrency = userInfo.role === 'free_t' ? CURRENCIES.EUR : CURRENCIES.KRW;

  const handleClickRightIcon = () => {
    navigate(`/purchase-request/detail?id=${data?.id}`);
  };

  let status = data?.products?.[0].order_product?.status;
  if (!status || ['pending', 'confirmed'].includes(status)) {
    status = data.status;
  }

  return (
    <>
      <Card
        // title={dayjs(data?.updated_at).format('YYYY.MM.DD')}
        title={dayjs(data?.created_at).format('YYYY.MM.DD')}
        extra={
          <div className="d-flex justify-content-between" onClick={handleClickRightIcon}>
            <StatusTag status={status} opts={[...ORDER_STATUS, ...PURCHASE_REQUEST_STATUS]} />
            <div className="ms-2">
              <RightOutlined />
            </div>
          </div>
        }
        bodyStyle={{ padding: '0' }}>
        {data?.products?.map((item, idx) => {
          const orderProd = item.order_product;
          const config = ORDER_STATUS.find((i) => i.values?.includes(orderProd?.status));
          let invoice_pictures = item.invoice_pictures;
          if (userInfo.role === 'free_t' && item.is_ordered === false) {
            invoice_pictures = orderProd?.inventories?.[0]?.invoice?.pictures || [];
          }
          return (
            <Card.Grid key={idx} hoverable={false} style={{ width: '100%' }}>
              {orderProd && config && (
                <Row justify="space-between">
                  <Col span={18}>
                    <Typography.Link
                      href={`/order-product?order_id=${item.order_product.order_id}`}
                      style={{ color: '#000000' }}
                      underline>
                      {orderProd.order_product_number} <ExternalLinkBlack />
                    </Typography.Link>
                  </Col>
                  <Col span="content">
                    <Typography.Text strong style={{ color: '#4BBF9F' }}>
                      {config.label}
                    </Typography.Text>
                  </Col>
                </Row>
              )}
              <Space>
                <Image
                  height={76}
                  width={76}
                  style={{ objectFit: 'cover', borderRadius: '5px' }}
                  src={item?.product_picture?.file_view || ''}
                  alt=""
                />
                <Space direction="vertical" size={0}>
                  <Typography.Text type="secondary" ellipsis style={{ fontSize: '12px' }}>
                    {item?.brand_name}
                  </Typography.Text>
                  <Typography.Text ellipsis style={{ fontSize: '12px' }}>
                    {item?.product_name}
                  </Typography.Text>
                  <Typography.Text
                    type="secondary"
                    ellipsis
                    style={{ fontSize: '12px' }}>{`${item?.quantity}/${item?.option}`}</Typography.Text>
                  <Typography.Text ellipsis style={{ fontWeight: 700, fontSize: '12px' }}>
                    {refundCurrency.symbol}
                    {formatCurrency(userInfo.role === 'free_t' ? item?.eur_price : item?.krw_price, userInfo.role === 'free_t' ? 2 : 0)}
                  </Typography.Text>
                </Space>
              </Space>
              <Row justify="space-between">
                {orderProd?.shipment?.tracking_number && (
                  <Typography.Link
                    href={`https://acieshop.com/aci_pod/pod.php?BL=${orderProd?.shipment?.tracking_number}`}
                    target="_blank"
                    underline>
                    배송 현황 확인 <ExternalLinkBlack />
                  </Typography.Link>
                )}
                {data?.photo_inspection && item.order_product?.inspection_images?.length > 0 && (
                  <ClickPreviewImages
                    title={
                      <Typography.Link underline>
                        검수 사진 확인 <ExternalLinkBlack />
                      </Typography.Link>
                    }
                    images={item.order_product.inspection_images}
                  />
                )}
                {invoice_pictures?.length > 0 && (
                  <ClickPreviewImages
                    title={
                      <Typography.Link underline>
                        영수증 <ExternalLinkBlack />
                      </Typography.Link>
                    }
                    images={invoice_pictures}
                  />
                )}
              </Row>
              <Row justify="space-between">
                <Typography.Text style={{ color: '#EAAA08', fontWeight: 700 }}>환급 예정 금액</Typography.Text>
                <Typography.Text style={{ color: '#EAAA08', fontWeight: 700 }}>
                  {refundCurrency.symbol}
                  {formatCurrency(userInfo.role === 'free_t' ? item?.eur_refunded_price : item?.krw_refunded_price, userInfo.role === 'free_t' ? 2 : 0)}
                </Typography.Text>
              </Row>
            </Card.Grid>
          );
        })}
        {data.status === 'rejected' && (
          <Button
            onClick={() => {
              handleOpenRejectModal(data.id, data.reject_reason);
            }}
            size="middle"
            style={{ width: '100%', borderRadius: '0px 0px 8px 8px' }}
            type="primary"
            danger>
            반려 사유 확인
          </Button>
        )}
        {data.status !== 'rejected' && data?.shipping_cost && (
          <Button
            onClick={() => {
              navigate(`/purchase-request/shipping-cost?id=${data?.id}`);
            }}
            size="middle"
            style={{ width: '100%', borderRadius: '0px 0px 8px 8px', backgroundColor: '#4BBF9FF2' }}
            disabled={data?.shipping_cost?.status === SHIPPING_COST_STATUS.PAYMENT_COMPLETED}
            className={'ant-btn-custom-disabled'}
            type="primary">
            {data?.shipping_cost?.status === SHIPPING_COST_STATUS.DEPOSIT_WAITING
              ? '배송비 결제'
              : data?.shipping_cost?.status === SHIPPING_COST_STATUS.DEPOSIT_COMPLETED
              ? '배송비 입금 확인중'
              : '배송비 결제 완료'}
          </Button>
        )}
      </Card>
    </>
  );
};

export default ProductCard;
