import Login from '../views/pages/login';
import NotFound from '../views/pages/not-found';
import SignUpPage from '../views/pages/login/sign-up/SignUpPage';

const PublicRoutes = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/sign-up',
    element: <SignUpPage />
  },
  {
    path: '/*',
    element: <NotFound />
  }
];
export default PublicRoutes;
