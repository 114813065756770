import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { capitalize, map, reduce } from 'lodash';
import { getStatusLabel, PURCHASE_REQUEST_STATUS } from '../../common/utilities/constants';
import { fetchPurchaseRequest, fetchPurchaseRequestStatus, fetchConversionRate, fetchDefaultRecipient } from '../../views/features/purchased/api';

export const fetchPurchaseRequestThunk = createAsyncThunk('ft/purchaseRequest/all', async (params, thunkApi) => {
  // params["sort"] = "-updated_at";
  params["sort"] = "-created_at";
  return await fetchPurchaseRequest(params, thunkApi);
});
export const fetchPurchaseRequestStatusThunk = createAsyncThunk('ft/purchaseRequest/status/all', async (params, thunkApi) => {
  return await fetchPurchaseRequestStatus(params, thunkApi);
});
export const fetchConversionRateThunk = createAsyncThunk('ft/purchaseRequest/conversionRate/get', async (params, thunkApi) => {
  return await fetchConversionRate(thunkApi);
});
export const fetchDefaultRecipientThunk = createAsyncThunk('ft/purchaseRequest/defaultRecipient/get', async (params, thunkApi) => {
  return await fetchDefaultRecipient(thunkApi);
});


const initialState = {
  loading: false,
  isOpenSearchDrawer: false,
  totalPage: 0,
  totalResources: 0,
  page: 1,
  size: 50,
  refund_rate: parseFloat(process.env.REACT_APP_REFUND_RATE) || 0.2,
  conversion_rate: 1,
  resources: [],
  statusOptions:[],
  pending_count: 0,
  default_recipient: null,
  filters: {},
};

export const purchaseRequestSlice = createSlice({
  name: 'purchaseRequest',
  initialState,
  reducers: {
    openSearchDrawer: (state) => {
      state.isOpenSearchDrawer = true;
    },
    closeSearchDrawer: (state) => {
      state.isOpenSearchDrawer = false;
    },
    changeSize: (state, action) => {
      state.size = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeFilters: (state, action) => {
      state.filters = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseRequestThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPurchaseRequestThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.totalPage = action.payload.total_pages;
        state.totalResources = action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchPurchaseRequestThunk.rejected, (state, action) => {
        state.loading = false;
        state.resources = 0;
        state.totalPage = 0;
        state.totalResources = [];
      })
      .addCase(fetchConversionRateThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchConversionRateThunk.fulfilled, (state, action) => {
        state.conversion_rate = action.payload;
        state.loading = false;
      })
      .addCase(fetchConversionRateThunk.rejected, (state, action) => {
        state.loading = false;
        state.conversion_rate = 1;
      })
      .addCase(fetchDefaultRecipientThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDefaultRecipientThunk.fulfilled, (state, action) => {
        state.default_recipient = action.payload;
        state.loading = false;
      })
      .addCase(fetchDefaultRecipientThunk.rejected, (state, action) => {
        state.loading = false;
        state.default_recipient = null;
      })
      .addCase(fetchPurchaseRequestStatusThunk.fulfilled, (state, action) => {
        const status =  action.payload || {}
        const newStatus = map(status, (value, key) => {
          if (key === 'pending') {
            state.pending_count = value;
          }
          return {
            value: key, 
            label: getStatusLabel(key, PURCHASE_REQUEST_STATUS) + ' ' + `(${value})`
          }
        })
        // const sum = reduce(status,(sum, value)  => value + sum , 0)
        // newStatus.unshift({
        //   value: '', 
        //   label: `All (${sum})`
        // })
        state.statusOptions = newStatus ;
      })
  }
});

export const { openSearchDrawer, closeSearchDrawer, changePage, changeSize, changeFilters } = purchaseRequestSlice.actions;

export default purchaseRequestSlice.reducer;
