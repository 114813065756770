import { Button, Checkbox, Col, Form, Input, InputNumber, Row, message, Typography, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { CURRENCIES } from '../../../../common/utilities/constants';
import { logoutThunk } from '../../../../redux/reducers/authSlice';
import { createBusiness, updateBusiness } from '../../../../common/api';
const { Text } = Typography;

const BusinessCustomerForm = ({ opened, onSubmitted, onClose, mode = 'edit', ...props }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(CURRENCIES.EUR);
  const { userInfo: user } = useSelector((state) => state?.auth);

  const handleClickLogout = () => {
    dispatch(logoutThunk());
  };

  const initialValues = {
    registration_number: user?.business?.registration_number,
    representative_name: user?.business?.representative_name,
    pictures: user?.business?.pictures || [],
    product_type: user?.business?.product_type,
    estimated_monthly_amount: user?.business?.estimated_monthly_amount,
    email: user?.business?.email,
    trade_name: user?.business?.trade_name,
    address: user?.business?.address,
    postal_code: user?.business?.postal_code
  };

  const onFinish = async (values) => {
    const businessId = user?.business?.id;
    const businessData = { ...values, user_id: user?.id };
    const data = businessId ? await updateBusiness(businessId, businessData) : await createBusiness(businessData);

    if (data === null) {
      message.error('Error!');
    } else {
      message.success('Successfully!');
      onSubmitted();
    }
  };

  const validateMessages = {
    required: 'This field is required!',
    types: {
      email: '이메일 형식이 올바르지 않아요'
    }
  };

  useEffect(() => {
    if (opened) {
      form.resetFields();
    }
  }, [opened, form]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      validateMessages={validateMessages}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      size="large"
      className="business-customer-form"
      {...props}>
      <Form.Item name="registration_number" label="1. 사업자 등록 번호" rules={[{ required: true }]}>
        <Input placeholder="사업자 등록 번호" />
      </Form.Item>

      <Form.Item name="trade_name" label="2. 상호명" rules={[{ required: true }]}>
        <Input placeholder="프리티고" />
      </Form.Item>

      <Form.Item
        name="postal_code"
        label="3. 우편 번호"
        rules={[
          { required: true },
          () => ({
            validator(_, value) {
              const re = /^[0-9\b]+$/; // Regular expression to allow only numbers and backspace
              if (!value || re.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Input must contain only digits'));
            }
          })
        ]}>
        <Input placeholder="00000" />
      </Form.Item>

      <Form.Item name="address" label="4. 사업장 주소" rules={[{ required: true }]}>
        <Input placeholder="Company Address" />
      </Form.Item>

      <Typography.Text type="secondary" style={{ color: 'red', fontWeight: '700' }}>
        * 사업장 주소는 영문으로 입력해 주세요.
      </Typography.Text>

      <Form.Item name="representative_name" label="5. 대표자 이름" rules={[{ required: true }]}>
        <Input placeholder="홍길동" />
      </Form.Item>

      <Form.Item name="pictures" label="6. 사업자 등록증" rules={[{ required: true }]}>
        <UploadFileCustom
          accept=".png,.jpg,.jpeg"
          upload_hint="PNG or JPG"
          feature_type="user"
          isViewOnly={mode === 'view' ? true : false}
        />
      </Form.Item>

      <Form.Item name="product_type" label="7. 취급 제품 종류 (예 : 의류, 주방용품 등)" rules={[{ required: true }]}>
        <Input placeholder="의류, 주방용품, 명품 등" />
      </Form.Item>

      <Form.Item
        name="estimated_monthly_amount"
        label="8. 월 예상 거래금액 (예 : 10,000유로)"
        rules={[{ required: true }]}>
        <InputNumber
          controls={false}
          min={0.01}
          placeholder={'숫자만 입력해 주세요'}
          suffix={currency?.label}
          formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
          parser={(value) => value.replace(/,/g, '')}
          style={{ width: '100%' }}
          size="large"
        />
      </Form.Item>

      <Form.Item name="email" label="9. 기업용 이메일" rules={[{ required: true, type: 'email' }]}>
        <Input placeholder="연락용 이메일을 작성해 주세요" />
      </Form.Item>

      {/* Submit button */}
      <Form.Item>
        <Row gutter={8}>
          {mode === 'profile' ? (
            <Col span={8}>
              <Button block type="default" onClick={onClose}>
                닫기
              </Button>
            </Col>
          ) : (
            <Col span={8}>
              <Button block type="default" onClick={handleClickLogout}>
                로그아웃
              </Button>
            </Col>
          )}
          <Col span={16}>
            <Button block type="primary" htmlType="submit">
              사업자 정보 등록
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
export default BusinessCustomerForm;
