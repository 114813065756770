import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Skeleton, Typography } from 'antd';
import { fetchDeposits } from '../../../common/api';
import { CURRENCIES } from '../../../common/utilities/constants';
import DepositListItem from './DepositListItem';
const { Text } = Typography;

const DepositList = ({ type = '', maxHeight = 500, setMemo=null, setOpenMemo=null, ...props }) => {
  const id = ['deposit-list', Math.random().toString(36).substring(2)].join('-');
  const size = 10;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deposits, setDeposits] = useState([]);
  const [currency, setCurrency] = useState(CURRENCIES.KRW);

  const loadMoreData = () => {
    if (loading) return;
    setLoading(true);
    fetchDeposits({ page: page + 1, size, type })
      .then((res) => {
        const resources = [...deposits, ...res.resources];
        setPage(page + 1);
        setHasMore(resources.length < res.total_resources);
        setLoading(false);
        setDeposits(resources);
        setCurrency(CURRENCIES[res.currency.name]);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <div id={id} style={{ maxHeight: maxHeight, overflow: 'auto' }} {...props}>
      <InfiniteScroll
        dataLength={deposits.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={<Skeleton />}
        scrollableTarget={id}>
        <List dataSource={deposits} renderItem={(deposit) => <DepositListItem deposit={deposit} currency={currency} setOpenMemo={setOpenMemo} setMemo={setMemo} />} />
      </InfiniteScroll>
    </div>
  );
};

export default DepositList;
