import {
  Col,
  Input,
  Form,
  Row,
  Select,
  Checkbox,
  Space,
  Divider,
  Typography,
  Button,
  Spin,
  Card,
  Radio,
  Modal
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { NoteIcon } from '../../../../asset/icons/Icons';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { DEFAULT_QUANTITY_DROPDOWN, NUMBER_KOREAN } from '../../../../common/utilities/constants';
import InputNumberCustom from '../../../../common/components/ActionIcons/InputNumber/InputNumberCustom';
import './productCard.style.scss';
import ProductImagesModal from './ProductImagesModal';
import UploadSingleImage from '../../../../common/components/UploadSingleImage';
import { useSelector } from 'react-redux';

const ProductInfo = ({ mode = 'edit', role, onValidate, form, setOpenRefundRateModal, onEditMode }) => {
  const [loadings, setLoadings] = useState([]);
  const [openPicModal, setOpenPicModal] = useState([]);
  const [isUploadModal, setIsUploadModal] = useState([]);
  const { conversion_rate, refund_rate } = useSelector((state) => state.purchaseRequests);
  const { userInfo } = useSelector((state) => state.auth);

  const setProductLoading = (key, isLoading) => {
    setLoadings((state) => {
      const newState = [...state];
      newState[key] = isLoading;
      return newState;
    });
  };

  const handleRemove = (remove) => {
    Modal.confirm({
      title: 'Are you sure you want to delete it?',
      onCancel: () => {},
      onOk: () => {
        remove();
      },
      centered: true,
      icon: null,
      cancelText: 'Close',
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
        style: {
          marginRight: '8%',
          width: '40%'
        }
      },
      cancelButtonProps: {
        style: {
          width: '40%'
        }
      }
    });
  };

  const disableDeleteButton = () => {
    const products = form.getFieldValue('products') || [];
    return products.length <= 1;
  };

  const getProductNameCard = (key) => {
    return NUMBER_KOREAN[key] || '';
  };

  const handleProductPicture = (key, pic) => {
    const products = form.getFieldValue('products');
    if (pic !== null) {
      products[key].product_picture = { ...pic, picture_type: 'product' };
    } else {
      products[key].product_picture = null;
    }
    form.setFieldsValue({ products });
  };

  const onChangeEurPrice = (key) => {
    const price = form.getFieldValue('products')?.[key]?.eur_price;
    const krw_price = price * conversion_rate;
    const products = form.getFieldValue('products');
    products[key].krw_price = krw_price;
    products[key].krw_refunded_price =
      krw_price * (products[key].quantity || 0) * (userInfo?.refund_rate || refund_rate);
    products[key].eur_refunded_price = price * (products[key].quantity || 0) * (userInfo?.refund_rate || refund_rate);
    form.setFieldsValue({ products });
  };

  return (
    <>
      <Form.List name="products" noStyle>
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <Space className="product-info-space" key={field.key}>
                <Spin tip="Loading..." spinning={loadings[field.key] || false}>
                  <Card className="mb-3">
                    <div className="d-flex justify-content-between align-item-center mb-3">
                      <div style={{ fontSize: '18px', color: '#344054', fontWeight: '700' }}>
                        {getProductNameCard(field.name)} 번째 제품
                      </div>
                      {!(mode === 'view') && field.name !== 0 && (
                        <Button
                          style={{ width: '120px' }}
                          icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                          size="large"
                          onClick={() => handleRemove(() => remove(field.name))}
                          disabled={disableDeleteButton()}
                          key={`deleteBtn${field.key}`}
                          block>
                          삭제
                        </Button>
                      )}
                    </div>
                    <Form.Item
                      noStyle
                      shouldUpdate={(preValue, curValue) =>
                        preValue.products?.[field.name]?.product_picture !==
                        curValue.products?.[field.name]?.product_picture
                      }>
                      {({ getFieldValue }) => {
                        const UpdateUploadModal = (isUpload, isOpen) => {
                          setOpenPicModal((openPicModal) => {
                            const newOpenPicModal = [...openPicModal];
                            newOpenPicModal[field.name] = isOpen;
                            return newOpenPicModal;
                          });
                          setIsUploadModal((isUploadModal) => {
                            const newIsUpload = [...isUploadModal];
                            newIsUpload[field.name] = isUpload;
                            return newIsUpload;
                          });
                        };
                        const onCancel = () => {
                          setOpenPicModal((openPicModal) => {
                            const newOpenPicModal = [...openPicModal];
                            newOpenPicModal[field.name] = false;
                            return newOpenPicModal;
                          });
                        };
                        const onSubmit = (val) => {
                          handleProductPicture(field.name, val);
                        };
                        return (
                          <>
                            <div className="mb-2">
                              <div className="text-14 gray-900 fw-5" style={{ fontWeight: 500 }}>
                                제품 이미지 <span style={{ color: 'red' }}>*</span> <br />
                                <span style={{ fontWeight: 400, color: '#667085' }}>
                                  두가지 버튼 중 하나를 선택해 주세요
                                </span>
                              </div>
                              {getFieldValue('products')[field.name]?.product_picture || mode === 'view' ? (
                                <>
                                  <UploadSingleImage
                                    key={`productImg${field.key}`}
                                    value={getFieldValue('products')[field.name]?.product_picture}
                                    onChangeValue={onSubmit}
                                    isViewOnly={mode === 'view' ? true : false}
                                  />
                                </>
                              ) : (
                                <>
                                  <Row gutter={[8, 16]}>
                                    <Col span={12}>
                                      <Button
                                        key={`productImgUrl${field.key}`}
                                        block
                                        type="primary"
                                        onClick={() => {
                                          UpdateUploadModal(false, true);
                                        }}>
                                        이미지 URL
                                      </Button>
                                    </Col>
                                    <Col span={12}>
                                      <Button
                                        block
                                        key={`productImgUpload${field.key}`}
                                        type="primary"
                                        onClick={() => {
                                          UpdateUploadModal(true, true);
                                        }}>
                                        이미지 업로드
                                      </Button>
                                    </Col>
                                  </Row>
                                  <ProductImagesModal
                                    key={`productImgModal${field.key}`}
                                    isOpen={openPicModal[field.name] || false}
                                    isUpload={isUploadModal[field.name] || false}
                                    onCancel={onCancel}
                                    onSubmit={onSubmit}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      key={`product_url${field.key}`}
                      name={[field.name, 'product_url']}
                      rules={[
                        {
                          validator: async (rule, value) => {
                            if (
                              value &&
                              !/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+(\/[^\s]*)?$/.test(value)
                            ) {
                              throw new Error('잘못된 URL 형식');
                            }
                          }
                        }
                      ]}
                      validateTrigger="onChange"
                      label={
                        <div className="w-100 d-flex justify-content-between">
                          <div className="text-14 gray-900 fw-5">
                            제품 URL <span style={{ color: 'red' }}>*</span>
                          </div>
                          {mode === 'view' && (
                            <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                          )}
                        </div>
                      }
                      required>
                      <Input size="large" placeholder="URL" />
                    </Form.Item>
                    <Form.Item
                      key={`product_name${field.key}`}
                      label={
                        <div className="w-100 d-flex justify-content-between">
                          <div className="text-14 gray-900 fw-5">
                            제품명 <span style={{ color: 'red' }}>*</span>
                          </div>
                          {mode === 'view' && (
                            <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                          )}
                        </div>
                      }
                      name={[field.name, 'product_name']}
                      required>
                      <Input size="large" placeholder="제품명" />
                    </Form.Item>
                    {role === 'free_t' && (
                      <Form.Item
                        key={`product_id${field.key}`}
                        label={
                          <div className="w-100 d-flex justify-content-between">
                            <div className="text-14 gray-900 fw-5">Product ID</div>
                            {mode === 'view' && (
                              <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                            )}
                          </div>
                        }
                        name={[field.name, 'product_id']}>
                        <Input size="large" placeholder="Product ID" />
                      </Form.Item>
                    )}
                    <Form.Item
                      key={`brand_name${field.key}`}
                      label={
                        <div className="w-100 d-flex justify-content-between">
                          <div className="text-14 gray-900 fw-5">
                            브랜드 <span style={{ color: 'red' }}>*</span>
                          </div>
                          {mode === 'view' && (
                            <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                          )}
                        </div>
                      }
                      name={[field.name, 'brand_name']}
                      required>
                      <Input size="large" placeholder="브랜드" />
                    </Form.Item>
                    <Row gutter={[10]}>
                      <Col span={16}>
                        <Form.Item
                          key={`option${field.key}`}
                          label={
                            <div className="w-100 d-flex justify-content-between">
                              <div className="text-14 gray-900 fw-5">
                                옵션 (컬러/사이즈/외) <span style={{ color: 'red' }}>*</span>
                              </div>
                              {mode === 'view' && (
                                <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                              )}
                            </div>
                          }
                          name={[field.name, 'option']}
                          required>
                          <Input size="large" placeholder="옵션 (컬러/사이즈/외)" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          key={`quantity${field.key}`}
                          label={
                            <div className="w-100 d-flex justify-content-between">
                              <div className="text-14 gray-900 fw-5">
                                개수 <span style={{ color: 'red' }}>*</span>
                              </div>
                              {mode === 'view' && (
                                <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                              )}
                            </div>
                          }
                          name={[field.name, 'quantity']}
                          required>
                          <Select
                            size="large"
                            placeholder="개수"
                            options={DEFAULT_QUANTITY_DROPDOWN}
                            onChange={(e) => onChangeEurPrice(field.name)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      key={`eur_price${field.key}`}
                      label={
                        <div className="w-100 d-flex justify-content-between">
                          <div className="text-14 gray-900 fw-5">
                            제품 가격 (유로) <span style={{ color: 'red' }}>*</span>
                          </div>
                          {mode === 'view' && (
                            <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                          )}
                        </div>
                      }
                      name={[field.name, 'eur_price']}>
                      <InputNumberCustom
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                        className="w-100"
                        onChange={() => onChangeEurPrice(field.name)}
                      />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(preValue, curValue) =>
                        preValue.products?.[field.name]?.eur_price !== curValue.products?.[field.name]?.eur_price
                      }>
                      {({ getFieldValue }) => {
                        return (
                          <>
                            <Form.Item
                              key={`krw_price${field.key}`}
                              label={<div className="text-14 gray-900 fw-5">제품 가격 (원화)</div>}
                              name={[field.name, 'krw_price']}>
                              <InputNumberCustom
                                disabled
                                formatter={(value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/₩\s?|(,*)/g, '')}
                                className="w-100"
                              />
                            </Form.Item>
                            <Form.Item
                              key={`krw_refund_price${field.key}`}
                              label={
                                <Space
                                  className="no-width-space"
                                  onClick={() => {
                                    setOpenRefundRateModal(true);
                                  }}>
                                  <div className="text-14 gray-900 fw-5">
                                    환급 예상 금액 ({role === 'free_t' ? '유로' : '원화'})
                                  </div>
                                  <NoteIcon />
                                </Space>
                              }
                              name={[field.name, role === 'free_t' ? 'eur_refunded_price' : 'krw_refunded_price']}>
                              <InputNumberCustom
                                disabled
                                formatter={(value) =>
                                  (role === 'free_t' ? '€' : '₩') +
                                  ` ${parseFloat(value || 0).toFixed(role === 'free_t' ? 2 : 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={(value) => value.replace(/[₩€]\s?|(,*)/g, '')}
                                className="w-100"
                              />
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      key={`eur_shipping_fee${field.key}`}
                      label={
                        <div className="w-100 d-flex justify-content-between">
                          <div className="text-14 gray-900 fw-5">유럽내배송비 (유로)</div>
                          {mode === 'view' && (
                            <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                          )}
                        </div>
                      }
                      name={[field.name, 'eur_shipping_fee']}>
                      <InputNumberCustom
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                        className="w-100"
                      />
                    </Form.Item>
                    <Form.Item
                      key={`product_memo${field.key}`}
                      label={
                        <div className="w-100 d-flex justify-content-between">
                          <div className="text-14 gray-900 fw-5">메모</div>
                          {mode === 'view' && (
                            <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                          )}
                        </div>
                      }
                      name={[field.name, 'memo']}>
                      <TextArea rows={3} placeholder="메모를 작성해 주세요" />
                    </Form.Item>
                    {role === 'free_t' && (
                      <>
                        <Form.Item
                          key={`is_ordered${field.key}`}
                          name={[field.name, 'is_ordered']}
                          label={
                            <div className="w-100 d-flex justify-content-between">
                              <div className="text-14 gray-900 fw-5">
                                직접 주문여부를 체크해주세요 (프리티고 카드 발급자)
                              </div>
                              {mode === 'view' && (
                                <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                              )}
                            </div>
                          }>
                          <Radio.Group
                            // value={role}
                            className="d-flex"
                            // onChange={(e) => setRole(e.target.value)}
                          >
                            <Radio value={true} className="p-2">
                              {' '}
                              주문했어요{' '}
                            </Radio>
                            <Radio value={false} className="p-2">
                              {' '}
                              안했어요{' '}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(preValue, curValue) =>
                            preValue.products?.[field.name]?.is_ordered !== curValue.products?.[field.name]?.is_ordered
                          }>
                          {({ getFieldValue }) => {
                            return getFieldValue('products')[field.name]?.is_ordered ? (
                              <>
                                <Form.Item
                                  key={`shipping_company${field.key}`}
                                  label={
                                    <div className="w-100 d-flex justify-content-between">
                                      <div className="text-14 gray-900 fw-5">운송사</div>
                                      {mode === 'view' && (
                                        <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                                      )}
                                    </div>
                                  }
                                  name={[field.name, 'shipping_company']}
                                  required>
                                  <Input size="large" placeholder="운송사" />
                                </Form.Item>
                                <Form.Item
                                  key={`tracking_number${field.key}`}
                                  label={
                                    <div className="w-100 d-flex justify-content-between">
                                      <div className="text-14 gray-900 fw-5">송장 번호</div>
                                      {mode === 'view' && (
                                        <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                                      )}
                                    </div>
                                  }
                                  name={[field.name, 'tracking_number']}
                                  required>
                                  <Input size="large" placeholder="송장 번호" />
                                </Form.Item>
                                <Form.Item
                                  key={`invoice_pictures${field.key}`}
                                  label={
                                    <div className="w-100 d-flex justify-content-between">
                                      <div className="text-14 gray-900 fw-5">구매 영수증</div>
                                      {mode === 'view' && (
                                        <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />
                                      )}
                                    </div>
                                  }
                                  name={[field.name, 'invoice_pictures']}
                                  required={mode === 'edit'}>
                                  <UploadFileCustom
                                    accept=".png,.jpg,.jpeg"
                                    upload_hint="PNG or JPG"
                                    isViewOnly={mode === 'view' ? true : false}
                                  />
                                </Form.Item>
                              </>
                            ) : (
                              <></>
                            );
                          }}
                        </Form.Item>
                      </>
                    )}
                  </Card>
                </Spin>
              </Space>
            ))}
            {!(mode === 'view') && (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Button
                      block
                      className="ms-1"
                      type="primary"
                      onClick={() =>
                        add({
                          product_url: null,
                          product_name: null,
                          brand_name: null,
                          option: null,
                          quantity: null,
                          eur_price: 0,
                          eur_shipping_fee: 0,
                          product_picture: null,
                          memo: '',
                          is_ordered: role === 'free_t',
                          shipping_company: '',
                          tracking_number: '',
                          invoice_pictures: []
                        })
                      }
                      disabled={!onValidate(getFieldValue('products'))}>
                      제품 추가 (최대 10개)
                    </Button>
                  );
                }}
              </Form.Item>
            )}
          </div>
        )}
      </Form.List>
    </>
  );
};

export default ProductInfo;
