import { useState } from 'react';
import { Image, Modal } from 'antd';
import PDFViewer from '../../../../common/components/PdfViewer';

const ClickPreviewImages = ({ title, images, ...prop }) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [pdfView, setPdfView] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  return (
    <>
      <Image.PreviewGroup
        preview={{
          visible: isPreviewVisible,
          onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
          imageRender: (originalNode, info) => {
            let src = originalNode.props.src;
            const isPdf = src?.toLowerCase()?.endsWith('.pdf');
            if (isPdf) {
              src = '/pdf-icon.png';
            }
            const newNode = {
              ...originalNode,
              props: {
                ...originalNode.props,
                src,
                onClick: () => {
                  if (isPdf) {
                    setPdfView(true);
                    setPdfFile(src);
                  }
                }
              }
            };
            return newNode;
          }
        }}>
        {images?.map((img, idx) => (
          <Image
            key={`pre_img${idx}`}
            hidden={true}
            src={img?.file_view}
          />
        ))}
      </Image.PreviewGroup>
      <a
        style={{
          textDecoration: 'underline',
          // color: '#297DCB'
        }}
        onClick={() => setPreviewVisible(!isPreviewVisible)}
        {...prop}>
        {title}
      </a>
      <Modal
        centered
        open={pdfView}
        width={false}
        bodyStyle={{ height: '100vh', overflow: 'scroll' }}
        footer={null}
        onCancel={() => {
          setPdfView(false);
        }}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <PDFViewer file_view={pdfFile} />
      </Modal>
    </>
  );
};

export default ClickPreviewImages;
