import { forEach } from 'lodash';
import apiClient, { apiClientV2 } from '../../../services/axios';

export const fetchRefunds = async (data, thunkApi) => {
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
     if (value) {
      params.append(key, value);
    }
  });
  try {
    const response = await apiClient.get('/refund', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};
