import { Button, Drawer, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoLogin from '../../../asset/images/LogoLogin.svg';
import { loginThunk } from '../../../redux/reducers/authSlice';
import { CheckOutlined } from '@ant-design/icons';
import './style.scss';
import { regX_password, getOptionsValidPassword } from '../../../utility/utils';
import { X } from 'react-feather';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, isLogin } = useSelector((state) => state.auth);
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const isCheckValidForm = (values) => {
    const isValidPassword = values.password && regX_password.test(values?.password);
    return isValidPassword && values?.username;
  };

  useEffect(() => {
    if (isLogin) {
      navigate('/purchase-request');
    }
  }, [isLogin]);

  const handleSubmit = (values) => {
    dispatch(loginThunk(values));
  };

  const validateMessages = {
    types: {
      email: '${label} 형식이 올바르지 않아요'
    }
  };

  return (
    <div className="loginPage">
      <div className="content">
        <div className="logo">
          <img src={LogoLogin} />
        </div>
        <Form name="login" layout="vertical" colon={false} onFinish={handleSubmit} validateMessages={validateMessages}>
          <Form.Item label="이메일" name="username" rules={[{ type: 'email' }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item label="비밀번호" name="password" style={{ marginBottom: '8px' }}>
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item shouldUpdate={(preValue, curValue) => preValue.password !== curValue.password}>
            {({ getFieldValue }) => {
              const config = getOptionsValidPassword(getFieldValue('password'));
              const item = config.map((item) => (
                <div key={item.item} className="d-flex ms-1" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                  <CheckOutlined /> <div className="ms-1">{item.description}</div>
                </div>
              ));
              return <div className="d-flex">{item}</div>;
            }}
          </Form.Item>
          <div className="text-14 gray-900 fw-6 mb-4 text-end">비밀번호를 잃어버렸어요</div>
          <Form.Item shouldUpdate>
            {({ getFieldsValue }) => (
              <Button
                htmlType="submit"
                block
                type="primary"
                loading={loading}
                size="large"
                disabled={!isCheckValidForm(getFieldsValue())}>
                로그인
              </Button>
            )}
          </Form.Item>
          <div className="text-14 gray-700 fw-4 mt-4 text-center">
            계정이 없으신가요? <b onClick={() => setOpenSignupModal(true)}>회원가입</b>
          </div>
          <ul className="mt-4">
            <li className="text-14 gray-700 fw-4">
              영업 및 송금 확인 시간 <br />
              <strong>17:30 ~ 0:00 / 주말, 공휴일 휴무</strong>
            </li>
            <li className="text-14 gray-700 fw-4">E-mail: sales@hautevie.co</li>
          </ul>
        </Form>
      </div>
      <Drawer
        title={false}
        placement={'bottom'}
        closable={false}
        onClose={() => setOpenSignupModal(false)}
        open={openSignupModal}
        rootClassName="optiosDrawer"
        contentWrapperStyle={{
          height: '220px',
          overflow: 'scroll',
          borderRadius: '20px 20px 0px 0px'
        }}>
        <div className="d-flex justify-content-between">
          <p
            style={{
              fontSize: '16px',
              color: '#101828',
              fontWeight: '700'
            }}>
            유형을 선택해 주세요
          </p>
          <X style={{ marginBottom: '20px', fontSize: '18px' }} onClick={() => setOpenSignupModal(false)} />
        </div>
        <div>
          <Button
            type="default"
            block
            size="large"
            className="mt-4"
            style={{ textAlign: 'left' }}
            onClick={() => navigate('/sign-up')}>
            일반 고객
          </Button>
          <Button
            type="default"
            block
            size="large"
            className="mt-3"
            style={{ textAlign: 'left' }}
            onClick={() => {
              setOpenWarningModal(true);
              setOpenSignupModal(false);
            }}>
            사업자
          </Button>
        </div>
      </Drawer>
      <Modal
        closable={false}
        maskClosable={false}
        open={openWarningModal}
        footer={false}
        centered
        title={<div className="text-16 gray-900 fw-7">사업자 회원</div>}>
        <div className="text-14 gray-700 mt-3">
        사업자 회원으로 가입 예정이라면 프리티고 서비스의 리뉴얼된 ver.2 소개페이지로 연결할게요 <br/>
          <a style={{color: '#000', textDecoration: 'underline'}} href='https://hautevie.notion.site/ver-2-e7f618076c7a4952bca2fb7f9c81e123' rel="noreferrer" target='_blank'>프리티고 ver.2 소개 바로가기🔍</a>
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block type='default' onClick={() => setOpenWarningModal(false)} size="large">
            닫기
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
