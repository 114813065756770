import React from 'react';
import { List, Space, Typography, message } from 'antd';
import dayjs from 'dayjs';
import { DEPOSIT_TYPE } from './constants';
import { formatCurrency } from '../../../utility/utils';
import { ExternalLinkBlack } from '../../../asset/icons/Icons';
import ClickPreviewImages from '../../features/orderProduct/components/ClickPreviewImages';
import { getInvoiceFile } from '../../../common/api';
import FileSaver from 'file-saver';
const { Text, Link } = Typography;

const DepositListItem = ({ deposit, currency, setMemo = null, setOpenMemo = null }) => {
  const depositType = DEPOSIT_TYPE[deposit.type];

  const handleGetInvoicePDF = async (depositId, download) => {
    try {
      const data = await getInvoiceFile(depositId);
      if (download) {
        const blob = new Blob([data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, 'deposit_invoice.pdf');
      } else {
        // Open the PDF in a new tab
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      }
    } catch (e) {
      message.error('Error!');
    }
  };

  let description;
  switch (depositType.value) {
    case DEPOSIT_TYPE.add.value:
      description = (
        <Space>
          {deposit?.pictures?.length > 0 && (
            <ClickPreviewImages
              title={
                <Link underline>
                  인보이스
                  <ExternalLinkBlack />
                </Link>
              }
              images={deposit?.pictures}
            />
          )}
          {deposit?.memo && (
            <Link
              underline
              onClick={() => {
                setMemo(deposit?.memo);
                setOpenMemo(true);
              }}>
              메모
              <ExternalLinkBlack />
            </Link>
          )}
        </Space>
      );
      break;

    case DEPOSIT_TYPE.request.value:
      description = (
        <Space>
          <Link
            underline
            onClick={() => {
              handleGetInvoicePDF(deposit.id, false);
            }}>
            인보이스 확인
            <ExternalLinkBlack />
          </Link>
          <Link
            underline
            onClick={() => {
              handleGetInvoicePDF(deposit.id, true);
            }}>
            인보이스 다운
            <ExternalLinkBlack />
          </Link>
        </Space>
      );
      break;
  }

  return (
    <List.Item key={deposit.id}>
      <List.Item.Meta
        title={
          <Space direction="vertical" size={4}>
            <Text>{dayjs(deposit.date).format('YYYY.MM.DD')}</Text>
            <Text strong>{depositType?.description}</Text>
          </Space>
        }
        description={description}
      />
      <Text strong style={{ color: depositType?.color, textAlign: 'end' }}>
        {depositType.sign}
        {formatCurrency(deposit.amount, currency?.scale)}
        {currency.label}
        <br />
        {depositType?.display}
      </Text>
    </List.Item>
  );
};

export default DepositListItem;
