import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenSideBar:false,
  isOpenContactAdmin:false,
  isOpenBusinessOnboarding:false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openSideBar: (state) => {
      state.isOpenSideBar = true
    },
    closeSideBar: (state) => {
      state.isOpenSideBar = false
    },
    openContactAdmin: (state) => {
      state.isOpenContactAdmin = true
    },
    closeContactAdmin: (state) => {
      state.isOpenContactAdmin = false
    },
    openBusinessOnboarding: (state) => {
      state.isOpenBusinessOnboarding = true
    },
    closeBusinessOnboarding: (state) => {
      state.isOpenBusinessOnboarding = false
    },

  },
});

export const { openSideBar, closeSideBar, closeContactAdmin, openContactAdmin, openBusinessOnboarding, closeBusinessOnboarding } = layoutSlice.actions;

export default layoutSlice.reducer;
