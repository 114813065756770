import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import HeaderFT from '../../../common/layout/HeaderFT';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, message, Modal, Space } from 'antd';
import { fetchUserInfoThunk } from '../../../redux/reducers/authSlice';
import './style.scss';
import { CopyCardIcon, EyeOff, EyeOn } from '../../../asset/icons/Icons';
import PDFViewer from '../../../common/components/PdfViewer';

const CreditCardPage = () => {
  const [openPDFView, setOpenPDFView] = useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const card = userInfo?.card;
  const [cardNumber, setCardNumber] = useState([]);
  const [cardExpiry, setCardExpiry] = useState([]);
  const [cardCVV, setCardCVV] = useState('');
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    dispatch(fetchUserInfoThunk());
  }, []);

  useEffect(() => {
    setCardNumber(card?.card_number.match(/.{1,4}/g) || []);
    setCardExpiry(card?.card_expiry.split('/') || []);
    setCardCVV(card?.card_cvv || '');
    if (isHidden) {
      setCardNumber((prevCardNumber) => {
        const newCardNumber = [...prevCardNumber];
        for (let i = 1; i <= 3; i++) {
          newCardNumber[i] = '****';
        }
        return newCardNumber;
      });
      setCardExpiry((prevCardExpiry) => {
        const newCardExpiry = [...prevCardExpiry];
        for (let i = 0; i < 2; i++) {
          newCardExpiry[i] = '**';
        }
        return newCardExpiry;
      });
      setCardCVV('***');
    }
  }, [card, isHidden]);

  return (
    <>
      <HeaderFT title={'개별 카드'} />
      <Content className="p-2 d-flex justify-content-center">
        {card ? (
          <div className="d-flex flex-column" style={{ width: '100%', maxWidth: '343px' }}>
            <div className="card-container font-face-pv">
              <strong style={{ fontSize: '26px', fontWeight: '700', letterSpacing: '0.1em' }}>FREE_TGO</strong>
              <div className="card-item mt-5">
                <span>Card Number</span>
                <Space onClick={() => {
                      message.success('Copy Card Number successfully.');
                      navigator.clipboard.writeText(card?.card_number || '');
                    }}>
                  <div className="text-item">{cardNumber.join(' ')}</div>
                  <CopyCardIcon />
                </Space>
              </div>
              <div className="d-flex justify-content-start mt-2">
                <div className="card-item">
                  <span>VALID THRU</span>
                  <Space>
                    <div className="text-item">{cardExpiry.join('/')}</div>
                  </Space>
                </div>
                <div className="card-item" style={{ marginLeft: '35px' }}>
                  <span>CVV</span>
                  <Space onClick={() => {
                        message.success('Copy CVV successfully.');
                        navigator.clipboard.writeText(card?.card_cvv || '');
                      }}>
                    <div className="text-item">{cardCVV}</div>
                    <CopyCardIcon />
                  </Space>
                </div>
              </div>
            </div>
            <Button
              className="mt-3"
              type="primary"
              style={{ width: '100%', height: '40px', margin: '0 auto' }}
              onClick={() => {
                setIsHidden(!isHidden);
              }}>
              <Space>{isHidden ? (<><EyeOn /> 카드 정보 보기</>) : <><EyeOff /> 카드 정보 숨기기</>}</Space>
            </Button>
            <span style={{ fontWeight: '700', color: '#FF0000', marginTop: '20px' }}>
              * 구매 결제 단계에서 카드 승인 처리가 필요하기 때문에, 프리티고 카드 결제는 영업시간 내에만 가능합니다.
            </span>
            <span style={{ fontWeight: '700', marginTop: '10px' }}>
              영업시간 : 평일 오후 5시30분 ~ 오전 2시 (한국 기준)
            </span>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center flex-column justify-content-center"
            style={{ marginTop: '10vh', alignItems: 'center' }}>
            <img src={process.env.PUBLIC_URL + '/empty.png'} style={{ width: '183px' }} />
            <p style={{ textAlign: 'center', fontWeight: '700' }}>사용가능한 카드가 없어요</p>
            <Button
              className="mt-3"
              type="primary"
              style={{ backgroundColor: '#4BBF9FF2', width: '250px', height: '40px' }}
              onClick={() => {
                setOpenPDFView(true);
              }}>
              카드 발급 이용안내
            </Button>
          </div>
        )}
      </Content>
      <Modal
        centered
        open={openPDFView}
        width={false}
        bodyStyle={{ height: '100vh', overflow: 'scroll' }}
        footer={null}
        onCancel={() => {
          setOpenPDFView(false);
        }}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <PDFViewer file_view={process.env.PUBLIC_URL + '/onboarding/second_button.pdf'} />
      </Modal>
    </>
  );
};

export default CreditCardPage;
