import { useState } from 'react';
import { Image } from 'antd';
import { downloadFiles } from '../api';
import FileSaver from 'file-saver';
import PDFViewer from '../../../../common/components/PdfViewer';
import _ from 'lodash';

const ClickPreviewDownloadImages = ({ id, images, ...prop }) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const firstImage = _.filter(images || [], (img) => !img.file_view?.toLowerCase()?.endsWith('.pdf')) || images;
  const handleDownloadFiles = async () => {
    setPreviewVisible(!isPreviewVisible);
    try {
      const result = await downloadFiles(id);
      const blob = new Blob([result], { type: 'application/zip' });
      FileSaver.saveAs(blob, 'download_files.zip');
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Image.PreviewGroup
        preview={{
          visible: isPreviewVisible,
          onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible)
        }}>
        {_.flatMap(images || [], (img, idx) => {
          if (img.file_view?.toLowerCase()?.endsWith('.pdf')) {
            return [];
          }
          return <Image key={`pre_img${idx}`} hidden={true} src={img?.file_view} />;
        })}
      </Image.PreviewGroup>
      {firstImage?.[0]?.file_view?.toLowerCase()?.endsWith('.pdf') ? (
        <div
          style={{
            borderRadius: '6px', borderColor: '#344054', 
            width: '40px',
            height: '40px',
            overflow: 'scroll'
          }}
          onClick={handleDownloadFiles}>
          <PDFViewer file_view={firstImage?.[0]?.file_view} />
        </div>
      ) : (
        <div style={{ borderRadius: '6px', borderColor: '#344054', width: '40px', height: '40px', overflow: 'hidden' }}>
        <img
          style={{ width: '40px' }}
          onClick={handleDownloadFiles}
          src={firstImage?.[0]?.file_view || process.env.PUBLIC_URL + '/1px.png'}
          {...prop}
        />
        </div>
      )}
    </>
  );
};

export default ClickPreviewDownloadImages;
