import { Navigate } from 'react-router-dom';
import HTVLayout from '../common/layout/HTVLayout';
import DashboardPage from '../views/pages/dashboard/DashboardPage';
import ProfilePage from '../views/pages/profile/ProfilePage';
import OrderStatusPage from '../views/pages/orderStatus/OrderStatusPage';
import PurchaseRequestPage from '../views/pages/purchaseRequest/PurchaseRequestPage';
import CreateNewPurchaseRequest from '../views/pages/purchaseRequest/CreateNewPurchaseRequest';
import PrivateRoute from './PrivateRoute';
import FAQPage from '../views/pages/faq/FAQPage';
import PaymentInfo from '../views/pages/purchaseRequest/PaymentInfo';
import OrderProductPage from '../views/pages/orderProduct/OrderProductPage';
import OnboardingPage from '../views/pages/onboarding/OnboardingPage';
import CreditCardPage from '../views/pages/card/CreditCardPage';
import RefundPage from '../views/pages/refund/RefundPage';
import RefundListPage from '../views/pages/refund/RefundListPage';
import DepositPage from '../views/pages/deposit/DepositPage';
import DeliveryDestinationPage from '../views/pages/deliveryDestination/DeliveryDestinationPage';
import ShippingCost from '../views/pages/purchaseRequest/ShippingCost';
export { default as PrivateRoute } from './PrivateRoute';
export { default as PublicRoutes } from './PublicRoutes';

const AllPrivateRoutes = [
  {
    path: '/',
    index: true,
    element: <Navigate to="/purchase-request" replace />
  },
  // {
  //   path: 'dashboard',
  //   element: <DashboardPage />
  // },
  {
    path: 'faq',
    element: <FAQPage />
  },
  {
    path: 'card',
    element: <CreditCardPage />
  },
  {
    path: 'profile',
    element: <ProfilePage />
  },
  {
    path: 'purchase-request',
    element: <PurchaseRequestPage />
  },
  {
    path: 'create-purchase-request',
    element: <CreateNewPurchaseRequest />
  },
  {
    path: 'purchase-request/detail',
    element: <CreateNewPurchaseRequest action="view" />
  },
  {
    path: 'purchase-request/shipping-cost',
    element: <ShippingCost />
  },
  {
    path: 'purchase-request/edit',
    element: <CreateNewPurchaseRequest action="edit" />
  },
  {
    path: 'purchase-request/payment-info',
    element: <PaymentInfo />
  },
  {
    path: 'order-status',
    element: <OrderStatusPage />
  },
  {
    path: 'order-product',
    element: <OrderProductPage />
  },
  {
    path: 'onboarding',
    element: <OnboardingPage />
  },
  {
    path: 'refund',
    element: <RefundPage />
  },
  {
    path: 'refund/:type',
    element: <RefundListPage />
  },
  {
    path: 'deposit',
    element: <DepositPage />
  },
  {
    path: 'delivery-destination',
    element: <DeliveryDestinationPage />
  }
];

export const AllProtectedRoute = [
  {
    path: '/',
    element: (
      <PrivateRoute>
        <HTVLayout />
      </PrivateRoute>
    ),
    children: [...AllPrivateRoutes]
  }
];
