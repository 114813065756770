import { Tabs } from 'antd';
import React from 'react';
import DepositList from './DepositList';
import { DEPOSIT_TYPE } from './constants';

const DepositTabs = ({setMemo, setOpenMemo}) => {
  const depositDetailTabs = [
    {
      key: 'all',
      label: '전체',
      children: <DepositList setMemo={setMemo} setOpenMemo={setOpenMemo} />
    },
    {
      key: 'add',
      label: '적립',
      children: <DepositList type={DEPOSIT_TYPE.add.value} setMemo={setMemo} setOpenMemo={setOpenMemo} />
    },
    {
      key: 'used',
      label: '사용',
      children: <DepositList type={DEPOSIT_TYPE.used.value} />
    },
    {
      key: 'request',
      label: '예정',
      children: <DepositList type={DEPOSIT_TYPE.request.value} />
    },
  ];

  return <Tabs defaultActiveKey={depositDetailTabs[0].key} items={depositDetailTabs} />;
};

export default DepositTabs;
