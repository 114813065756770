import { Button, InputNumber, Modal, Space, Spin, message } from 'antd';
import React, { useState } from 'react';
import { CURRENCIES } from '../../../common/utilities/constants';
import { createDeposit, getInvoiceFile } from '../../../common/api';
import { DEPOSIT_TYPE } from './constants';
import { formatCurrency } from '../../../utility/utils';
import FileSaver from 'file-saver';

const ApplyButton = ({ buttonProps }) => {
  const [openDeposit, setOpenDeposit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [depositAmount, setDepositAmount] = useState(null);
  const [depositId, setDepositId] = useState(null);
  // const currency = role === 'free_t' ? CURRENCIES.EUR : CURRENCIES.KRW;
  const currency = CURRENCIES.EUR;
  const handleCreateDeposit = async () => {
    // Get date is now for the deposit
    const date = new Date();
    const depositData = { amount: depositAmount, type: DEPOSIT_TYPE.request.value, date };
    const data = await createDeposit(depositData);
    setDepositId(data?.id);

    if (data === null) {
      message.error('Error!');
    } else {
      message.success('Successfully!');
    }
  };
  const handleGetInvoicePDF = async (download) => {
    setLoading(true);
    try {
      const data = await getInvoiceFile(depositId);
      if (download) {
        const blob = new Blob([data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, 'deposit_invoice.pdf');
      } else {
        // Open the PDF in a new tab
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      }
    } catch (e) {
      message.error('Error!');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Button
        onClick={() => setOpenDeposit(true)}
        style={{
          position: 'fixed',
          width: '420px',
          bottom: 0,
          height: '48px',
          color: 'white',
          backgroundColor: '#10264D',
          border: 'none',
          borderRadius: 0,
          fontSize: 20
        }}
        {...buttonProps}>
        예치금 인보이스 신청하기
      </Button>
      <Modal
        closable={false}
        maskClosable={false}
        open={openDeposit}
        footer={false}
        centered
        title={<div className="text-16 gray-900 fw-7 mb-3">{!depositId ? '예치금 신청' : '예치금 인보이스'}</div>}>
        <Spin tip="Loading" spinning={loading}>
          <div className="w-100">
            <InputNumber
              className="input-number-w-button-after"
              controls={false}
              size={'large'}
              min={0}
              suffix={currency?.label}
              formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
              parser={(value) => value.replace(/,/g, '')}
              onChange={setDepositAmount}
              value={depositAmount}
              readOnly={!!depositId}
              style={{ width: '100%' }}
            />
            {!depositId ? (
              <>
                <div className="text-14 fw-5 mt-3" style={{ color: 'red', fontWeight: '700' }}>
                  * 인보이스에 적혀있는 계좌번호로 이체 후 관리자가 승인하면 예치금이 적립됩니다 <br /> * 예치금 적립은
                  프리티고 영업시간 내에만 가능합니다.
                </div>
                <div className="text-14 fw-5 mt-2" style={{ fontWeight: '700', color: '#344054' }}>
                  영업시간 : 평일 오후 5시30분 ~ 오전 2시 (한국 기준)
                </div>
              </>
            ) : (
              <div className="text-14 fw-5 mt-2" style={{ color: '#344054' }}>
                <strong style={{ color: '#F63D68' }}>
                  {formatCurrency(depositAmount, currency?.scale)}
                  {currency?.label}
                </strong>
                에 대한 인보이스 발급이 완료되었습니다.
              </div>
            )}
          </div>
          {!depositId ? (
            <div className="w-100 mt-4 d-flex">
              <Button block onClick={() => setOpenDeposit(false)} className="me-3" size="large">
                닫기
              </Button>
              <Button block type="primary" size="large" onClick={handleCreateDeposit}>
                인보이스 발급신청
              </Button>
            </div>
          ) : (
            <Space direction="vertical" size="middle" className="w-100 mt-4 d-flex">
              <Button block type="primary" size="large" onClick={() => handleGetInvoicePDF(false)}>
                인보이스 확인하기
              </Button>
              <Button block type="primary" size="large" onClick={() => handleGetInvoicePDF(true)}>
                인보이스 다운로드
              </Button>
              <Button
                block
                onClick={() => {
                  setOpenDeposit(false);
                  if (depositId) {
                    window.location.reload();
                  }
                }}
                className="me-3"
                size="large">
                닫기
              </Button>
            </Space>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default ApplyButton;
