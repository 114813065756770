import { Tabs } from 'antd';
import React from 'react';
import RefundList from './RefundList';
import { REFUND_TYPE } from './constants';

const RefundTabs = () => {
  const refundDetailTabs = [
    {
      key: 'all',
      label: '전체',
      children: <RefundList />
    },
    {
      key: 'add',
      label: '환급',
      children: <RefundList type={REFUND_TYPE.add.value} />
    },
    {
      key: 'deduct',
      label: '이체',
      children: <RefundList type={REFUND_TYPE.deduct.value} />
    },
    {
      key: 'used',
      label: '사용',
      children: <RefundList type={REFUND_TYPE.used.value} />
    },
    {
      key: 'expected',
      label: '예정',
      children: <RefundList type={REFUND_TYPE.expected.value} />
    }
  ];

  return <Tabs defaultActiveKey={refundDetailTabs[0].key} items={refundDetailTabs} />;
};

export default RefundTabs;
