import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { ChevronRight } from 'react-feather';
import { CURRENCIES } from '../../../common/utilities/constants';
import { fetchRefundAggs } from '../../../common/api';
import { formatCurrency } from '../../../utility/utils';

const RefundAggList = () => {
  const [refundAgg, setRefungAgg] = useState({});
  const currency = CURRENCIES[refundAgg.currency?.name];
  const aggregations = [
    {
      type: 'add', // Available Refund
      title: '사용 가능 환급금',
      href: '/refund/add',
      amount: refundAgg.remaining || 0
    },
    {
      type: 'accumulated', // Accumulated Refund
      title: '적립된 환급금' || 0,
      href: '/refund/accumulated',
      amount: refundAgg.accumulated
    },
    {
      type: 'expected', // Expected Refund
      title: '적립 예정 환급금' || 0,
      href: '/refund/expected',
      amount: refundAgg.expected
    },
    {
      type: 'total', // Total Refund
      title: '총 받은 환급금' || 0,
      amount: refundAgg.total
    }
  ];

  useEffect(() => {
    fetchRefundAggs().then((agg) => setRefungAgg(agg));
  }, []);

  return (
    <List
      dataSource={aggregations}
      renderItem={(item, _) => (
        <List.Item>
          <List.Item.Meta
            title={
              <a
                className="d-flex justify-content-between"
                href={item.href}
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}>
                {item.title}
                <div className="d-flex">
                  <b style={{ color: 'black' }}>
                    {formatCurrency(item.amount, currency?.scale)}
                    {currency?.label}
                  </b>
                  {item.href && <ChevronRight />}
                </div>
              </a>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default RefundAggList;
