import { List } from 'antd';
import { ChevronRight } from 'react-feather';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import HeaderFT from '../../../common/layout/HeaderFT';

const FAQPage = () => {
  const data = [
    {
      title: '프리티고 오피셜 사이트',
      link: 'https://prettygo0.mycafe24.com/'
    },
    {
      title: '배송료 및 부가서비스 비용',
      link: 'https://prettygo0.mycafe24.com/#shipping'
    },
    {
      title: '사업자회원 안내',
      link: 'https://prettygo0.mycafe24.com/#faq'
    },
    // {
    //   title: '프리티고 서비스 랜딩페이지',
    //   link: 'https://prettygo0.mycafe24.com/#service'
    // },
    {
      title: '프리티고 이용방법',
      link: 'https://prettygo0.mycafe24.com/#service'
    },
    {
      title: 'About 프리티고',
      link: 'https://prettygo0.mycafe24.com/#about'
    }
  ];
  return (
    <>
      <HeaderFT title={'FREE-TGO 가이드 & FAQ'} />
      <Content className="p-3">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    className="d-flex justify-content-between"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none'
                    }}>
                    <p className="title">{item.title}</p>{' '}
                    <div className="d-flex">
                      {item.count && <b style={{ color: 'red' }}>{item.count}</b>}
                      <ChevronRight />
                    </div>
                  </a>
                }
              />
            </List.Item>
          )}
        />
      </Content>
    </>
  );
};

export default FAQPage;
