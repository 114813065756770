import { Button, Form, Layout, message, Modal, Typography, Spin } from 'antd';
import { every, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import apiClient from '../../../services/axios';
import { X } from 'react-feather';
import ActionIcon from '../../../common/components/ActionIcons';
import RequestInfo from '../../features/purchased/components/RequestInfo';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConversionRateThunk, fetchDefaultRecipientThunk } from '../../../redux/reducers/purchaseRequestSlice';
import './style.scss';
import { getArea, getPhoneNumber } from '../../../utility/utils';
import { PURCHASE_REQUEST_STATUS } from '../../../common/utilities/constants';
import PaymentInfo from './PaymentInfo';
const { Content } = Layout;

const CreateNewPurchaseRequest = ({ action = 'new' }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusRequest, setStatusRequest] = useState('');
  const [statusRequestTag, setStatusRequestTag] = useState('');
  const requestId = ['edit', 'view'].includes(action) ? searchParams.get('id') : undefined;
  const { userInfo } = useSelector((state) => state.auth);
  const { default_recipient } = useSelector((state) => state.purchaseRequests);

  function getParams(s) {
    const params = { step: s };
    if (requestId) {
      params['id'] = requestId;
      if (action === 'view') {
        params['step'] = 2;
      }
    }
    return params;
  }

  useEffect(() => {
    setSearchParams(getParams('1'));
    dispatch(fetchConversionRateThunk());
    dispatch(fetchDefaultRecipientThunk());
  }, []);

  useEffect(() => {
    handleScrollTop();
  }, [step]);

  useEffect(() => {
    if (action === 'new' && default_recipient !== null) {
      const area = getArea(default_recipient?.phone);
      const phone = default_recipient?.phone?.replace(area?.code || '', '0');
      form.setFieldsValue({
        ...default_recipient,
        areaCode: area?.value,
        phone
      });
    }
  }, [default_recipient]);

  useEffect(() => {
    if (requestId) {
      fetchRequest(requestId);
    }
  }, [requestId]);

  useEffect(() => {
    if (action === 'edit' && statusRequest && !['pending', 'rejected'].includes(statusRequest)) {
      message.error("This request doesn't edit");
      navigate('/purchase-request');
    }
    const config = PURCHASE_REQUEST_STATUS.filter((i) => i.value === statusRequest)?.[0];
    if (config) {
      setStatusRequestTag(config.label);
    }
  }, [statusRequest]);

  const fetchRequest = async (id) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/purchase-request/${id}`);
      if (data) {
        setStatusRequest(data?.status);
        const area = getArea(data?.phone);
        const phone = data?.phone?.replace(area?.code || '', '0');
        form.setFieldsValue({
          ...data,
          areaCode: area?.value,
          phone,
          products: data.products?.map((item) => ({
            ...item
          }))
        });
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get('step')) {
      setStep(+searchParams.get('step'));
    }
  }, [searchParams.get('step')]);

  const handleToStep2 = () => {
    setSearchParams(getParams('2'));
  };

  const handleToStep1 = () => {
    setSearchParams(getParams('1'));
  };

  const handleToStep3 = () => {
    setSearchParams(getParams('3'));
  };

  const onNavigate = () => {
    if (action === 'view') {
      navigate('/purchase-request');
    } else {
      handleToStep1();
    }
  };

  const handleValidateProducts = (products) => {
    // At least one product must be provided
    if (products.length === 0 || products.length > 10) return false;
    // Validate each product
    for (const product of products) {
      for (const key in product) {
        if (
          [
            'is_ordered',
            'shipping_company',
            'tracking_number',
            'memo',
            'invoice_pictures',
            'eur_shipping_fee',
            'pictures',
            'id',
            'product_id'
          ].includes(key)
        ) {
          continue;
        }
        if (!product[key]) {
          return false;
        } else if (isArray(product[key]) && isEmpty(product[key])) {
          return false;
        }
      }
    }
    return true;
  };

  const handleValidateForm = (values) => {
    const isValidate = every(values, (value, key) => {
      if (
        [
          'basic_inspection',
          'photo_inspection',
          'fta_doc_issuance',
          'store_pickup',
          'invoice_document',
          'export_report',
          'memo',
          'use_refund_amount',
          'deposit_amount'
          // 'collect_customs'
        ].includes(key)
      ) {
        return true;
      }
      if (key === 'products') {
        return handleValidateProducts(value);
      }
      if (!value && value !== '') {
        return false;
      } else {
        return true;
      }
    });
    return !isValidate;
  };

  const onSubmit = async () => {
    const body = form.getFieldsValue();
    body['phone'] = getPhoneNumber(body.areaCode, body.phone);
    body.products?.map((product) => {
      product.invoice_pictures?.map((pic) => {
        pic.picture_type = 'invoice';
      });
    });
    if (userInfo.role === 'free_t') {
      body['fta_doc_issuance'] = false;
      // body['store_pickup'] = false;
    }
    setLoading(true);
    try {
      const res = requestId
        ? await apiClient.put(`/purchase-request/${requestId}`, body)
        : await apiClient.post('/purchase-request', body);
      message.success('Successfully');
      setLoading(false);
      const isReturnInfo = userInfo?.role === 'free_t_customer' && res?.data?.krw_total_price > 0;
      if (res?.data?.id && isReturnInfo) {
        navigate(`/purchase-request/payment-info?id=${res?.data?.id}&mode=view`);
      } else {
        navigate('/purchase-request');
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
      setLoading(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleClickSubmitBtn = async () => {
    await onSubmit();
  };

  const onEditMode = () => {
    if (action === 'new') {
      handleToStep1();
    } else {
      if (action === 'view' && ['pending', 'rejected'].includes(statusRequest)) {
        navigate(`/purchase-request/edit?id=${requestId}&step=1`, { replace: true });
      }
    }
  };

  const deleteRequest = async (id) => {
    try {
      const body = {
        status: 'deleted'
      };
      const { data } = await apiClient.put(`/purchase-request/${id}`, body);
      if (data) {
        Modal.success({
          title: <div className="text-18">삭제되었습니다</div>,
          onOk: () => {
            navigate('/purchase-request');
          },
          centered: true,
          icon: null,
          okText: '닫기',
          okButtonProps: {
            type: 'default',
            size: 'large',
            style: {
              width: '100%'
            }
          }
        });
      } else {
        message.error(error.response?.data?.message || 'error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    }
  };

  const onDelete = () => {
    Modal.confirm({
      title: <div className="text-18">신청서를 삭제 하시겠습니까?</div>,
      onCancel: () => {},
      onOk: () => {
        deleteRequest(requestId);
      },
      centered: true,
      icon: null,
      cancelText: '닫기',
      okText: '삭제',
      okButtonProps: {
        danger: true,
        type: 'primary',
        size: 'large',
        style: {
          // marginRight: '8%',
          width: '48%',
          backgroundColor: '#f63d68'
        }
      },
      cancelButtonProps: {
        style: {
          width: '48%'
        },
        size: 'large'
      }
    });
  };

  return step !== 3 ? (
    <>
      <HeaderFT2
        isHideLeftIcon={step !== 2}
        rightIems={
          step === 1 ? (
            <ActionIcon icon={<X />} onClick={() => navigate('/purchase-request')} />
          ) : (
            requestId &&
            userInfo?.role === 'free_t_customer' && (
              <Button
                type="primary"
                style={{ backgroundColor: '#4BBF9FF2' }}
                onClick={() => {
                  if (requestId) {
                    navigate(`/purchase-request/payment-info?id=${requestId}`);
                  } else {
                    handleToStep3();
                  }
                }}>
                결제정보
              </Button>
            )
          )
        }
        title={action === 'view' ? `신청서 (${statusRequestTag})` : '신청서'}
        onNavigate={onNavigate}
      />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}>
        <div className="create-new-request">
          <Spin tip="Loading" spinning={loading}>
            <Form
              name="create-purchase-request"
              layout="vertical"
              form={form}
              // onFinish={onSubmit}
              disabled={step === 2}
              initialValues={{
                recipient: null,
                areaCode: 'korean',
                phone: null,
                tax_id: null,
                address: null,
                postal_code: null,
                memo: '',
                collect_customs: false,
                // set_default_address: false,
                basic_inspection: true,
                photo_inspection: false,
                fta_doc_issuance: false,
                store_pickup: false,
                invoice_document: false,
                export_report: false,
                use_refund_amount: 0,
                deposit_amount: 0,
                products: [
                  {
                    product_url: null,
                    product_name: null,
                    product_id: null,
                    brand_name: null,
                    option: null,
                    quantity: null,
                    eur_price: 0,
                    eur_shipping_fee: 0,
                    krw_price: 0,
                    krw_refunded_price: 0,
                    eur_refunded_price: 0,
                    product_picture: null,
                    memo: '',
                    is_ordered: userInfo.role === 'free_t',
                    shipping_company: '',
                    tracking_number: '',
                    invoice_pictures: []
                  }
                ]
              }}>
              {step === 1 && (
                <>
                  <RequestInfo
                    form={form}
                    role={userInfo.role}
                    handleValidateProducts={handleValidateProducts}
                    setTotalPrice={setTotalPrice}
                  />
                  <Form.Item shouldUpdate>
                    {({ getFieldsValue }) => {
                      return (
                        <Button
                          disabled={handleValidateForm(getFieldsValue())}
                          onClick={handleToStep2}
                          className="fixed-bottom-btn"
                          block
                          type="primary">
                          다음
                        </Button>
                      );
                    }}
                  </Form.Item>
                </>
              )}
              {step === 2 && (
                <>
                  <RequestInfo
                    mode="view"
                    form={form}
                    role={userInfo.role}
                    onEditMode={onEditMode}
                    setTotalPrice={setTotalPrice}
                    requestId={requestId}
                  />
                  <Form.Item shouldUpdate>
                    {({ getFieldsValue }) => {
                      return (requestId && action === 'edit') || !requestId ? (
                        <Button
                          htmlType="submit"
                          onClick={handleClickSubmitBtn}
                          className="fixed-bottom-btn"
                          block
                          type="primary"
                          disabled={false}>
                          신청서 접수
                        </Button>
                      ) : (
                        <Button
                          htmlType="submit"
                          onClick={onDelete}
                          className="fixed-bottom-btn"
                          block
                          type="primary"
                          disabled={statusRequest !== 'pending' && requestId}
                          danger>
                          삭제
                        </Button>
                      );
                    }}
                  </Form.Item>
                </>
              )}
            </Form>
          </Spin>
        </div>
      </Content>
    </>
  ) : (
    <PaymentInfo role={userInfo.role} totalPrice={totalPrice} />
  );
};

export default CreateNewPurchaseRequest;
