import { Button, List, message, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Mail } from 'react-feather';
import { SquiggleLeft, SquiggleRight } from '../../../asset/icons/Icons';
import './style.scss';
import { apiClientV2 } from "../../../services/axios";
import { useNavigate } from 'react-router-dom';

const OnboardingPage = () => {
  const [current, setCurrent] = useState(1);
  const navigate = useNavigate();
  const maxSteps = 5;
  const next = () => {
    if (current < maxSteps) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const updateUser = async (body) => {
    const res = await apiClientV2.put('/user/me', body);
    return res;
  };

  const skip = async () => {
    try {
      const res = await updateUser({
        registered: true
      });
    } catch (error) {
      message.error(error.response?.data?.message || 'Error');
    } finally {
      navigate(`/purchase-request`);
    }
  };
  const step5Data = [
    {
      title: '프리티고 홈',
      link: 'https://prettygo0.mycafe24.com/'
    },
    {
      title: '프리티고 이용 가이드',
      link: 'https://prettygo0.mycafe24.com/#service'
    },
    // {
    //   title: '자주 묻는 질문',
    //   link: 'https://www.waveon.io/apps/20442/pages/1681600489604'
    // },
    {
      title: '사업자회원 안내',
      link: 'https://prettygo0.mycafe24.com/#b2b'
    },
    {
      title: '국제 배송료 및 부가서비스',
      link: 'https://prettygo0.mycafe24.com/#shipping'
    }
  ];
  const stepRange = Array.from({ length: maxSteps }, (_, index) => index + 1);
  return (
    <>
      <Content className="onboarding-page">
        {current > 1 && (
          <div className="d-flex onboarding-header">
            <ChevronLeft onClick={prev} />
          </div>
        )}
        <div className="onboarding-steps-container">
          <div className="content">
            {current === 1 && (
              <>
                <img src={process.env.PUBLIC_URL + '/onboarding/step1.png'} style={{ width: '229px' }} />
                <span style={{ position: 'absolute', left: '-25px', bottom: '80px' }}>
                  <SquiggleLeft />
                </span>
                <p style={{ fontSize: '16px', marginTop: '40px', textAlign: 'center' }}>프랑스 직구 택스 리펀을 위해</p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>
                  <strong style={{ color: '#4BBF9F' }}>신청서를</strong> 작성해 주세요
                </p>
              </>
            )}
            {current === 2 && (
              <>
                <img src={process.env.PUBLIC_URL + '/onboarding/step2Top.png'} style={{ width: '167px' }} />
                <img
                  src={process.env.PUBLIC_URL + '/onboarding/step2Bot.png'}
                  style={{ width: '167px', marginTop: '200px', marginLeft: '7px' }}
                />
                <p style={{ fontSize: '16px', marginTop: '30px', textAlign: 'center' }}>
                  <strong style={{ color: '#4BBF9F' }}>배송지와 제품 정보</strong>를
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>입력해 주세요</p>
              </>
            )}
            {current === 3 && (
              <>
                <img src={process.env.PUBLIC_URL + '/onboarding/step3.png'} style={{ width: '229px' }} />
                <p style={{ fontSize: '16px', marginTop: '20px', marginBottom: '10px', textAlign: 'center' }}>
                  <strong style={{ color: '#4BBF9F' }}>다양한 부가서비스를 선택한</strong>후
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center', width: '229px' }}>{"'신청서 접수'를 클릭하여 신청서를 제출하세요."}</p>
              </>
            )}
            {current === 4 && (
              <>
                <img src={process.env.PUBLIC_URL + '/onboarding/step4.png'} style={{ width: '229px' }} />
                <p style={{ fontSize: '16px', marginTop: '40px', textAlign: 'center' }}>
                  <strong style={{ color: '#4BBF9F' }}>입금이 완료</strong>되면
                </p>
                <p style={{ fontSize: '16px', textAlign: 'center' }}>신청서가 접수돼요</p>
              </>
            )}
            {current === 5 && (
              <>
                <div style={{ width: '40vh' }}>
                  <p style={{ fontWeight: '700', fontSize: '20px', textAlign: 'center' }}>
                    추가로 궁금한 사항을 선택해 주세요
                  </p>
                  <List
                    className="onboarding-list"
                    itemLayout="horizontal"
                    dataSource={step5Data}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <a
                              className="d-flex justify-content-between"
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none'
                              }}>
                              <span>{item.title}</span>{' '}
                              <div className="d-flex">
                                {item.count && <b style={{ color: 'red' }}>{item.count}</b>}
                                <ChevronRight />
                              </div>
                            </a>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
                <div style={{ position: 'relative', marginTop: '70px', width: '40vh', textAlign: 'center' }}>
                  <p style={{fontSize: '16px'}}>더 궁금한 사항이 있으시다면 <strong style={{textDecoration: 'underline'}}>문의</strong>해 주세요</p>
                  <span style={{ position: 'absolute', right: '50px', bottom: '45px' }}>
                  <SquiggleRight />
                </span>
                  <Button
                    size="large"
                    style={{
                      marginTop: '70px'
                    }}
                    block
                    className="d-flex justify-content-center"
                    onClick={() => {
                      window.open('https://vdx9l.channel.io/user-chats/6564bc7eac07d9a53815?page=https%3A%2F%2Fvdx9l.channel.io%2Fsupport-bots%2F67415', '_blank');
                    }}
                    >
                    <div className="me-1">
                      <Mail />
                    </div>
                    문의 하기
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {current === 5 ? (
          <div className="onboarding-steps" style={{ padding: '0px', border: '0px', backgroundColor: 'transparent' }}>
            <Button
              className="w-100 mt-3"
              type="primary"
              style={{ backgroundColor: '#4BBF9FF2', height: '48px', borderRadius: '0px', maxWidth: '420px' }}
              onClick={skip}>
              시작하기
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-between onboarding-steps">
            <span onClick={skip}>건너뛰기</span>
            <Space className="steps">
              {stepRange.map((step, idx) => (
                <div key={idx} className={step == current ? 'current' : step < current ? 'active' : 'inactive'}></div>
              ))}
            </Space>
            <Button type="default" onClick={next}>
              다음
            </Button>
          </div>
        )}
      </Content>
    </>
  );
};

export default OnboardingPage;
