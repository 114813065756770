import { forEach } from 'lodash';
import apiClient, { apiClientV2 } from '../../../services/axios';

export const fetchOrderProduct = async (data, thunkApi) => {
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (key === 'status') {
      if (value !== 'all') {
        params.append(key, value);
      }
    } else if (value) {
      params.append(key, value);
    }
  });
  try {
    const response = await apiClient.get('/purchase-request/order', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchOrderProductList = async (data, thunkApi) => {
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (key === 'status') {
      if (value !== 'all') {
        params.append(key, value);
      }
    } else if (value) {
      params.append(key, value);
    }
  });
  try {
    const response = await apiClient.get('/purchase-request/order/product', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchOrderProductStatus = async (data, thunkApi) => {
  try {
    const response = await apiClient.get('/purchase-request/order/agg/status');
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchTotalRefund = async () => {
  try {
    const response = await apiClient.get('/purchase-request/order/product/total-est-refund');
    if (response.status === 200) {
      return response.data;
    }
    return 0;
  } catch (e) {
    return 0;
  }
};

export const fetchOrderProductListStatus = async (data, thunkApi) => {
  try {
    const params = new URLSearchParams();
    forEach(data, (value, key) => {
      if (key === 'status') {
        if (value !== 'all') {
          params.append(key, value);
        }
      } else if (value) {
        params.append(key, value);
      }
    });
    const response = await apiClient.get('/purchase-request/order/product/agg/status', { params });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const downloadFiles = async (id) => {
    try {
      const response = await apiClient.get(`/purchase-request/order/product/${id}/download-files`, {
        responseType: 'blob',
      });
      if (response.status == 200) {
        return response.data;
      }
      return null;
    } catch (e) {
      return null;
    }
}
