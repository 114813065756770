import { Content } from 'antd/es/layout/layout';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card, Spin, message, Row, Col, Button } from 'antd';
import apiClient from '../../../services/axios';
import { CheckedIcon } from '../../../asset/icons/Icons';
import { formatCurrency } from '../../../utility/utils';
import dayjs from 'dayjs';
import ActionIcon from '../../../common/components/ActionIcons';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';

const PaymentInfo = ({ totalPrice = 0 }) => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const requestId = searchParams.get('id');
  const mode = searchParams.get('mode');
  const [data, setData] = useState(null);
  const onClickCopy = () => {
    message.success('Copy 계좌번호 successfully.');
    navigator.clipboard.writeText('80350100120333');
  };
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (requestId) {
      fetchRequest(requestId);
    }
  }, [requestId]);

  const fetchRequest = async (id) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/purchase-request/${id}`);
      if (data) {
        setData(data);
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderFT2
        isHideLeftIcon={!!mode}
        title={'신청서 접수 완료'}
        rightIems={mode === 'view' ? <ActionIcon icon={<X />} onClick={() => navigate('/purchase-request')} /> : <></>}
      />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}>
        <div className="payment-info my-4 mx-3">
          <Spin tip="Loading" spinning={loading}>
            <Card>
              <div className="d-flex flex-column">
                <Row>
                  <Col span={24} className="d-flex justify-content-center">
                    <CheckedIcon />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="d-flex justify-content-center">
                    <h3 className="gray-700 text-20 my-4">신청 완료</h3>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="d-flex flex-column" style={{ textAlign: 'center' }}>
                    <span>
                      <b className="text-16" style={{color: 'red',}}>
                        {dayjs(data?.updated_at).add(1, 'day').format('YYYY년 M월 DD일')} 23:59
                      </b>{' '}
                      까지
                    </span>
                    <span>아래 계좌로 입금을 완료해 주시면</span>
                    <span>결제 요청이 완료됩니다</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="d-flex justify-content-between mt-3">
                      <div>총 입금액</div>
                      <div className="text-16" style={{ color: '#4BBF9FF2' }}>
                        {userInfo.role === 'free_t'
                          ? `${formatCurrency((data?.eur_total_price || totalPrice)?.toFixed(2))} EUR`
                          : `${formatCurrency((data?.krw_total_price || totalPrice)?.toFixed(2), 0)} KRW`}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div>은행명</div>
                      <div className="text-16">국민은행</div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div>계좌번호</div>
                      <div className="text-16">80350100120333</div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div>예금주</div>
                      <div className="text-16">(주)오뜨비</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      style={{ backgroundColor: '#4BBF9FF2' }}
                      onClick={onClickCopy}>
                      입금 계좌번호 복사
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
            <p
              className="mt-4"
              >
              입금 확인은 한국 시간 기준 오후 5시 ~ 자정에 진행되며, <span style={{
                color: 'red',
                fontWeight: 'bolder'
              }}>{dayjs(data?.updated_at).add(1, 'day').format('YYYY년 M월 DD일')} 23:59 까지</span> 입금 확인이 되지 않을 경우 신청서는 자동 취소 됩니다
            </p>
          </Spin>
        </div>
      </Content>
    </>
  );
};

export default PaymentInfo;
