import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { find, map, reduce } from 'lodash';
import { ORDER_PRODUCT_STATUS, ORDER_STATUS, getStatusLabel } from '../../common/utilities/constants';
import {
  fetchOrderProduct,
  fetchOrderProductList,
  fetchOrderProductStatus,
  fetchOrderProductListStatus
} from '../../views/features/orderProduct/api';

export const fetchOrderProductThunk = createAsyncThunk('ft/orderProducts/all', async (params, thunkApi) => {
  return await fetchOrderProduct(params, thunkApi);
});
export const fetchOrderProductListThunk = createAsyncThunk('ft/orderProducts/list/all', async (params, thunkApi) => {
  return await fetchOrderProductList(params, thunkApi);
});
export const fetchOrderProductStatusThunk = createAsyncThunk(
  'ft/orderProducts/status/all',
  async (params, thunkApi) => {
    return await fetchOrderProductStatus(params, thunkApi);
  }
);

export const fetchOrderProductListStatusThunk = createAsyncThunk(
  'ft/orderProducts/list/status/all',
  async (params, thunkApi) => {
    return await fetchOrderProductListStatus(params, thunkApi);
  }
);

const initialState = {
  loading: false,
  totalPage: 0,
  totalResources: 0,
  page: 1,
  size: 50,
  resources: [],
  statusOptions: [],
  orderProductStatusOptions: [],
  committed_count: 0,
  // Order Product
  orderProductLoading: false,
  orderProductTotalPage: 0,
  orderProductTotalResources: 0,
  orderProductPage: 1,
  orderProductSize: 50,
  orderProductTotalRefund: 0,
  orderProductResources: [],
};

export const purchaseRequestSlice = createSlice({
  name: 'orderProducts',
  initialState,
  reducers: {
    changeSize: (state, action) => {
      state.size = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeOrderProductSize: (state, action) => {
      state.orderProductSize = action.payload;
    },
    changeOrderProductPage: (state, action) => {
      state.orderProductPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderProductThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrderProductThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.totalPage = action.payload.total_pages;
        state.totalResources = action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchOrderProductThunk.rejected, (state, action) => {
        state.loading = false;
        state.totalResources = 0;
        state.totalPage = 0;
        state.resources = [];
      })
      .addCase(fetchOrderProductListThunk.pending, (state) => {
        state.orderProductLoading = true;
      })
      .addCase(fetchOrderProductListThunk.fulfilled, (state, action) => {
        state.orderProductResources = action.payload.resources;
        state.orderProductTotalPage = action.payload.total_pages;
        state.orderProductTotalResources = action.payload.total_resources;
        state.orderProductLoading = false;
        state.orderProductTotalRefund = reduce(
          state.orderProductResources,
          (sum, value) => (value?.refund_price || 0) + sum,
          0
        );
      })
      .addCase(fetchOrderProductListThunk.rejected, (state, action) => {
        state.orderProductLoading = false;
        state.orderProductTotalResources = 0;
        state.orderProductTotalPage = 0;
        state.orderProductResources = [];
      })
      .addCase(fetchOrderProductListStatusThunk.fulfilled, (state, action) => {
        const status = action.payload || {};
        const newStatus = map(status, (value, key) => {
          return {
            value: key,
            label: getStatusLabel(key, ORDER_PRODUCT_STATUS) + ' ' + `(${value})`
          };
        });
        // const sum = reduce(status, (sum, value) => value + sum, 0);
        // newStatus.unshift({
        //   value: 'all',
        //   label: `전체 (${sum})`
        // });
        state.orderProductStatusOptions = newStatus;
      })
      .addCase(fetchOrderProductStatusThunk.fulfilled, (state, action) => {
        const status = action.payload || {}; 
        // const newStatus = map(status, (value, key) => {
        //   if (key === 'committed') {
        //     state.committed_count = value;
        //   }
        //   return {
        //     value: key,
        //     label: getStatusLabel(key, ORDER_STATUS) + ' ' + `(${value})`
        //   };
        // });
        const orderedStatus = [];
        for (const item of ORDER_STATUS) {
          const value = status[item.value] || 0;
          if (item.value === 'committed') {
            state.committed_count = value;
          }
          orderedStatus.push({
            value: item.value,
            label: item.label + ' ' + `(${value})`
          })
        }
        state.statusOptions = orderedStatus;
      });
  }
});

export const {
  changePage,
  changeSize,
  changeOrderProductSize,
  changeOrderProductPage,
} = purchaseRequestSlice.actions;

export default purchaseRequestSlice.reducer;
