import { Button, Form, Input, Layout, message, Modal, Radio } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderFT2 from '../../../../common/layout/HeaderFT2';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { apiClientV2 } from '../../../../services/axios';
import { loginThunk } from '../../../../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { regX_password, getOptionsValidPassword } from '../../../../utility/utils';

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading, isLogin } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [role, setRole] = useState('free_t_customer');
  const [signUpLoading, setSignUpLoading] = useState(false);

  const isCheckValidForm = (values) => {
    const isValidPassword = values.password && regX_password.test(values?.password);
    return (
      values?.full_name &&
      isValidPassword &&
      values?.username &&
      values?.confirm_password &&
      values?.confirm_password === values.password
    );
  };

  useEffect(() => {
    if (isLogin) {
      setOpenModal(false);
      navigate(`/purchase-request`);
    }
  }, [isLogin]);

  const signUp = async (body) => {
    setSignUpLoading(true);
    try {
      const { data } = await apiClientV2.post('/auth/signup', body);
      setSignUpLoading(false);
      setOpenModal(true);
    } catch (error) {
      setSignUpLoading(false);
      message.error(error?.response?.data?.message || 'Sign up failed');
    }
  };

  const handleSignUp = (values) => {
    const { full_name, username, password, role, site_id } = values;
    signUp({
      full_name,
      username,
      password,
      role: 'free_t_customer',
      site_id
    });
  };

  const handleSignIn = () => {
    const { username, password } = form.getFieldsValue();
    dispatch(
      loginThunk({
        username,
        password
      })
    );
  };

  return (
    <Layout
      style={{
        backgroundColor: '#fff',
        paddingBottom: '10px'
      }}>
      <HeaderFT2 title={'회원가입'} onNavigate={() => navigate('/login')} isShadow={false} titleCenter={true} />
      <Content
        style={{
          backgroundColor: '#fff',
          marginTop: '20px'
        }}>
        <div className="container-page d-flex flex-column" style={{ minHeight: 'calc(100vh - 120px)' }}>
          <Form
            layout="vertical"
            form={form}
            name="signIn"
            initialValues={{
              full_name: null,
              username: null,
              password: null,
              confirm_password: null,
              role: 'free_t_customer',
              site_id: null
            }}
            onFinish={handleSignUp}>
            <Form.Item name="full_name" label={<div className="text-14 gray-900 fw-5">이름</div>}>
              <Input placeholder="이름 입력" size="large" />
            </Form.Item>
            <Form.Item
              name="username"
              label={<div className="text-14 gray-900 fw-5">이메일</div>}
              rules={[
                {
                  type: 'email',
                  message: '이메일 형식이 올바르지 않아요'
                }
              ]}>
              <Input placeholder="아이디(이메일) 입력" size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label={<div className="text-14 gray-900 fw-5">비밀번호</div>}
              style={{ marginBottom: '8px' }}>
              <Input.Password placeholder="비밀번호 입력" size="large" />
            </Form.Item>
            <Form.Item shouldUpdate={(preValue, curValue) => preValue.password !== curValue.password}>
              {({ getFieldValue }) => {
                const config = getOptionsValidPassword(getFieldValue('password'));
                const item = config.map((item) => (
                  <div key={item.item} className="d-flex ms-1" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                    {/* {item.isValid ? <CheckOutlined /> : <CloseOutlined />}{' '} */}
                    <CheckOutlined /> <div className="ms-1">{item.description}</div>
                  </div>
                ));
                return <div className="d-flex">{item}</div>;
              }}
            </Form.Item>
            <Form.Item name="confirm_password" style={{ marginBottom: '8px' }}>
              <Input.Password placeholder="비밀번호 확인" size="large" />
            </Form.Item>
            <Form.Item shouldUpdate={(preValue, curValue) => preValue.confirm_password !== curValue.confirm_password}>
              {({ getFieldValue }) => {
                const item = {
                  key: 4,
                  description: '비밀번호 일치',
                  isValid:
                    getFieldValue('confirm_password') && getFieldValue('password') == getFieldValue('confirm_password')
                };
                return (
                  <div key={item.item} className="d-flex" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                    {item.isValid ? <CheckOutlined /> : <CloseOutlined />}{' '}
                    <div className="ms-1">{item.description}</div>
                  </div>
                );
              }}
            </Form.Item>
            <Form.Item
              name="site_id"
              label={<div className="text-14 gray-900 fw-5">기존 사이트 아이디</div>}
              extra={<div style={{ color: '#F63D68' }}>*이전 사이트 내 적립금이 3일 내 반영됩니다</div>}>
              <Input placeholder="기존 아이디를 입력해 주세요" size="large" />
            </Form.Item>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => (
                <Button
                  block
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={signUpLoading}
                  disabled={!isCheckValidForm(getFieldsValue())}>
                  회원가입
                </Button>
              )}
            </Form.Item>
          </Form>
          <div className="text-14 gray-700 fw-4 mt-4 text-center">
            계정이 있으신가요?{' '}
            <b className="text-decoration-underline" size="large" onClick={() => navigate('/login')}>
              로그인
            </b>
          </div>
        </div>
      </Content>
      <Modal
        closable={false}
        maskClosable={false}
        open={openModal}
        footer={false}
        centered
        title={<div className="text-16 gray-900 fw-7">회원가입 성공</div>}>
        <div className="text-14 gray-700 ">
          바로 로그인하시려면 아래 <b>{`"로그인"`}</b> 버튼을 선택해 주세요
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={() => navigate('/login')} className="me-3" size="large">
            닫기
          </Button>
          <Button block type="primary" onClick={handleSignIn} loading={loading} size="large">
            로그인
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default SignUpPage;
