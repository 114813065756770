import { Button, Col, Empty, Pagination, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronDown } from '../../../asset/icons/Icons';
import HeaderFT from '../../../common/layout/HeaderFT';
import {
  changePage,
  fetchOrderProductThunk,
} from '../../../redux/reducers/orderProductSlice';
import ProductCard from '../../features/orderProduct/components/ProductCard';
import './style.scss';

const OrderStatusPage = () => {
  const [selected, setSelected] = useState('all');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key') || 'all';
  const { totalResources, page, size, resources, loading, statusOptions } = useSelector(
    (state) => state.orderProducts
  );
  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    setSelected(key);
  }, []);

  useEffect(() => {
    if (selected === key) {
      dispatch(fetchOrderProductThunk({ status: selected, page, size }));
    }
  }, [page, selected]);

  const handleChangeSelect = (value) => {
    setSelected(value);
     if (value) {
      navigate({
        search: createSearchParams({
          key: value
        }).toString()
      });
    } else {
      navigate({
        search: createSearchParams({}).toString()
      });
    }
  };

  const handleChangePagination = (current) => {
    dispatch(changePage(current));
  };

  return (
    <>
      <HeaderFT
        title={'주문 현황'}
      />
      <Content
        style={{
          paddingBottom: '50px'
        }}>
        <div className="container-page">
          <Select
            value={selected}
            onChange={handleChangeSelect}
            style={{
              width: '100%',
              marginTop: '24px',
              marginBottom: '22px',
              border: '1px solid #D0D5DD',
              borderRadius: '5px'
            }}
            suffixIcon={<ChevronDown />}
            bordered={false}
            size="large"
            options={statusOptions}
          />
          <Spin tip="Loading" spinning={loading}>
            {resources?.length > 0 ? (
              <>
                <Row>
                  {resources?.map((item, idx) => {
                    return (
                      <Col key={idx} xs={{ span: 24 }} lg={{ span: 24 }} className="mb-3">
                        <ProductCard data={item} key={idx} role={userInfo.role} />
                      </Col>
                    );
                  })}
                </Row>
                <div className="d-flex justify-content-center mt-1">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={size}
                    total={totalResources}
                    onChange={handleChangePagination}
                  />
                </div>
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Spin>
        </div>
      </Content>
    </>
  );
};

export default OrderStatusPage;
