import { Row, Typography, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import HeaderFT from '../../../common/layout/HeaderFT';
const { Text, Title } = Typography;

const DeliveryDestinationPage = () => {
  return (
    <>
      <HeaderFT title={'프랑스 배송 주소'} />
      <Space direction="vertical" style={{ backgroundColor: '#F2F4F7' }} size={'middle'}>
        <Content style={{ backgroundColor: 'white', padding: '32px 16px' }}>
          <Title level={5} type="danger">
            * 회사 주소와 배송 주소가 다르기 때문에 반드시 구분하여 작성해야 합니다
          </Title>
          <Title level={4}>{"Facturation L'adress (Building address)"}</Title>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row justify="space-between">
              <Text strong>{'Name / Sur Name'}</Text>
              <Text>{'GC + 본인 업체'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Address 1'}</Text>
              <Text>{'101 rue de sevres'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Address 2'}</Text>
              <Text>{'GC'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Postal (Zip Code)'}</Text>
              <Text>{'75006'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'City'}</Text>
              <Text>{'Paris'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Number'}</Text>
              <Text>{'+33766412297'}</Text>
            </Row>
          </Space>
        </Content>
        <Content style={{ backgroundColor: 'white', padding: '32px 16px' }}>
          <Title level={4}>{"Livrasion L'adress (Delivery address)"}</Title>
          <Space direction="vertical" style={{ width: '100%', marginBottom: '24px' }}>
            <Row justify="space-between">
              <Text strong>{'Name / Sur Name'}</Text>
              <Text>{'GC + 본인 업체'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Address 1'}</Text>
              <Text>{'15 rue des mathurins'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Address 2'}</Text>
              <Text>{'GC'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Postal (Zip Code)'}</Text>
              <Text>{'75009'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'City'}</Text>
              <Text>{'Paris'}</Text>
            </Row>
            <Row justify="space-between">
              <Text strong>{'Number'}</Text>
              <Text>{'+33766412297'}</Text>
            </Row>
          </Space>
          <Title level={5} type="danger">
            * 럭셔리 브랜드 공식홈페이지에서 제품 구매시 이름을 회사이름으로 기재하면 주문이 취소되는 경우가 많습니다.
            따라서 이름은 실제 성함으로 작성해주시고 회사이름 GC는 청구지 2번째 주소에 기재해주시면 좋습니다.
          </Title>
        </Content>
      </Space>
    </>
  );
};

export default DeliveryDestinationPage;
