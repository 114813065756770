import { DoubleLeftOutlined } from '@ant-design/icons';
import { Button, Drawer, Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserOutline } from '../../asset/icons/Icons';
import LogoLogin from '../../asset/images/LogoLogin.svg';
import { fetchUserInfoThunk, logoutThunk } from '../../redux/reducers/authSlice';
import { closeSideBar, openContactAdmin, openBusinessOnboarding } from '../../redux/reducers/layoutSlice';
import { Mail, LogOut, ChevronRight } from 'react-feather';
import ContactAdmin from './ContactAdmin';
import BusinessOnboarding from './BusinessOnboarding';

const HTVLayout = () => {
  const [selectedKey, setSelectedKey] = useState('purchase-request');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { isOpenSideBar } = useSelector((state) => state.layout);
  // const { pending_count } = useSelector((state) => state.purchaseRequests);
  // const { committed_count } = useSelector((state) => state.orderProducts);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (location?.pathname && location?.pathname !== selectedKey) {
      setSelectedKey(location?.pathname?.split('/')?.[1]);
    }
    dispatch(fetchUserInfoThunk());
  }, [dispatch]);

  const getLabelItem = (label, count, hideIcon = false) => {
    return (
      <>
        <p>{label}</p>{' '}
        <div className="d-flex  align-items-center">
          {count && <b style={{ color: 'red' }}>{count}</b>}
          {!hideIcon && <ChevronRight />}
        </div>
      </>
    );
  };

  const navItems = [
    // {
    //   label: getLabelItem('대시보드', undefined, true),
    //   key: 'dashboard'
    // },
    // {
    //   label: getLabelItem('구매 대행 신청', undefined),
    //   key: 'purchase-request'
    // },
    {
      label: getLabelItem('주문 현황', undefined),
      key: 'purchase-request'
    },
    {
      label: getLabelItem('환급금', undefined, true),
      key: 'refund'
    },
    userInfo?.role === 'free_t' && {
      label: getLabelItem('예치금', undefined, true),
      key: 'deposit'
    },
    {
      label: '프랑스 배송 주소',
      key: 'delivery-destination'
    },
    userInfo?.role === 'free_t' && {
      label: getLabelItem('개별 카드', undefined, true),
      key: 'card'
    },
    {
      label: getLabelItem('FREE-TGO 가이드 & FAQ', undefined, true),
      key: 'faq'
    },
    userInfo?.role === 'free_t'
      ? {
          label: (
            <a
              href="#"
              style={{
                textDecoration: 'none'
              }}
              onClick={() => {
                dispatch(closeSideBar());
                setTimeout(() => {
                  dispatch(openBusinessOnboarding());
                }, 500);
              }}>
              <p style={{ color: 'rgba(0, 0, 0, 0.88)' }}>이용 방법 확인하기</p>
            </a>
          ),
          key: 'onboarding',
          disabled: true
        }
      : {
          label: getLabelItem('이용 방법 확인하기', undefined, true),
          key: 'onboarding'
        }
    // {
    //   label: (
    //     <a
    //       href="https://bylocl.notion.site/bylocl/HAUTEVIE-LIMITED-LUXURY-MD-GUIDE-954b7e92cf864ec790effa79688ffbf8"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       style={{
    //         textDecoration: 'none'
    //       }}>
    //       <p style={{ color: 'rgba(0, 0, 0, 0.88)' }}>FREE-TGO Guide & FAQ</p>
    //     </a>
    //   ),
    //   key: 'link',
    //   disabled: true
    // }
  ];
  const handleOnlClickNav = (e) => {
    if (e.key !== 'link') {
      const path = e.keyPath?.[0];
      setSelectedKey(e.key);
      navigate(path);
    }
    dispatch(closeSideBar());
  };

  const handleClickLogout = () => {
    dispatch(logoutThunk());
  };

  let cssCustom = {
    backgroundColor: '#fff',
    paddingBottom: '10px',
    maxWidth: '420px',
    margin: '0 auto',
    minHeight: '100dvh',
    height: '100%',
    border: '1px solid #D0D5DD',
    borderBottom: '0px',
    borderTop: '0px'
  };

  if (location?.pathname && ['/order-product'].includes(location?.pathname)) {
    cssCustom = {
      backgroundColor: '#fff',
      paddingBottom: '10px'
    };
  }

  return (
    <>
      <Layout style={cssCustom}>
        <Outlet />
      </Layout>
      <Drawer
        title={
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img style={{ height: '22px' }} src={LogoLogin} />
              </div>
              <Button onClick={() => dispatch(closeSideBar())}>
                <DoubleLeftOutlined />
              </Button>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div>
                <div style={{ fontSize: '14px', color: '#101828' }}>
                  <b>Hello, {userInfo?.full_name}</b>
                </div>
                <div style={{ fontSize: '12px', color: '#667085' }}>{userInfo?.username}</div>
              </div>
              <div>
                <Button
                  icon={<UserOutline />}
                  onClick={() => {
                    navigate('/profile');
                    dispatch(closeSideBar());
                  }}
                />
              </div>
            </div>
          </div>
        }
        placement="left"
        onClose={() => dispatch(closeSideBar())}
        open={isOpenSideBar}
        contentWrapperStyle={{
          width: '290px'
        }}
        closable={false}>
        <Menu
          mode="inline"
          className="htv-layout"
          defaultSelectedKeys={selectedKey}
          style={{ height: '70%', borderRight: 0 }}
          items={navItems}
          onClick={handleOnlClickNav}
        />
        <ul className="mt-4">
          <li className="text-14 gray-700 fw-4">
            영업 및 송금 확인 시간 <br/><strong>17:30 ~ 0:00 / 주말, 공휴일 휴무</strong>
          </li>
          <li className="text-14 gray-700 fw-4">E-mail: sales@hautevie.co</li>
        </ul>
        <Button
          size="large"
          style={{
            marginBottom: '16px'
          }}
          onClick={() => {
            dispatch(closeSideBar());
            setTimeout(() => {
              dispatch(openContactAdmin());
            }, 500);
          }}
          block
          className="d-flex">
          <div className="me-1">
            <Mail />
          </div>
          문의 하기
        </Button>
        <Button
          size="large"
          onClick={handleClickLogout}
          block
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <div>Logout</div>
          <div>
            <LogOut />
          </div>
        </Button>
      </Drawer>
      <ContactAdmin />
      <BusinessOnboarding is_active={userInfo?.active || false} />
    </>
  );
};
export default HTVLayout;
