import React from 'react';
import { List, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { REFUND_TYPE } from './constants';
import { formatCurrency } from '../../../utility/utils';
import { ExternalLinkBlack } from '../../../asset/icons/Icons';
const { Text, Link } = Typography;

const RefundListItem = ({ refund, currency }) => {
  const refundType = REFUND_TYPE[refund.type];

  let description;
  switch (refundType.value) {
    case REFUND_TYPE.accumulated.value:
      description = `${dayjs(refund.available_at).format('YYYY.MM.DD')} 이후 사용 가능`;
      break;
    // case REFUND_TYPE.deduct.value:
    //   description = (
    //     <Space>
    //       <Link underline>
    //         인보이스
    //         <ExternalLinkBlack />
    //       </Link>
    //       <Link underline>
    //         메모
    //         <ExternalLinkBlack />
    //       </Link>
    //     </Space>
    //   );
    //   break;
  }

  return (
    <List.Item key={refund.id}>
      <List.Item.Meta
        title={
          <Space direction="vertical" size={4}>
            <Text>{dayjs(refund.date).format('YYYY.MM.DD')}</Text>
            <Text strong>{refundType?.description}</Text>
          </Space>
        }
        description={description}
      />
      <Text strong style={{ color: refundType?.color, textAlign: 'end' }}>
        {refundType.sign}
        {formatCurrency(refund.amount, currency?.scale)}
        {currency.label}
        <br />
        {refundType?.display}
      </Text>
    </List.Item>
  );
};

export default RefundListItem;
