import { Input, Form, Select, Checkbox, Space, Typography, Drawer } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { MOBILE_TYPE_OPTIONS } from '../../../../common/utilities/constants';
import ProductInfo from './ProductInfo';
import TotalForm from './TotalForm';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const RequestInfo = ({ mode = 'edit', form, role, handleValidateProducts, onEditMode, setTotalPrice, requestId }) => {
  const [openRefundRateModal, setOpenRefundRateModal] = useState(false);
  const { refund_rate } = useSelector((state) => state.purchaseRequests);

  return (
    <>
      <div className="container-page" style={{ backgroundColor: '#F2F4F7', padding: '0' }}>
        <div className="mt-3 bg-white px-3">
          {!requestId && mode === 'view' && <div className="text-14 gray-900 fw-5" style={{ color: 'red', fontWeight: 700 }}>
            * 주문서 최종 제출 전, 스크롤을 내려 작성 내용을 확인 후 접수 버튼을 눌러주세요.
          </div>}
          <div className="d-flex justify-content-between align-items-center">
            <Typography.Title level={4} className="fw-7">
              배송 정보
            </Typography.Title>
            <div>
              <span style={{ color: 'red' }}>*</span> 필수
            </div>
          </div>
        </div>
        <div className="py-4 px-3 bg-white">
          <Form.Item
            name="recipient"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  수취인 <span style={{ color: 'red' }}>*</span>
                </div>
                {mode === 'view' && <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />}
              </div>
            }
            required>
            <Input size="large" placeholder="수취인" />
          </Form.Item>
          <Form.Item
            name="areaCode"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  전화 번호 <span style={{ color: 'red' }}>*</span>
                </div>
                {mode === 'view' && <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />}
              </div>
            }>
            <Select size="large">
              {MOBILE_TYPE_OPTIONS.map((opt, idx) => (
                <Select.Option key={`mobileTypeOpt${idx}`} value={opt.value} label={opt.label}>
                  <Space>
                    {/* {opt.icon} */}
                    {opt.label}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="phone" className="w-100">
            <Input size="large" placeholder="전화 번호" className="w-100" />
          </Form.Item>
          <Form.Item
            name="postal_code"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  우편 번호 <span style={{ color: 'red' }}>*</span>
                </div>
                {mode === 'view' && <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />}
              </div>
            }
            required>
            <Input size="large" placeholder="우편 번호" />
          </Form.Item>
          <Form.Item
            name="address"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  주소 <span style={{ color: 'red' }}>*</span>
                </div>
                {mode === 'view' && <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />}
              </div>
            }
            required>
            <Input size="large" placeholder="주소" />
          </Form.Item>
          <Form.Item
            name="tax_id"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  개인 통관 부호 <span style={{ color: 'red' }}>*</span>
                </div>
                {mode === 'view' && <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />}
              </div>
            }
            required>
            <Input size="large" placeholder="개인 통관 부호" />
          </Form.Item>
          <a href="https://unipass.customs.go.kr/csp/persIndex.do" target="_blank" rel="noopener noreferrer">
            <p>개인 통관 부호 조회/발급</p>
          </a>
          <Form.Item name="collect_customs" valuePropName="checked" required>
            <Checkbox
              size="large"
              // defaultChecked={form.getFieldValue('collect_customs')}
              // onChange={(e) => form.setFieldsValue({ collect_customs: e.target.checked })}
            >
              <div className="text-14 gray-900 fw-5">
                통관 정보 수집 및 제공 동의 <span style={{ color: 'red' }}>*</span>
              </div>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="memo"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">메모</div>
                {mode === 'view' && <EditOutlined style={{ fontSize: '20px' }} onClick={() => onEditMode()} />}
              </div>
            }>
            <TextArea maxLength={200} style={{ height: 80, resize: 'none' }} placeholder="메모를 작성해 주세요" />
          </Form.Item>
        </div>
        <div className="py-4 px-3 bg-white mt-3">
          <div className="text-14 gray-900 fw-5" style={{ color: 'red', fontWeight: 700 }}>
            * 에르메스, 셀린느 브랜드는 1회 신청서에 단일 제품, 단일 수량만 신청할 수 있습니다.
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <Typography.Title level={4} className="fw-7">
              제품 정보
            </Typography.Title>
            <div>
              <span style={{ color: 'red' }}>*</span> 필수
            </div>
          </div>
          <ProductInfo
            mode={mode}
            onValidate={handleValidateProducts}
            form={form}
            role={role}
            setOpenRefundRateModal={setOpenRefundRateModal}
            onEditMode={onEditMode}
          />
        </div>
        <TotalForm form={form} mode={mode} onEditMode={onEditMode} role={role} setTotalPrice={setTotalPrice} />
      </div>
      <Drawer
        title={'돌려받을 금액'}
        closable={false}
        placement={'bottom'}
        onClose={() => setOpenRefundRateModal(false)}
        open={openRefundRateModal}
        rootClassName="RefundRateDrawer"
        extra={
          <CloseOutlined
            onClick={() => {
              setOpenRefundRateModal(false);
            }}
          />
        }
        contentWrapperStyle={{
          height: '150px',
          overflow: 'scroll'
        }}>
        <ul>
          <li>
            {/* 예상 환급금액은 <span style={{ color: '#F63D68' }}>총 결제금액의 {refund_rate * 100}%</span> 입니다 */}
            프랑스 부가세 20%중 <span style={{ color: '#F63D68' }}>10%</span>를 돌려받을 수 있어요
          </li>
        </ul>
      </Drawer>
    </>
  );
};

export default RequestInfo;
