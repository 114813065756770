import { FranceFlag, KoreanFlag } from '../../asset/icons/Icons';
import { filter } from 'lodash';

export const ORDER_PRODUCT_STATUS = [
  {
    label: '전체',
    value: 'all'
  },
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Confirmed',
    value: 'confirmed'
  },
  {
    label: '포장 준비중',
    value: 'committed'
  },
  {
    label: '포장 완료',
    value: 'packed'
  },
  {
    label: 'LocalWhIn',
    value: 'local_wh_in'
  },
  {
    label: 'LocalDeparture',
    value: 'local_departure'
  },
  {
    label: '세관 도착',
    value: 'poe_arrival'
  },
  {
    label: 'CustomsCleared',
    value: 'customs_cleared'
  },
  {
    label: '배송 완료',
    value: 'delivered'
  },
  {
    label: 'Completed',
    value: 'completed'
  },
  {
    label: 'Replacement Request',
    value: 'replacement_request'
  },
  {
    label: 'Replacement Pending',
    value: 'replacement_pending'
  },
  {
    label: 'Replacement Accepted',
    value: 'replacement_accepted'
  },
  {
    label: 'Refund Request',
    value: 'refund_request'
  },
  {
    label: 'Refund Accepted',
    value: 'refund_accepted'
  },
  {
    label: 'Refunded',
    value: 'refunded'
  },
  {
    label: 'Cancellation Request',
    value: 'cancellation_request'
  },
  {
    label: 'Cancelled',
    value: 'cancelled'
  }
];

export const ORDER_SORT_BY_DEFAULT = [
  {
    value: '-status_updated_at',
    label: 'Status updated at - Newest'
  },
  {
    value: 'status_updated_at',
    label: 'Status updated at - Oldest'
  },
  {
    value: '-order.received_at',
    label: 'Date received - newest'
  },
  {
    value: 'order.received_at',
    label: 'Date received - oldest'
  }
];

export const PURCHASE_REQUEST_STATUS = [
  {
    value: 'pending',
    label: '대기중',
    dot: 1,
    dotColor: '#4BBF9FF2',
    color: '#344054',
    backgroundColor: '#EAECF0'
  },
  {
    value: 'deposited',
    label: '입금 완료',
    dot: 2,
    dotColor: '#EAAA08',
    color: '#FFFFFF',
    backgroundColor: '#EAAA08'
  },
  {
    value: 'approved',
    label: '주문 완료',
    dot: 3,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9F'
  },
  {
    value: 'rejected',
    label: '반려',
    dot: 7,
    dotColor: '#F63D68',
    color: '#FFFFFF',
    backgroundColor: '#F63D68'
  }
];

export const ORDER_STATUS = [
  {
    value: 'committed',
    label: '포장 준비중',
    dot: 4,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9FF2',
    amountColor: '#EAAA08',
    values: ['committed']
  },
  {
    value: 'packed',
    label: '포장 완료',
    dot: 5,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9FF2',
    amountColor: '#EAAA08',
    values: ['packed', 'local_wh_in', 'local_departure']
  },
  {
    value: 'poe_arrival',
    label: '세관 도착',
    dot: 6,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#4BBF9FF2',
    amountColor: '#EAAA08',
    values: ['poe_arrival', 'customs_cleared']
  },
  {
    value: 'delivered',
    label: '배송 완료',
    dot: 7,
    dotColor: '#4BBF9FF2',
    color: '#FFFFFF',
    backgroundColor: '#98A2B3',
    amountColor: '#4BBF9F',
    values: [
      'delivered',
      'completed',
      'replacement_request',
      'replacement_accepted',
      'refund_request',
      'refund_accepted',
      'refunded',
      'cancellation_request',
      'cancelled'
    ]
  },
  {
    value: 'all',
    label: '전체'
    // dot: 4,
    // dotColor: '#4BBF9FF2',
    // color: '#FFFFFF',
    // backgroundColor: '#4BBF9FF2',
  }
];

export const MOBILE_TYPE_OPTIONS = [
  {
    label: 'Korean +82',
    value: 'korean',
    icon: <KoreanFlag />,
    code: '+82'
  },
  {
    label: 'France +33',
    value: 'france',
    icon: <FranceFlag />,
    code: '+33'
  }
];

export const NUMBER_KOREAN = ['첫', '두', '세', '네', '다섯', '여섯', '일곱', '여덟', '아홉', '열'];

export const DEFAULT_QUANTITY_DROPDOWN = [
  ...Array.from({ length: 20 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }))
];

export const getStatusLabel = (key, constantList) => {
  return filter(constantList, ['value', key])[0]?.label || '전체';
};

export const CURRENCIES = {
  KRW: { name: 'KRW', symbol: '₩', label: '원', scale: 0 },
  EUR: { name: 'EUR', symbol: '€', label: '유로', scale: 2 }
};

export const TRANSFER_TYPE = {
  REFUND: 'refund'
};

export const SHIPPING_COST_STATUS = {
  DEPOSIT_WAITING: 'deposit_waiting',
  DEPOSIT_COMPLETED: 'deposit_completed',
  PAYMENT_COMPLETED: 'payment_completed'
};
