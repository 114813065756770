import { Button, Layout, message, Modal, Typography, Row, Col, InputNumber, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import apiClient from '../../../services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConversionRateThunk } from '../../../redux/reducers/purchaseRequestSlice';
import './style.scss';
import { formatCurrency } from '../../../utility/utils';
import { CURRENCIES, SHIPPING_COST_STATUS } from '../../../common/utilities/constants';
import TextArea from 'antd/es/input/TextArea';
import UploadFileCustom from '../../../common/components/UploadFile';
import { fetchDepositAggs } from '../../../common/api';
const { Content } = Layout;

const ShippingCost = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [autoDeposit, setAutoDeposit] = useState(false);
  const [openShippingCostConfirmModal, setOpenShippingCostConfirmModal] = useState(false);
  const [shippingCost, setShippingCost] = useState({});
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositAvailable, setDepositAvailable] = useState(0);
  const [depositRemaining, setDepositRemaining] = useState(0);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const requestId = searchParams.get('id') || undefined;
  const { userInfo } = useSelector((state) => state.auth);
  const { conversion_rate } = useSelector((state) => state.purchaseRequests);
  const currency = CURRENCIES.KRW;
  const eurCurrency = CURRENCIES.EUR;

  useEffect(() => {
    fetchDepositAggs().then((agg) => {
      setDepositRemaining(agg?.remaining || 0);
    });
    dispatch(fetchConversionRateThunk());
  }, []);

  useEffect(() => {
    const newDeposit = shippingCost?.amount < depositRemaining ? shippingCost?.amount : depositRemaining;
    setDepositAvailable(newDeposit);
  }, [depositRemaining, shippingCost]);

  useEffect(() => {
    if (requestId) {
      fetchRequest(requestId);
    }
  }, [requestId]);

  const fetchRequest = async (id) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/purchase-request/${id}`);
      if (data) {
        setShippingCost(data?.shipping_cost || {});
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    } finally {
      setLoading(false);
    }
  };

  const onNavigate = () => {
    navigate('/purchase-request');
  };

  const handleShippingCostConfirm = async () => {
    try {
      const { data } = await apiClient.post(`/purchase-request/${requestId}/shipping-cost`, {
        status: SHIPPING_COST_STATUS.DEPOSIT_COMPLETED,
        used_deposit_amount: depositAmount,
        auto_deposit: autoDeposit
      });
      if (data) {
        message.success('Success!');
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    } finally {
      setOpenShippingCostConfirmModal(false);
      onNavigate();
    }
  };

  return (
    <>
      <HeaderFT2
        rightIems={
          <Button
            type="primary"
            style={{ backgroundColor: '#4BBF9FF2' }}
            onClick={() => {
              window.open('https://free-t.co.kr/#shipping', '_blank');
            }}>
            배송비 확인
          </Button>
        }
        title={'배송비 결제'}
        onNavigate={onNavigate}
      />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingBottom: '10px'
        }}>
        <div className="container-page mb-5" style={{ backgroundColor: '#F2F4F7', padding: '0' }}>
          <div className="mt-3 bg-white px-3 pb-3">
            <Typography.Title level={4} className="mb-3">
              배송 정보
            </Typography.Title>
            <Row gutter={[8, 16]}>
              <Col span={12}>
                <Typography.Text>무게</Typography.Text>
                <InputNumber
                  controls={false}
                  size={'large'}
                  min={0}
                  suffix={'kg'}
                  formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
                  parser={(value) => value.replace(/,/g, '')}
                  style={{ width: '100%' }}
                  value={shippingCost?.weight}
                  readOnly
                />
              </Col>
              <Col span={12}>
                <Typography.Text>배송비</Typography.Text>
                <InputNumber
                  controls={false}
                  size={'large'}
                  min={0}
                  suffix={currency?.label}
                  formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
                  parser={(value) => value.replace(/,/g, '')}
                  style={{ width: '100%' }}
                  value={shippingCost?.amount}
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="mt-3">
                <Typography.Text>메모</Typography.Text>
                <TextArea value={shippingCost?.memo} rows={4} readOnly style={{ width: '100%' }} />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="mt-3">
                <Typography.Text>이미지</Typography.Text>
                <UploadFileCustom
                  accept=".png,.jpg,.jpeg"
                  upload_hint="PNG or JPG"
                  isViewOnly={true}
                  value={shippingCost?.pictures}
                />
              </Col>
            </Row>
          </div>
          {userInfo?.role === 'free_t' ? (
            // Business Component
            <>
              <div className="py-4 px-3 bg-white mt-3">
                <Typography.Title level={5} className="mb-3">
                  현재 프리티고의 환율을 기준으로 반영됩니다
                </Typography.Title>
                <div className="mt-2" style={{ textAlign: 'right', fontWeight: '700' }}>
                  <Typography.Text style={{ color: 'red' }}>
                    1{eurCurrency?.label} = {formatCurrency(conversion_rate, 0)}
                    {currency?.label}
                  </Typography.Text>
                </div>
                <Row>
                  <Col span={24}>
                    <div
                      className="mt-3"
                      style={{
                        width: '100%',
                        padding: '3px',
                        borderRadius: '5px',
                        border: '1px solid #D0D5DD',
                        ...(autoDeposit && {
                          backgroundColor: '#F6FCFA',
                          borderColor: '#4BBF9F'
                        })
                      }}>
                      <Radio
                        disabled={depositAvailable < shippingCost?.amount}
                        className="d-flex p-2"
                        checked={autoDeposit}
                        onClick={(e) => {
                          setAutoDeposit(!autoDeposit);
                        }}>
                        <Space direction="vertical" size="small">
                          <Typography.Text level={4} style={{ fontWeight: '700' }}>
                            배송비 예치금 자동 결제
                          </Typography.Text>
                          <Typography.Text level={5} style={{ color: 'red' }}>
                            배송비가 예치금으로 자동 결제됩니다
                          </Typography.Text>
                        </Space>
                      </Radio>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="mt-3">
                    <Typography.Text>보유 예치금 사용</Typography.Text>
                    <InputNumber
                      className="input-number-w-button-after"
                      controls={false}
                      disabled={autoDeposit}
                      size={'large'}
                      min={0}
                      max={depositAvailable}
                      value={depositAmount}
                      suffix={currency?.label}
                      formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
                      parser={(value) => value.replace(/,/g, '')}
                      onChange={setDepositAmount}
                      addonAfter={
                        <Button
                          onClick={() => {
                            setDepositAmount(depositAvailable);
                          }}
                          style={{
                            fontSize: 15,
                            height: 'auto',
                            backgroundColor: '#4BBF9F',
                            borderColor: '#4BBF9F',
                            color: '#FFFFFF'
                          }}>
                          전체사용
                        </Button>
                      }
                      style={{ width: '100%' }}
                    />
                    <Row justify="end">
                      사용가능 예치금 : {formatCurrency(depositRemaining, 0)}
                      {eurCurrency?.label}
                      {'('}
                      {formatCurrency(depositRemaining * conversion_rate, 0)}
                      {currency?.label}
                      {')'}
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="py-4 px-3 bg-white mt-3">
                <Row justify="space-between" style={{ fontWeight: '500' }}>
                  <Col>
                    <Typography.Text>보유 예치금 사용</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>
                      {formatCurrency(shippingCost?.amount, 0)}
                      {currency.label}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ fontWeight: '500' }}>
                  <Col>
                    <Typography.Text>사용 예치금</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text style={{ color: 'red' }}>
                      -{formatCurrency(depositAmount, 0)}
                      {currency.label}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ fontWeight: '700' }}>
                  <Col>
                    <Typography.Text>결제할 배송비</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>
                      {formatCurrency(shippingCost?.amount - depositAmount, 0)}
                      {currency.label}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            // Customer Component
            <>
              <div className="py-4 px-3 bg-white mt-3">
                <Typography.Text style={{ fontSize: '18px', fontWeight: '700' }}>결제 수단</Typography.Text>
                <div className="mt-3">
                  <Button style={{ backgroundColor: '#4BBF9FF2', color: '#FFFFFF' }} size="large">
                    무통장 입금
                  </Button>
                </div>
                <Row justify="space-between mt-3" style={{ fontWeight: '500' }}>
                  <Col>
                    <Typography.Text style={{ fontSize: '18px' }}>은행명</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text style={{ fontWeight: '700', fontSize: '18px' }}>국민은행</Typography.Text>
                  </Col>
                </Row>
                <Row justify="space-between mt-3" style={{ fontWeight: '500' }}>
                  <Col>
                    <Typography.Text style={{ fontSize: '18px' }}>계좌번호</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Paragraph copyable style={{ fontWeight: '700', fontSize: '18px' }}>
                      80350100120333
                    </Typography.Paragraph>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ fontWeight: '500' }}>
                  <Col>
                    <Typography.Text style={{ fontSize: '18px' }}>예금주</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text style={{ fontWeight: '700', fontSize: '18px' }}>(주)오뜨비</Typography.Text>
                  </Col>
                </Row>
                <div className="mt-2">
                  <Typography.Text style={{ fontSize: '16px', color: 'red' }}>
                    위 계좌번호로 배송비를 입금해 주세요
                  </Typography.Text>
                </div>
                <Row justify="space-between mt-3" style={{ fontWeight: '500' }}>
                  <Col>
                    <Typography.Text style={{ fontSize: '18px' }}>배송비</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text style={{ fontWeight: '700', fontSize: '18px' }}>
                      {formatCurrency(shippingCost?.amount - depositAmount, 0)}
                      {currency?.label}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
        {shippingCost?.status === SHIPPING_COST_STATUS.DEPOSIT_WAITING && (
          <Button
            className="fixed-bottom-btn"
            block
            style={{ backgroundColor: '#4BBF9FF2', fontSize: '20px' }}
            type="primary"
            onClick={() => {
              if (userInfo.role === 'free_t') {
                handleShippingCostConfirm();
              } else {
                setOpenShippingCostConfirmModal(true);
              }
            }}>
            {autoDeposit
              ? '예치금 자동 결제 등록'
              : `${formatCurrency(shippingCost?.amount - depositAmount, 0)}${currency?.label} ` +
                (userInfo.role === 'free_t' ? '결제하기' : `입금 완료`)}
          </Button>
        )}
      </Content>
      <Modal
        closable={false}
        maskClosable={false}
        open={openShippingCostConfirmModal}
        footer={false}
        centered
        title={<div className="text-16 gray-900 fw-7">배송비</div>}>
        <div className="text-14 gray-700 my-4">배송비를 아래 계좌로 입금 하셨습니까?</div>
        <div>
          <Row justify="space-between mt-3" style={{ fontWeight: '500' }}>
            <Col>
              <Typography.Text style={{ fontSize: '18px' }}>은행명</Typography.Text>
            </Col>
            <Col>
              <Typography.Text style={{ fontWeight: '700', fontSize: '18px' }}>국민은행</Typography.Text>
            </Col>
          </Row>
          <Row justify="space-between mt-3" style={{ fontWeight: '500' }}>
            <Col>
              <Typography.Text style={{ fontSize: '18px' }}>계좌번호</Typography.Text>
            </Col>
            <Col>
              <Typography.Paragraph className="font-face-pv" copyable style={{ fontWeight: '700', fontSize: '18px' }}>
                80350100120333
              </Typography.Paragraph>
            </Col>
          </Row>
          <Row justify="space-between" style={{ fontWeight: '500' }}>
            <Col>
              <Typography.Text style={{ fontSize: '18px' }}>예금주</Typography.Text>
            </Col>
            <Col>
              <Typography.Text style={{ fontWeight: '700', fontSize: '18px' }}>(주)오뜨비</Typography.Text>
            </Col>
          </Row>
          <div className="mt-2">
            <Typography.Text style={{ fontSize: '16px', color: 'red' }}>
              위 계좌번호로 배송비를 입금해 주세요
            </Typography.Text>
          </div>
          <Row justify="space-between mt-3" style={{ fontWeight: '500' }}>
            <Col>
              <Typography.Text style={{ fontSize: '18px' }}>배송비</Typography.Text>
            </Col>
            <Col>
              <Typography.Text style={{ fontWeight: '700', fontSize: '18px' }}>
                {formatCurrency(shippingCost?.amount - depositAmount, 0)}
                {currency?.label}
              </Typography.Text>
            </Col>
          </Row>
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={() => setOpenShippingCostConfirmModal(false)} className="me-3" size="large">
            닫기
          </Button>
          <Button block type="primary" size="large" onClick={handleShippingCostConfirm}>
            입금했어요
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ShippingCost;
