import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { ChevronRight } from 'react-feather';
import { CURRENCIES } from '../../../common/utilities/constants';
import { fetchDepositAggs } from '../../../common/api';
import { formatCurrency } from '../../../utility/utils';

const DepositAggList = () => {
  const [depositAgg, setDepositAgg] = useState({});
  const currency = CURRENCIES[depositAgg.currency?.name];
  const aggregations = [
    {
      type: 'add', // Available Deposit
      title: '사용 가능 예치금',
      // href: '/deposit/add',
      amount: depositAgg.remaining || 0
    },
  ];

  useEffect(() => {
    fetchDepositAggs().then((agg) => setDepositAgg(agg));
  }, []);

  return (
    <List
      dataSource={aggregations}
      renderItem={(item, _) => (
        <List.Item>
          <List.Item.Meta
            title={
              <a
                className="d-flex justify-content-between"
                href={item?.href}
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}>
                {item?.title}
                <div className="d-flex">
                  <b style={{ color: 'black' }}>
                    {formatCurrency(item?.amount, currency?.scale)}
                    {currency?.label}
                  </b>
                  {item?.href && <ChevronRight />}
                </div>
              </a>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default DepositAggList;
