import { Button, Divider, Space, Typography } from 'antd';
import { HelpCircle } from 'react-feather';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import HeaderFT from '../../../common/layout/HeaderFT';
import RefundAggList from './RefundAggList';
import RefundTabs from './RefundTabs';
import ApplyTransferButton from './ApplyTransferButton';
import InformationDrawer from '../../../common/components/InformationDrawer';
const { Paragraph, Title } = Typography;

const RefundPage = () => {
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  return (
    <>
      <HeaderFT title={'환급금'} />
      <Space direction="vertical" size="middle" style={{ marginBottom: '48px', backgroundColor: '#F2F4F7' }}>
        <Content className="p-3" style={{ backgroundColor: 'white' }}>
          <RefundAggList />
        </Content>
        <Content className="p-3" style={{ backgroundColor: 'white' }}>
          <div className="d-flex justify-content-between">
            <Title level={4}>환급금 내역</Title>
            <Button type="text" shape="circle" icon={<HelpCircle />} onClick={() => setOpenInfoDrawer(true)} />
          </div>
          <Divider className="my-2" />
          <RefundTabs />
        </Content>
      </Space>
      <ApplyTransferButton withDrawer />
      <InformationDrawer
        title="환급금"
        content={
          <Paragraph>
            <Title level={5}>사용 가능 환급금</Title>
            <ul>
              <li>현재 사용할 수 있는 환급금입니다</li>
            </ul>
            <Title level={5}>적립된 환급금</Title>
            <ul>
              <li>제품이 배송된 후 적립됩니다</li>
              <li>다음 달 20일 이후 사용할 수 있습니다</li>
            </ul>
            <Title level={5}>적립 예정 환급금</Title>
            <ul>
              <li>제품 구매 후 배송 완료 전 환급금입니다</li>
            </ul>
            <Title level={5}>총 받은 환급금</Title>
            <ul>
              <li>총 받은 환급금입니다</li>
            </ul>
          </Paragraph>
        }
        open={openInfoDrawer}
        onClose={() => setOpenInfoDrawer(false)}
      />
    </>
  );
};

export default RefundPage;
