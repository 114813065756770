import { forEach } from 'lodash';
import apiClient, { apiClientV2 } from '../../../services/axios';

export const fetchPurchaseRequest = async (data, thunkApi) => {
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (key === 'status') {
      if (value !== 'all') {
        params.append(key, value);
      }
    } else if (value) {
      params.append(key, value);
    }
  });
  try {
    const response = await apiClient.get('/purchase-request', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchPurchaseRequestStatus = async (data, thunkApi) => {
  const params = new URLSearchParams();
  forEach(data, (value, key) => {
    if (key === 'status') {
      if (value !== 'all') {
        params.append(key, value);
      }
    } else if (value) {
      params.append(key, value);
    }
  });
  try {
    const response = await apiClient.get('/purchase-request/agg/status', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchConversionRate = async (thunkApi) => {
  try {
    const response = await apiClient.get('/purchase-request/conversion-rate');
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchDefaultRecipient = async (thunkApi) => {
  try {
    const response = await apiClient.get('/purchase-request/default-recipient');
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
}
