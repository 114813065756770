import { LeftOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import ActionIcon from '../components/ActionIcons';
import { ChevronLeft } from 'react-feather';
const { Header } = Layout;

const HeaderFT2 = ({ title, onNavigate, rightIems, isHideLeftIcon, titleCenter=false, isShadow=true }) => {
  const navigate = useNavigate();
  const headerStyles = {
    position: 'sticky',
    top: 0,
    zIndex: 50,
    width: '100%',
    backgroundColor: '#ffff',
    display: 'flex',
    padding: '0 15px',
    justifyContent: 'space-between',
  };
  if (isShadow) {
    headerStyles['boxShadow'] = '0px 0px 14px rgba(0, 0, 0, 0.15)';
  }
  return (
    <Header
      style={headerStyles}>
      <div className="d-flex justify-content-between w-100">
        <div className="me-1 d-flex align-items-center w-100">
          {!isHideLeftIcon ? (
            <ActionIcon
              icon={<ChevronLeft />}
              onClick={() => {
                if (onNavigate) {
                  onNavigate();
                } else {
                  navigate(-1);
                }
              }}
            />
          ) : (
            <></>
          )}
          <p className="title" style={titleCenter ? {margin: '0 auto'} : {}}>{title}</p>
        </div>
        <div className="d-flex align-items-center">
          {rightIems && <div className="d-flex align-items-center">{rightIems}</div>}
        </div>
      </div>
    </Header>
  );
};

export default HeaderFT2;
