import { Button, Form, Input, InputNumber, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import UploadFileCustom from '../../../common/components/UploadFile';
import { EditIcon } from '../../../asset/icons/Icons';
import BusinessCustomerDrawer from '../../features/common/components/BusinessCustomerDrawer';
import { useDispatch } from 'react-redux';
import { fetchUserInfoThunk } from '../../../redux/reducers/authSlice';
import { formatCurrency } from '../../../utility/utils';
const { Title } = Typography;

const BusinessInfoForm = ({ businessInfo, currency }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    form.setFieldsValue(businessInfo);
  }, [businessInfo]);

  const handleSubmit = () => {
    dispatch(fetchUserInfoThunk());
    setOpenDrawer(false);
  };

  return (
    <>
      <Form form={form} name="business_info" layout="vertical" initialValues={businessInfo}>
        <Row justify="space-between">
          <Title level={4}>사업자 정보</Title>
          <Button type="text" icon={<EditIcon />} onClick={() => setOpenDrawer(true)} />
        </Row>
        <Form.Item name="registration_number" label="사업자 등록 번호">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item name="trade_name" label="상호명">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item name="postal_code" label="우편 번호">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item
          name="address"
          label="사업장 주소"
          help={
            <Title level={5} type="danger">
              * 사업장 주소는 영문으로 입력해 주세요
            </Title>
          }>
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item name="representative_name" label="대표자 이름">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item name="pictures" label="사업자 등록증">
          <UploadFileCustom accept=".png,.jpg,.jpeg" upload_hint="PNG or JPG" feature_type="user" isViewOnly={true} />
        </Form.Item>
        <Form.Item name="product_type" label="취급 제품 종류 (예 : 의류, 주방용품 등)">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item name="estimated_monthly_amount" label="월 예상 거래 금액">
          <InputNumber
            suffix={currency?.label}
            formatter={(value) => formatCurrency(value, currency.name === 'KRW' ? 0 : 2)}
            size="large"
            disabled
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item name="email" label="기업용 이메일">
          <Input size="large" disabled />
        </Form.Item>
      </Form>
      <BusinessCustomerDrawer
        mode="profile"
        open={openDrawer}
        onSubmitted={handleSubmit}
        onClose={() => setOpenDrawer(false)}
      />
    </>
  );
};

export default BusinessInfoForm;
