// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/authSlice';
import layout from './reducers/layoutSlice';
import purchaseRequests from './reducers/purchaseRequestSlice';
import orderProducts from './reducers/orderProductSlice';

const store = configureStore({
  devTools: true,
  reducer: {
    auth,
    layout,
    purchaseRequests,
    orderProducts,
  }
});

export { store };
