import { Button, Checkbox, Col, Form, Input, InputNumber, Row, message, Typography, Space } from 'antd';
import { useEffect, useState } from 'react';
import { CURRENCIES, TRANSFER_TYPE } from '../../../common/utilities/constants';
import { createTransfer, fetchRefundAggs } from '../../../common/api';
import { formatCurrency } from '../../../utility/utils';
import BankAsyncSelect from '../../features/common/components/BankAsyncSelect';
import { useSelector } from 'react-redux';
const { Text } = Typography;

const TransferForm = ({ opened, onSubmitted, ...props }) => {
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState(CURRENCIES.KRW);
  const [available, setAvailable] = useState(0);
  const { userInfo: user } = useSelector((state) => state?.auth);

  const initialValues = {
    remember: false,
    bank_id: user?.bank_account?.bank_id,
    name: user?.bank_account?.name,
    holder_name: user?.bank_account?.holder_name,
    account_number: user?.bank_account?.account_number
  };

  const onFinish = async (values) => {
    const transferData = { ...values, type: TRANSFER_TYPE.REFUND };
    const data = await createTransfer(transferData);

    if (data === null) {
      message.error('Error!');
    } else {
      message.success('Successfully!');
      onSubmitted();
    }
  };

  const validateMessages = {
    required: 'This field is required!'
  };

  useEffect(() => {
    if (opened) {
      form.resetFields();
      fetchRefundAggs().then((agg) => {
        setCurrency(CURRENCIES[agg.currency.name]);
        setAvailable(agg.remaining);
      });
    }
  }, [opened, form]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      validateMessages={validateMessages}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      size="large"
      {...props}>
      {/* Transfer Amount */}
      <Form.Item
        label="1. 환급금 이체 신청"
        name="amount"
        rules={[{ required: true }, { type: 'number', min: 0.01, message: 'The minimum transfer value is 0.01!' }]}
        extra={
          <Row justify="end">
            이체가능 환급금 : {formatCurrency(available, currency?.scale)}
            {currency?.label}
          </Row>
        }>
        <InputNumber
          className="input-number-w-button-after"
          controls={false}
          min={0.01}
          max={available}
          suffix={currency?.label}
          formatter={(value) => value && new Intl.NumberFormat('en-EU').format(value)}
          parser={(value) => value.replace(/,/g, '')}
          addonAfter={
            <Button
              onClick={() => form.setFieldValue('amount', available)}
              style={{
                fontSize: 15,
                height: 'auto',
                backgroundColor: '#4BBF9F',
                borderColor: '#4BBF9F',
                borderRadius: '0px 8px 8px 0px'
              }}>
              전체이체
            </Button>
          }
          style={{ width: '100%' }}
        />
      </Form.Item>
      {/* Name */}
      <Form.Item name="name" label="2. 성함" rules={[{ required: true }]}>
        <Input placeholder="성함" />
      </Form.Item>
      {/* Bank & Account holder */}
      <Row gutter={16}>
        <Col span={12}>
          {/* Bank */}
          <Form.Item name="bank_id" label="3. 은행" rules={[{ required: true }]}>
            <BankAsyncSelect />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/* Account holder */}
          <Form.Item name="holder_name" label="4. 예금주" rules={[{ required: true }]}>
            <Input placeholder="예금주" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="5. 계좌 번호">
        {/* Account Number */}
        <Form.Item name="account_number" rules={[{ required: true }]} noStyle>
          <Input placeholder="‘-’ 없이 숫자만 입력해 주세요" />
        </Form.Item>
        {/* Remember checkbox */}
        <Row justify="end">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>이체 정보 저장</Checkbox>
          </Form.Item>
        </Row>
      </Form.Item>
      {/* Notices */}
      <Form.Item>
        <Space direction="vertical">
          <Text type="danger" strong>
            *환급금 이체는 배송이 완료된 시점의 다음 달 20일 이후에 신청 가능합니다. (한국 시간 기준)
          </Text>
          <Text strong>{`EX)`}</Text>
          <Text strong>{`11월 30일 배송 완료 -> 12월 20일 신청 가능`}</Text>
          <Text strong>{`11월 1일 배송 완료 -> 12월 20일 신청 가능`}</Text>
        </Space>
      </Form.Item>
      {/* Submit button */}
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default TransferForm;
