import { Col, Drawer, Row, Typography } from 'antd';
import BusinessCustomerForm from './BusinessCustomerForm';
const { Title } = Typography;

const BusinessCustomerDrawer = ({ onSubmitted, open, ...props }) => {
  return (
    <Drawer
      open={open}
      closeIcon={false}
      placement="bottom"
      contentWrapperStyle={{ overflow: 'scroll', height: '90vh' }}
      style={{ borderRadius: '12px 12px 0px 0px' }}
      {...props}>
      <Row justify="space-between">
        <Col>
          <Title level={5}>사업자 정보 입력</Title>
        </Col>
      </Row>
      <BusinessCustomerForm opened={open} onSubmitted={onSubmitted} onClose={props.onClose} mode={props.mode} />
    </Drawer>
  );
};
export default BusinessCustomerDrawer;
