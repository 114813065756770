import axios from 'axios';
import { getCookie } from '../utility/utils';
import { userService } from './authService';

const BASE_URL_V2 = `${process.env.REACT_APP_API_ENDPOINT}/api/V2`;
const BASE_URL_V1 = `${process.env.REACT_APP_API_ENDPOINT}/api/free-t`;

export const instance = axios.create({
  baseURL: BASE_URL_V2
});

export const apiClientV2 = axios.create({
  baseURL: BASE_URL_V2,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false
});

apiClientV2.interceptors.request.use(
  (config) => {
    const token = JSON.parse(getCookie('access_token'));
    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClientV2.interceptors.response.use(
  response => response,
  async error => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await userService.refreshToken();
      if (newAccessToken) {
        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      }
      return instancePrivate(prevRequest);
    }
    return Promise.reject(error);
  },
);

// apiClientV2.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.data?.['error_code'] === 'user_inactive') {
//       if (window.location.pathname !== '/thank-you') {
//         location.href = '/thank-you';
//       }
//     }
//     return Promise.reject(error);
//   }
// );

const apiClient = axios.create({
  baseURL: BASE_URL_V1,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false
});

apiClient.interceptors.request.use(
  (config) => {
    const token = JSON.parse(getCookie('access_token'));
    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  response => response,
  async error => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await userService.refreshToken();
      if (newAccessToken) {
        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      }
      return instancePrivate(prevRequest);
    }
    return Promise.reject(error);
  },
);

// apiClient.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.data?.['error_code'] === 'user_inactive') {
//       if (window.location.pathname !== '/thank-you') {
//         location.href = '/thank-you';
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export default apiClient;
