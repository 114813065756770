import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Skeleton, Typography } from 'antd';
import { fetchRefunds } from '../../../common/api';
import { CURRENCIES } from '../../../common/utilities/constants';
import RefundListItem from './RefundListItem';
const { Text } = Typography;

const RefundList = ({ type = '', maxHeight = 500, ...props }) => {
  const id = ['refund-list', Math.random().toString(36).substring(2)].join('-');
  const size = 10;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refunds, setRefunds] = useState([]);
  const [currency, setCurrency] = useState(CURRENCIES.KRW);

  const loadMoreData = () => {
    if (loading) return;
    setLoading(true);
    fetchRefunds({ page: page + 1, size, type })
      .then((res) => {
        const resources = [...refunds, ...res.resources];
        setPage(page + 1);
        setHasMore(resources.length < res.total_resources);
        setLoading(false);
        setRefunds(resources);
        setCurrency(CURRENCIES[res.currency.name]);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <div id={id} style={{ maxHeight: maxHeight, overflow: 'auto' }} {...props}>
      <InfiniteScroll
        dataLength={refunds.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={<Skeleton />}
        scrollableTarget={id}>
        <List dataSource={refunds} renderItem={(refund) => <RefundListItem refund={refund} currency={currency} />} />
      </InfiniteScroll>
    </div>
  );
};

export default RefundList;
