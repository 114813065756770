import { Button, Drawer, Input, Pagination, Table, Grid, Row, Col, Select, Space, DatePicker, Image } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  changeOrderProductPage,
  changeOrderProductSize,
  fetchOrderProductListThunk,
  fetchOrderProductListStatusThunk
} from '../../../redux/reducers/orderProductSlice';
import './style.scss';
import { ORDER_PRODUCT_STATUS, ORDER_SORT_BY_DEFAULT } from '../../../common/utilities/constants';
import dayjs from 'dayjs';
import { formatCurrency } from '../../../utility/utils';
import { ChevronDown, Search } from 'react-feather';
import { ResetIcons } from '../../../asset/icons/Icons';
import { fetchTotalRefund } from '../../features/orderProduct/api';
import ClickPreviewDownloadImages from '../../features/orderProduct/components/ClickPreviewDownloadImages';

const { useBreakpoint } = Grid;

const columns = [
  {
    title: (
      <div className="d-flex flex-column">
        <span>Order ID</span>
        <span>
          <strong>/ Order-Product ID</strong>
        </span>
      </div>
    ),
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    className: 'bg-gray',
    render: (_, record) => (
      <div className="d-flex flex-column">
        <span>{record.order_id}</span>
        <span>
          <strong>{record.id}</strong>
        </span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>Order REF</span>
        <span>
          <strong>/ Order-Product REF</strong>
        </span>
      </div>
    ),
    dataIndex: 'number',
    key: 'number',
    align: 'center',
    render: (_, record) => (
      <div className="d-flex flex-column">
        <span>{record.order?.order_number}</span>
        <span>
          <strong>{record?.order_product_number}</strong>
        </span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>상태</span>
      </div>
    ),
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    className: 'bg-gray',
    render: (text) => (
      <div className="d-flex flex-column">
        <span>{ORDER_PRODUCT_STATUS.filter((i) => i.value === text)?.[0]?.label || text}</span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>승인 날짜</span>
        <span>/ 최근 업데이트</span>
      </div>
    ),
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    render: (_, record) => (
      <div className="d-flex flex-column">
        <span>{dayjs(record.order?.received_at).format('YYYY.MM.DD')}</span>
        <span>/{dayjs(record.status_updated_at).format('YYYY.MM.DD')}</span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>브랜드</span>
      </div>
    ),
    dataIndex: 'brand',
    key: 'brand',
    align: 'center',
    render: (_, record) => (
      <div className="d-flex flex-column">
        <span>{record.brand?.name_en}</span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>환급 예정 금액</span>
      </div>
    ),
    dataIndex: 'refund_price',
    key: 'refund_price',
    align: 'center',
    render: (text, record) => (
      <div className="d-flex flex-column">
        <span>
          {record.refund_currency?.symbol}
          {formatCurrency(text, record.refund_currency?.name === 'EUR' ? 2 : 0)}
        </span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>제품명</span>
      </div>
    ),
    dataIndex: 'product',
    key: 'product',
    align: 'center',
    render: (_, record) => (
      <div className="d-flex flex-column">
        <span>{record.product?.name_en?.trim() || record.product?.name_ko}</span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>Product ID</span>
        <span>/ 옵션</span>
      </div>
    ),
    dataIndex: 'product_variant',
    key: 'product_variant',
    align: 'center',
    render: (_, row) => {
      const variant_id = row.product_variant_id;
      const cafe24_options = row.cafe24_options;
      let details = row.product_variant?.properties?.map((p) => p.value)?.join(', ');
      if (cafe24_options?.length > 0 && cafe24_options[1] === 'false') {
        let draftOpt = '';
        cafe24_options.map((o, idx) => {
          if (idx > 1) {
            const optArr = o.split(':');
            if (optArr[0] !== 'customs') {
              draftOpt += `${optArr[1]}, `;
            }
          }
        });
        details = <b style={{ color: '#fa5252' }}>{draftOpt.replace(/, $/, '')}</b>;
      }
      return (
        <div className="d-flex flex-column">
          <span>{row.product_id || 'N/A'}</span>
          <span>
            {`-${variant_id} (`} {details} {`)`}
          </span>
        </div>
      );
    }
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>개수</span>
      </div>
    ),
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
    render: (text) => (
      <div className="d-flex flex-column">
        <span>{text}</span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>금액</span>
      </div>
    ),
    dataIndex: 'sale_price',
    key: 'sale_price',
    align: 'center',
    render: (text) => (
      <div className="d-flex flex-column">
        <span>€{formatCurrency(text?.toFixed(2))}</span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>Delivery method</span>
        <span>/ Tracking Number</span>
      </div>
    ),
    dataIndex: 'shipment',
    key: 'shipment',
    align: 'center',
    render: (_, record) => (
      <div className="d-flex flex-column">
        <span>{record.shipment?.method || ''}</span>
        <span>{record.shipment?.tracking_number || ''}</span>
      </div>
    )
  },
  {
    title: (
      <div className="d-flex flex-column">
        <span>첨부 파일</span>
      </div>
    ),
    dataIndex: 'download_images',
    key: 'download_images',
    align: 'center',
    render: (_, record) => {
      return record?.download_images?.length > 0 ? (
        <ClickPreviewDownloadImages id={record.id} images={record?.download_images} />
      ) : (
        <></>
      );
    }
  }
];

const OrderProductPage = () => {
  const screens = useBreakpoint();
  const currentScreens = Object.entries(screens).filter((screen) => !!screen[1]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get('order_id') || undefined;
  const [openWarning, setOpenWarning] = useState(order_id === undefined);
  const [keywordType, setKeywordType] = useState(order_id ? 'order_id' : 'all');
  const [keyword, setKeyword] = useState(order_id);
  const [status, setStatus] = useState('all');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [totalRefund, setTotalRefund] = useState(0);
  const [sort, setSort] = useState('-status_updated_at');
  const [selectedRows, setSelectedRows] = useState([]);
  const dateFormat = 'YYYY.MM.DD';
  const {
    orderProductLoading,
    orderProductTotalPage,
    orderProductTotalResources,
    orderProductPage,
    orderProductSize,
    orderProductResources,
    orderProductStatusOptions
  } = useSelector((state) => state.orderProducts);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    }
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  useEffect(() => {
    if (order_id) {
      fetchOrderProductListThunk({
        page: 1,
        size: orderProductSize,
        keyword: order_id,
        keyword_type: 'order_id'
      });
    }
  }, [order_id]);

  const request = async () => {
    const data = await fetchTotalRefund();
    setTotalRefund(data?.total || 0);
  };

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (keyword && keyword?.length < 3) {
      return;
    }
    dispatch(
      fetchOrderProductListThunk({
        page: orderProductPage,
        size: orderProductSize,
        keyword,
        keyword_type: keywordType,
        sort,
        status,
        start_date: startDate,
        end_date: endDate
      })
    );
    dispatch(
      fetchOrderProductListStatusThunk({
        keyword,
        keyword_type: keywordType,
        sort,
        status,
        start_date: startDate,
        end_date: endDate
      })
    );
  }, [orderProductPage, orderProductSize, sort, status]);

  const resetFilter = () => {
    setEndDate(undefined);
    setStartDate(undefined);
    setKeyword(undefined);
    setKeywordType('all');
    dispatch(
      fetchOrderProductListThunk({
        page: 1,
        size: orderProductSize
      })
    );
  };

  const submitFilter = () => {
    dispatch(changeOrderProductPage(1));
    dispatch(
      fetchOrderProductListThunk({
        page: 1,
        size: orderProductSize,
        keyword,
        keyword_type: keywordType,
        sort,
        status,
        start_date: startDate,
        end_date: endDate
      })
    );
    dispatch(
      fetchOrderProductListStatusThunk({
        keyword,
        keyword_type: keywordType,
        sort,
        status,
        start_date: startDate,
        end_date: endDate
      })
    );
  };

  const handleChangeOrderProductPagination = (current) => {
    dispatch(changeOrderProductPage(current));
  };

  const handleChangeOrderProductSize = (current, size) => {
    dispatch(changeOrderProductPage(1));
    dispatch(changeOrderProductSize(size));
  };

  const handleChangeSort = (value) => {
    dispatch(changeOrderProductPage(1));
    setSort(value);
  };

  const handleChangeFilterSelect = (value) => {
    setKeywordType(value);
    if (currentScreens?.[0]?.[0] === 'xs') {
      submitFilter();
    }
  };

  const handleChangeFilterSearch = (value) => {
    setKeyword(value);
    if (currentScreens?.[0]?.[0] === 'xs') {
      submitFilter();
    }
  };

  const handleChangeStatus = (value) => {
    dispatch(changeOrderProductPage(1));
    setStatus(value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
  };

  const handleChangeEndDate = (value) => {
    setEndDate(value);
  };

  const filterOpts = [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'id',
      label: 'Order Product ID'
    },
    {
      value: 'order_product_number',
      label: 'Order Product REF'
    },
    {
      value: 'order_id',
      label: 'Order ID'
    },
    {
      value: 'order_number',
      label: 'Order REF'
    },
    {
      value: 'tracking_number',
      label: 'Tracking Number'
    },
    {
      value: 'product_id',
      label: 'Product ID'
    },
    {
      value: 'brand',
      label: 'Brand'
    }
  ];

  return (
    <>
      <HeaderFT2 title={'Order Products'} />
      <Content className="py-3 px-2">
        <div>
          {currentScreens?.[0]?.[0] === 'xs' ? (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Select
                    value={keywordType}
                    onChange={handleChangeFilterSelect}
                    style={{
                      width: '100%',
                      marginTop: '24px',
                      marginBottom: '22px',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    suffixIcon={<ChevronDown />}
                    bordered={false}
                    size="large"
                    options={filterOpts}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    placeholder="Search"
                    value={keyword}
                    onChange={(e) => {
                      handleChangeFilterSearch(e.target.value);
                    }}
                    style={{
                      width: '100%',
                      marginTop: '24px',
                      marginBottom: '22px',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    size="large"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col span={4}>
                  <Space align="center" style={{ width: '100%', height: '100%' }}>
                    상태
                  </Space>
                </Col>
                <Col span={20}>
                  <Select
                    value={status}
                    onChange={handleChangeStatus}
                    style={{
                      width: '100%',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    suffixIcon={<ChevronDown />}
                    bordered={false}
                    size="large"
                    options={orderProductStatusOptions}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Space
                direction="vertical"
                className="mb-3"
                style={{
                  width: '100%'
                }}>
                <Space align="center" size={'middle'}>
                  <Select
                    value={keywordType}
                    onChange={handleChangeFilterSelect}
                    style={{
                      width: '200px',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    suffixIcon={<ChevronDown />}
                    bordered={false}
                    size="large"
                    options={filterOpts}
                  />
                  <Input
                    placeholder="Search"
                    value={keyword}
                    onChange={(e) => {
                      handleChangeFilterSearch(e.target.value);
                    }}
                    style={{
                      width: '100%',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    size="large"
                  />
                  <DatePicker
                    value={startDate}
                    style={{
                      width: '100%',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    placeholder="Start Date"
                    size="large"
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      handleChangeStartDate(date);
                    }}
                  />
                  <div>~</div>
                  <DatePicker
                    value={endDate}
                    style={{
                      width: '100%',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    placeholder="End Date"
                    size="large"
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      handleChangeEndDate(date);
                    }}
                  />
                  <Button
                    onClick={() => submitFilter()}
                    size="large"
                    type="primary"
                    style={{
                      width: '130px'
                    }}>
                    <Space align="center" size={'middle'} style={{ width: '100%', height: '100%' }}>
                      <Search /> Search
                    </Space>
                  </Button>
                  <Button
                    onClick={() => resetFilter()}
                    size="large"
                    type="default"
                    style={{
                      width: '110px'
                    }}>
                    <Space align="center" size={'middle'} style={{ width: '100%', height: '100%' }}>
                      <ResetIcons /> Reset
                    </Space>
                  </Button>
                </Space>
                <Row>
                  <Col span={18}>
                    <Space align="center" size={'large'} className="mt-2">
                      상태
                      <Select
                        value={status}
                        onChange={handleChangeStatus}
                        style={{
                          width: '300px',
                          border: '1px solid #D0D5DD',
                          borderRadius: '5px'
                        }}
                        suffixIcon={<ChevronDown />}
                        bordered={false}
                        size="large"
                        options={orderProductStatusOptions}
                      />
                      <span>총 환급 예정 금액</span>
                      <span>
                        <strong style={{ color: '#4BBF9F' }}>{formatCurrency(totalRefund, 0)}원</strong>{' '}
                        <span style={{ color: '#F63D68' }}>* 배송 완료된 후 적립됩니다</span>
                      </span>
                    </Space>
                  </Col>
                  <Col span={6} className="d-flex justify-content-end">
                    <Space align="center" size={'large'} className="mt-2">
                      Sort by
                      <Select
                        value={sort}
                        onChange={handleChangeSort}
                        style={{
                          width: '300px',
                          border: '1px solid #D0D5DD',
                          borderRadius: '5px'
                        }}
                        suffixIcon={<ChevronDown />}
                        bordered={false}
                        size="large"
                        options={ORDER_SORT_BY_DEFAULT}
                      />
                    </Space>
                  </Col>
                </Row>
              </Space>
            </>
          )}
        </div>
        <div>
          <Table
            className="order-table"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            loading={orderProductLoading}
            columns={columns}
            dataSource={orderProductResources?.map((row, idx) => {
              return { ...row, key: idx + 1 };
            })}
            scroll={{
              x: 1500
            }}
            // bordered
            borderRadius={5}
            pagination={{ position: ['none'] }}
            summary={() => <Table.Summary fixed={'top'}></Table.Summary>}
            sticky={{
              offsetHeader: 64
            }}
          />
          <div className="d-flex justify-content-center mt-3">
            <Pagination
              defaultCurrent={orderProductPage}
              pageSize={orderProductSize}
              total={orderProductTotalResources}
              onChange={handleChangeOrderProductPagination}
              showSizeChanger
              onShowSizeChange={handleChangeOrderProductSize}
            />
          </div>
        </div>
      </Content>
      <Drawer
        title={false}
        placement={'bottom'}
        closable={false}
        onClose={() => setOpenWarning(false)}
        open={openWarning}
        rootClassName="optiosDrawer"
        contentWrapperStyle={{
          height: '260px',
          // maxHeight: '420px',
          overflow: 'scroll',
          borderRadius: '20px 20px 0px 0px'
        }}>
        <div>
          <p>해당 페이지는 데스크탑(PC)에 최적화되어있습니다.</p>
          <p>현재 모바일 페이지를 작업중이니 조금만 기다려 주세요.</p>
          <p>감사합니다.</p>
          <Button type="default" block size="large" className="mt-4" onClick={() => setOpenWarning(false)}>
            닫기
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default OrderProductPage;
