export const DEPOSIT_TYPE = {
  add: {
    value: 'add',
    display: '적립',
    description: '예치금 적립',
    color: '#4BBF9F',
    sign: '+'
  },
  request: {
    value: 'request',
    display: '적립 예정',
    description: '적립 예정',
    color: '#EAAA08',
    sign: ''
  },
  return: {
    value: 'return',
    display: '',
    description: '구매 취소',
    color: '#4BBF9F',
    sign: '+'
  },
  used: {
    value: 'used',
    description: '제품 구매',
    color: '#F63D68',
    sign: '-',
    display: '사용',
  },
  deduct: {
    value: 'deduct',
    description: '공제',
    color: '#F63D68',
    sign: '-',
    display: '공제',
  },
};
