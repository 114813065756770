import { Button, Col, Drawer, Input, Modal, Pagination, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Search, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronDown } from '../../../asset/icons/Icons';
import ActionIcon from '../../../common/components/ActionIcons';
import HeaderFT from '../../../common/layout/HeaderFT';
import { closeSideBar, openBusinessOnboarding } from '../../../redux/reducers/layoutSlice';
import {
  changePage,
  fetchPurchaseRequestThunk,
  openSearchDrawer,
  closeSearchDrawer
} from '../../../redux/reducers/purchaseRequestSlice';
import { fetchUserInfoThunk, logoutThunk } from '../../../redux/reducers/authSlice';
import ProductCard from '../../features/purchased/components/ProductCard';
import SearchFilter from './SearchFilter';
import './style.scss';
import BusinessCustomerDrawer from '../../features/common/components/BusinessCustomerDrawer';

const PurchaseRequestPage = () => {
  const [selected, setSelected] = useState('all');
  const [isUserFetch, setIsUserFetch] = useState(false);
  const [openBusinessCUstomerDrawer, setOpenBusinessCUstomerDrawer] = useState(false);
  const [openRejectReasonModal, setOpenRejectReasonModal] = useState(false);
  const [openBusinessModal, setOpenBusinessModal] = useState(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectId, setRejectId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key') || 'all';
  const { totalResources, page, size, resources, loading, statusOptions, isOpenSearchDrawer, filters } = useSelector(
    (state) => state.purchaseRequests
  );
  const orderProductState = useSelector((state) => state.orderProducts);
  const STATUS_OPTIONS = [
    statusOptions.find((s) => s.value === 'all'),
    ...statusOptions.filter((s) => s.value !== 'all'),
    ...orderProductState.statusOptions.filter((s) => s.value !== 'all')
  ].filter(Boolean);
  const handleClickLogout = () => {
    dispatch(logoutThunk());
  };

  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    if (userInfo.role === 'free_t' && !userInfo.active) {
      setOpenBusinessCUstomerDrawer(true);
      return;
    }
    if (isUserFetch && userInfo && userInfo?.registered === false) {
      dispatch(closeSideBar());
      if (userInfo.role === 'free_t') {
        dispatch(openBusinessOnboarding());
      } else {
        navigate('/onboarding');
      }
    }
  }, [userInfo, dispatch]);
  const [searchData, setSearchData] = useState({});
  useEffect(() => {
    dispatch(fetchUserInfoThunk());
    setSelected(key);
    setIsUserFetch(true);
  }, []);

  useEffect(() => {
    if (userInfo.active && selected === key) {
      dispatch(fetchPurchaseRequestThunk({ ...filters, status: selected, page, size }));
    }
  }, [userInfo, page, selected]);

  // TODO: Create filter params with OrderProduct status
  const handleChangeSelect = (value) => {
    setSelected(value);
    if (value) {
      navigate({
        search: createSearchParams({
          key: value
        }).toString()
      });
    } else {
      navigate({
        search: createSearchParams({}).toString()
      });
    }
  };

  const handleOnclickSubmitNewPurchaseRequest = () => {
    navigate('/create-purchase-request');
  };

  const handleChangePagination = (current) => {
    dispatch(changePage(current));
  };

  const handleOpenRejectModal = (id, reason) => {
    setOpenRejectReasonModal(true);
    setRejectId(id);
    setRejectReason(reason);
  };

  const handleCloseRejectModal = () => {
    setOpenRejectReasonModal(false);
    setRejectId(null);
    setRejectReason(null);
  };

  return (
    <>
      <HeaderFT
        title={'주문 현황'}
        rightIems={
          <>
            <ActionIcon
              style={{ padding: '0px', marginRight: '10px' }}
              icon={<Search size="24px" />}
              onClick={() => dispatch(openSearchDrawer())}
            />
          </>
        }
      />
      <Content
        style={{
          paddingBottom: '50px'
        }}>
        <div className="container-page">
          <Select
            value={selected}
            onChange={handleChangeSelect}
            style={{
              width: '100%',
              marginTop: '24px',
              marginBottom: '22px',
              border: '1px solid #D0D5DD',
              borderRadius: '5px'
            }}
            suffixIcon={<ChevronDown />}
            bordered={false}
            size="large"
            options={STATUS_OPTIONS}
          />
          <Spin tip="Loading" spinning={loading}>
            {resources?.length > 0 ? (
              <>
                <Row>
                  {resources?.map((item, idx) => {
                    return (
                      <Col key={idx} xs={{ span: 24 }} lg={{ span: 24 }} className="mb-3">
                        <ProductCard data={item} key={idx} handleOpenRejectModal={handleOpenRejectModal} />
                      </Col>
                    );
                  })}
                </Row>
                <div className="d-flex justify-content-center mt-1">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={size}
                    total={totalResources}
                    onChange={handleChangePagination}
                  />
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center flex-column justify-content-center"
                style={{ marginTop: '10vh', alignItems: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/empty.png'} style={{ width: '183px' }} />
                <p style={{ textAlign: 'center', fontWeight: '700' }}>신청 내역이 없어요</p>
                <Button
                  className="mt-3"
                  type="primary"
                  style={{ backgroundColor: '#4BBF9FF2', width: '250px', height: '40px' }}
                  onClick={() => {
                    navigate('/onboarding');
                  }}>
                  이용 방법 다시 확인하기
                </Button>
              </div>
            )}
          </Spin>
        </div>

        <Button className="submit-new-btn" type="primary" block onClick={handleOnclickSubmitNewPurchaseRequest}>
          신청서 작성
        </Button>
        <SearchFilter
          open={isOpenSearchDrawer}
          onSaveSearchData={setSearchData}
          searchData={searchData}
          onClose={() => dispatch(closeSearchDrawer())}
        />
      </Content>
      <Drawer
        title={false}
        placement={'bottom'}
        closable={false}
        onClose={() => handleCloseRejectModal()}
        open={openRejectReasonModal}
        rootClassName="optiosDrawer"
        contentWrapperStyle={{
          height: '250px',
          // maxHeight: '420px',
          overflow: 'scroll'
        }}>
        <div className="d-flex justify-content-between">
          <p
            style={{
              fontSize: '16px',
              color: '#101828',
              fontWeight: '700'
            }}>
            반려 사유
          </p>
          <X style={{ marginBottom: '20px', fontSize: '18px' }} onClick={() => handleCloseRejectModal()} />
        </div>
        <div>
          <Input.TextArea rows={3} value={rejectReason} readOnly />
          <Button
            type="primary"
            block
            size="large"
            className="mt-4"
            onClick={() => navigate(`/purchase-request/edit?id=${rejectId}`)}>
            제품 수정
          </Button>
        </div>
      </Drawer>
      <BusinessCustomerDrawer
        open={openBusinessCUstomerDrawer}
        onSubmitted={() => {
          setOpenBusinessCUstomerDrawer(false);
          setOpenBusinessModal(true);
        }}
      />
      <Modal
        closable={false}
        maskClosable={false}
        open={openBusinessModal}
        footer={false}
        centered
        title={<div className="text-16 gray-900 fw-7">사업자 승인 대기중</div>}>
        <div className="text-14 gray-700 ">승인까지 영업일 기준 1일 소요됩니다.</div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={handleClickLogout} className="me-3" size="large">
            로그아웃
          </Button>
          <Button
            block
            type="primary"
            loading={loading}
            size="large"
            onClick={() => {
              window.open('https://vdx9l.channel.io', '_blank');
            }}>
            문의
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PurchaseRequestPage;
