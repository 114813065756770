import { apiClientV2 } from '../../services/axios';

export const createBusiness = async (data) => {
  try {
    const response = await apiClientV2.post('/business-account', data);
    if (response.status === 201) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error creating business:', error);
    return null;
  }
};

export const updateBusiness = async (id, data) => {
  try {
    const response = await apiClientV2.put(`/business-account/${id}`, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error updating business:', error);
    return null;
  }
};
