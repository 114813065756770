import { Button, Form, Input, message, Space, Spin, Select, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfoThunk } from '../../../redux/reducers/authSlice';
import { EditIcon } from '../../../asset/icons/Icons';
import { getPhoneNumber } from '../../../utility/utils';
import { MOBILE_TYPE_OPTIONS } from '../../../common/utilities/constants';

const ProfileForm = ({ userInfo }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isUpdatePhoneNumber, setIsUpdatePhoneNumber] = useState(false);
  const [isCountDown, setIsCountDown] = useState(false);
  const [countDownTime, setCountDownTime] = useState(null);
  const [certified, setCertified] = useState(false);
  const [otpCertied, setOtpCertied] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { getInfoLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    const business = userInfo?.business;
    form.setFieldsValue({
      full_name: userInfo?.full_name || '',
      country: userInfo?.country || '',
      areaCode: userInfo?.country || 'korean',
      phone: userInfo?.phone || '',
      username: userInfo?.username || '',
      role: userInfo?.role == 'free_t_customer' ? '일반 고객' : '사업자',
      memo: userInfo?.note || '',
      business_pictures: business?.pictures || [],
      registration_number: business?.registration_number || '',
      representative_name: business?.representative_name || ''
    });
  }, [userInfo]);

  const updateProfile = async (body) => {
    setLoadingUpdate(true);
    try {
      dispatch(fetchUserInfoThunk());
      setIsUpdatePhoneNumber(false);
      setLoadingUpdate(false);
      setIsCountDown(false);
      setCountDownTime(null);
      message.success('Update Profile successfully');
      form.setFieldsValue({ otp: undefined });
    } catch (error) {
      setLoadingUpdate(false);
      message.error(error.response.data?.message || 'Update Profile Failed');
    }
  };

  const authentication = async (body) => {
    setLoadingAuth(true);
    try {
      setCertified(true);
      setLoadingAuth(false);
      setIsCountDown(true);
      setCountDownTime(Date.now() + 180 * 1000);
    } catch (error) {
      setLoadingAuth(false);
      message.error(error.response.data?.message || 'Certify Failed');
    }
  };

  const handleCertified = () => {
    const { phone, areaCode } = form.getFieldsValue();
    const body = {
      phone: getPhoneNumber(areaCode, phone)
    };
    authentication(body);
  };

  const handleFinish = async (values) => {
    const { full_name, country, phone, otp, areaCode, memo } = values;
    const body = {
      full_name,
      country,
      memo,
      phone: getPhoneNumber(areaCode, phone),
      otp
    };
    updateProfile(body);
  };

  const onClickChangePhoneNumber = () => {
    form.setFieldsValue({
      phone: !isUpdatePhoneNumber ? undefined : userInfo?.phone
    });
    setIsUpdatePhoneNumber(!isUpdatePhoneNumber);
  };

  const phoneStyle = !isUpdatePhoneNumber ? { display: 'none' } : {};

  return (
    <Form
      form={form}
      name="profile"
      layout="vertical"
      initialValues={{
        full_name: userInfo?.full_name || '',
        username: userInfo?.username || '',
        country: userInfo?.country || '',
        areaCode: userInfo?.country || 'korean',
        role: userInfo?.role == 'free_t_customer' ? '일반 고객' : '사업자',
        phone: userInfo?.phone || '',
        memo: ''
      }}
      onFinish={handleFinish}>
      <Spin tip="Loading" spinning={getInfoLoading}>
        <Form.Item name="full_name" label={<b className="gray-700">이름</b>}>
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item name="username" label={<b className="gray-700">이메일</b>}>
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item name="role" label={<b className="gray-700">유형</b>}>
          <Input size="large" disabled />
        </Form.Item>
        <label className="w-100">
          <p className="d-flex justify-content-between w-100" onClick={onClickChangePhoneNumber}>
            <b className="gray-700">전화 번호</b>
            <EditIcon />
          </p>
        </label>
        <Form.Item name="areaCode" style={phoneStyle}>
          <Select disabled={!isUpdatePhoneNumber} size="large">
            {MOBILE_TYPE_OPTIONS.map((opt, idx) => (
              <Select.Option key={idx} value={opt.value} label={opt.label}>
                <Space>
                  {/* {opt.icon} */}
                  {opt.label}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Space.Compact style={{ width: '100%' }} size="large">
          <Form.Item name="phone" className="w-100">
            <Input size="large" disabled={!isUpdatePhoneNumber} placeholder="전화 번호" className="w-100" />
          </Form.Item>
          {isUpdatePhoneNumber && (
            <Button loading={loadingAuth} size="large" type="primary" className="green-btn" onClick={handleCertified}>
              {isCountDown ? 'Resend' : 'Certified'}
            </Button>
          )}
        </Space.Compact>
        {isUpdatePhoneNumber && (
          <Form.Item name="otp">
            <Input
              size="large"
              placeholder="Enter certified number"
              suffix={
                isCountDown && (
                  <Statistic.Countdown
                    valueStyle={{
                      fontSize: '16px',
                      color: '#4BBF9F'
                    }}
                    format="mm:ss"
                    value={countDownTime}
                    onFinish={() => setCertified(false)}
                  />
                )
              }
            />
          </Form.Item>
        )}
        {!isUpdatePhoneNumber ? (
          <></>
        ) : (
          <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.otp !== curValues.otp}>
            {({ getFieldValue }) => {
              if (getFieldValue('otp')?.length > 5 && getFieldValue('otp')?.length < 9) {
                setOtpCertied(true);
              } else {
                setOtpCertied(false);
              }
              return (
                <Button
                  type="primary"
                  block
                  size="large"
                  className="green-btn mb-2"
                  // onClick={handleAuthentication}
                  disabled={!(certified && getFieldValue('otp')?.length > 5 && getFieldValue('otp')?.length < 9)}>
                  Authentication completed
                </Button>
              );
            }}
          </Form.Item>
        )}
        <Form.Item name="memo" label={<b className="gray-700">메모</b>}>
          <Input.TextArea rows={4} maxLength={6} disabled placeholder="Take a Note" />
        </Form.Item>
      </Spin>
      <Button
        htmlType="submit"
        loading={loadingUpdate}
        hidden={!isUpdatePhoneNumber}
        className="submit-new-btn"
        block
        type="primary"
        disabled={!(certified && otpCertied)}>
        Save
      </Button>
    </Form>
  );
};

export default ProfileForm;
