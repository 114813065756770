export const REFUND_TYPE = {
  add: {
    value: 'add',
    display: '환급',
    title: '사용 가능 환급금',
    description: '제품 구매 (배송 완료)',
    color: '#4BBF9F',
    sign: '+'
  },
  accumulated: {
    value: 'accumulated',
    display: '환급',
    title: '적립된 환급금',
    description: '제품 구매 (배송 완료)',
    color: '#4BBF9F',
    sign: '+'
  },
  expected: {
    value: 'expected',
    display: '환급 예정',
    title: '적립 예정 환급금',
    description: '제품 구매 (환급 예정)',
    color: '#EAAA08',
    sign: ''
  },
  deduct: {
    value: 'deduct',
    display: '이체 완료',
    description: '계좌 이체',
    color: '#F63D68',
    sign: '-'
  },
  used: {
    value: 'used',
    display: '사용 완료',
    description: '제품 구매',
    color: '#F63D68',
    sign: '-'
  },
  transfer_scheduled: {
    value: 'transfer_scheduled',
    display: '이체 예정',
    description: '이체 예정',
    color: '#EAAA08',
    sign: ''
  }
};
