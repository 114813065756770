import { Divider, Row, Space, Typography } from 'antd';
import { HelpCircle } from 'react-feather';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import RefundList from './RefundList';
import { REFUND_TYPE } from './constants';
import { CURRENCIES } from '../../../common/utilities/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from '../../../utility/utils';
import { fetchRefundAggs } from '../../../common/api';
import ApplyTransferButton from './ApplyTransferButton';
const { Text, Title } = Typography;

const RefundListPage = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [refundAgg, setRefungAgg] = useState({});
  const [total, setTotal] = useState({});

  const refundType = REFUND_TYPE[type];
  const currency = CURRENCIES[refundAgg.currency?.name];

  useEffect(() => {
    if (!refundType) navigate('/refund');
  }, [refundType]);

  useEffect(() => {
    fetchRefundAggs().then((agg) => {
      setRefungAgg(agg);
      switch (type) {
        case 'add':
          setTotal(agg.remaining);
          break;
        default:
          setTotal(agg[type]);
          break;
      }
    });
  }, []);

  return (
    <>
      <HeaderFT2 title={refundType?.title} onNavigate={() => navigate('/refund')} />
      <Space direction="vertical" style={{ backgroundColor: '#F2F4F7', bottom: '48px' }}>
        <Content className="p-3 " style={{ backgroundColor: 'white' }}>
          <Row justify="space-between">
            <Title level={4}>{refundType?.title}</Title>
            <HelpCircle />
          </Row>
          <Divider style={{ margin: '16px 0' }} />
          <Row justify="end">
            <Text strong>
              {formatCurrency(total, currency?.scale)}
              {currency?.label}
            </Text>
          </Row>
        </Content>
        <Content className="p-3" style={{ backgroundColor: 'white' }}>
          <RefundList type={refundType?.value} maxHeight={650} />
        </Content>
      </Space>
      {type === REFUND_TYPE.add.value && <ApplyTransferButton withDrawer />}
    </>
  );
};

export default RefundListPage;
