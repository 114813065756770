import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerCustom from '../../../common/components/DatePicker';
import {
  changePage,
  changeFilters,
  closeSearchDrawer,
  fetchPurchaseRequestThunk,
  fetchPurchaseRequestStatusThunk
} from '../../../redux/reducers/purchaseRequestSlice';

const SearchFilter = ({ onClose, open, onSaveSearchData, searchData = {} }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { filters } = useSelector(
    (state) => state.purchaseRequests
  );

  const initialValues = {
    ...filters, keyword_type: "all"
  };

  const handleSearch = (values) => {
    dispatch(changeFilters(values));
    dispatch(fetchPurchaseRequestThunk({ ...values, size: searchData.size, page: 1 }));
    dispatch(fetchPurchaseRequestStatusThunk({ ...values }));
    dispatch(closeSearchDrawer());
    dispatch(changePage(1));
  };

  const handleClose = () => {
    onClose();
    onSaveSearchData(form.getFieldsValue());
  };

  const handleReset = async () => {
    await dispatch(changeFilters({}));
    form.resetFields();
    // form.submit();
  }

  return (
    <Drawer
      title={false}
      placement={'top'}
      closable={false}
      onClose={handleClose}
      open={open}
      key={'search'}
      rootClassName="search-drawer"
      contentWrapperStyle={{
        height: '200px',
        overflow: 'scroll'
      }}
      maskClosable={false}>
      <Form name="filter-search" form={form} onFinish={handleSearch} initialValues={initialValues}>
        <Row gutter={[8]}>
          <Col span={8}>
            <Form.Item name="keyword_type">
              <Select
                style={{
                  width: '100%',
                  border: '1px solid #D0D5DD',
                  height: '40px',
                  borderRadius: '5px'
                }}
                bordered={false}
                size="large"
                options={[
                  {"value": 'all', 'label': '전체'},
                  {"value": 'product_name', 'label': '제품명'},
                  {"value": 'recipient', 'label': '수취인'},
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="keyword">
              <Input suffix={<SearchOutlined />} placeholder="Search" size="large" className="w-100" style={{height: '40px'}} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item name="start_date">
              <DatePickerCustom className="w-100" format={'YYYY.MM.DD'} />
            </Form.Item>
          </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            ~
          </Col>
          <Col span={11}>
            <Form.Item name="end_date">
              <DatePickerCustom className="w-100" format={'YYYY.MM.DD'} />
            </Form.Item>
          </Col>
        </Row>
        <div className="d-flex justify-content-between mt-2">
        <div><Button onClick={handleReset} className="me-1">
        리셋
          </Button>
          </div>
          <div><Button onClick={handleClose} className="me-1">
            닫기
          </Button>
          <Button htmlType="submit" type="primary">
            검색
          </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default SearchFilter;
