import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeBusinessOnboarding } from '../../redux/reducers/layoutSlice';
import { apiClientV2 } from '../../services/axios';
import PDFViewer from '../components/PdfViewer';
import './style.scss';

const BusinessOnboarding = ({ is_active }) => {
  const dispatch = useDispatch();
  const { isOpenBusinessOnboarding } = useSelector((state) => state.layout);
  const [openPDFView, setOpenPDFView] = useState(false);
  const [pdfFile, setPdfFile] = useState(process.env.PUBLIC_URL + '/onboarding/first_button.pdf');
  const updateUser = async (body) => {
    const res = await apiClientV2.put('/user/me', body);
    return res;
  };

  const skip = async () => {
    try {
      const res = await updateUser({
        registered: true
      });
    } catch (error) {
      console.log(error.response?.data?.message || 'Error');
    }
  };

  useEffect(() => {
    if (is_active) {
      skip();
    }
  }, [is_active]);

  return (
    <>
      <Modal
        title={false}
        closable={false}
        onCancel={() => dispatch(closeBusinessOnboarding())}
        footer={null}
        open={isOpenBusinessOnboarding}
        className="business-onboarding"
        contentWrapperStyle={{
          overflow: 'scroll'
        }}>
        <div className="d-flex justify-content-between">
          <p
            style={{
              fontSize: '16px',
              color: '#101828',
              fontWeight: '700'
            }}>
            사업자 승인 완료
          </p>
        </div>
        <div>
          <p className="mt-2" style={{ fontSize: '14px' }}>
            서비스 이용 가이드를 확인하려면 아래 버튼을 클릭해 주세요
          </p>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => {
              setPdfFile(process.env.PUBLIC_URL + '/onboarding/first_button.pdf');
              setOpenPDFView(true);
            }}>
            서비스 이용안내
          </Button>
          <p className="mt-4" style={{ fontSize: '14px' }}>
            사업자회원 전용 온라인결제 카드발급 안내를 받으려면 아래 버튼을 클릭해 주세요
          </p>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => {
              setPdfFile(process.env.PUBLIC_URL + '/onboarding/second_button.pdf');
              setOpenPDFView(true);
            }}>
            카드 발급 이용안내
          </Button>
          <p className="mt-4" style={{ fontSize: '14px' }}>
            지금 건너뛰어도 좌측 메뉴 버튼을 통해 다시 확인할 수 있어요
          </p>
          <Button type="default" block size="large" onClick={() => dispatch(closeBusinessOnboarding())}>
            건너뛰기
          </Button>
        </div>
      </Modal>
      <Modal
        centered
        open={openPDFView}
        width={false}
        bodyStyle={{ height: '100vh', overflow: 'scroll' }}
        footer={null}
        onCancel={() => {
          setOpenPDFView(false);
        }}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <PDFViewer file_view={pdfFile} />
      </Modal>
    </>
  );
};

export default BusinessOnboarding;
