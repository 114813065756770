import { forEach } from 'lodash';
import apiClient from '../../services/axios';

export const fetchRefunds = async (query) => {
  const params = new URLSearchParams();
  forEach(query, (value, key) => value && params.append(key, value));
  const response = await apiClient.get('/refund', { params });
  if (response.status === 200) {
    // Temporary
    response.data.remaining = Math.max(response.data.remaining, 0);
    return response.data;
  }
  return null;
};

export const fetchRefundAggs = async () => {
  const response = await apiClient.get('/refund/agg');
  if (response.status === 200) {
    // Temporary
    response.data.remaining = Math.max(response.data.remaining, 0);
    return response.data;
  }
  return null;
};
