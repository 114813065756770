import { Card, Image, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ExternalLinkBlack, RightOutlined } from '../../../../asset/icons/Icons';
import { ORDER_STATUS } from '../../../../common/utilities/constants';
import StatusTag from '../../../../common/components/StatusTag';
import { formatCurrency } from '../../../../utility/utils';
import ClickPreviewImages from './ClickPreviewImages';
const { Meta } = Card;

const ProductCard = ({ data, role }) => {
  const navigate = useNavigate();

  const handleClickRightIcon = () => {
    navigate(`/order-product`);
  };

  return (
    <>
      <Card
        title={dayjs(data?.updated_at).format('YYYY.MM.DD')}
        extra={
          <div className="d-flex justify-content-between" onClick={handleClickRightIcon}>
            <div className="ms-2">
              <RightOutlined />
            </div>
          </div>
        }
        bodyStyle={{ padding: '0' }}>
        {data?.products?.map((item, idx) => {
          const configArr = ORDER_STATUS.filter((i) => i.value === item.status);
          const config = configArr.length > 0 ? configArr[0] : null;
          let invoice_pictures = item?.purchase_request_product?.invoice_pictures;
          if (role === 'free_t' && item?.purchase_request_product?.is_ordered === false) {
            invoice_pictures = item?.inventories?.[0]?.invoice?.pictures || [];
          }
          return (
            <div
              key={idx}
              style={{
                padding: '12px',
                borderTop: idx === 0 && '1px solid #D0D5DD',
                borderBottom: idx < data?.products?.length - 1 && '1px solid #D0D5DD'
              }}>
              <div className="d-flex justify-content-between mb-2">
                <a
                  className="d-flex"
                  style={{
                    textDecoration: 'underline',
                    color: '#297DCB'
                  }}
                  onClick={() => navigate(`/order-product?order_id=${item.order_id}`)}>
                  <p style={{ paddingRight: '5px' }}>{item.order_product_number}</p> <ExternalLinkBlack />
                </a>
                <StatusTag status={item.status} opts={ORDER_STATUS} />
              </div>
              <Meta
                avatar={
                  <Image
                    style={{
                      width: '76px',
                      height: '76px',
                      objectFit: 'cover',
                      borderRadius: '5px'
                    }}
                    src={item?.product?.thumbnail_image?.file_view || ''}
                    alt=""
                  />
                }
                description={
                  <>
                    <Typography.Paragraph
                      ellipsis={true}
                      style={{ marginBottom: '0', color: '#667085', fontSize: '12px' }}>
                      {item?.brand?.name_en}
                    </Typography.Paragraph>
                    <Typography.Paragraph ellipsis={true} style={{ marginBottom: '0', fontSize: '12px' }}>
                      {item?.product?.name_en}
                    </Typography.Paragraph>
                    <Typography.Paragraph
                      ellipsis={true}
                      style={{
                        marginBottom: '0',
                        color: '#667085',
                        fontSize: '12px'
                      }}>{`${item?.quantity}/${
                      item?.product_variant?.properties?.map((p) => p.value).join(', ') || 'No variants'
                    }`}</Typography.Paragraph>
                    <Typography.Paragraph
                      ellipsis={true}
                      style={{ marginBottom: '0', fontWeight: 700, fontSize: '12px' }}>
                      €{formatCurrency(item?.product?.price)}
                    </Typography.Paragraph>
                  </>
                }
              />
              <div className="d-flex justify-content-end mt-2">
                {data?.purchase_request?.photo_inspection && item?.inspection_images?.length > 0 && (
                  <ClickPreviewImages
                    title={
                      <div className="d-flex">
                        <p style={{ paddingRight: '5px' }}>검수 사진 확인</p> <ExternalLinkBlack />
                      </div>
                    }
                    images={item?.inspection_images}
                  />
                )}
                {invoice_pictures?.length > 0 && (
                  <ClickPreviewImages
                    title={
                      <div className="d-flex">
                        <p style={{ paddingRight: '5px' }}>영수증</p> <ExternalLinkBlack />
                      </div>
                    }
                    images={invoice_pictures}
                    style={{
                      textDecoration: 'underline',
                      color: '#297DCB',
                      marginLeft: '7px'
                    }}
                  />
                )}
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div style={{ color: config?.amountColor || '#4BBF9F', fontWeight: 700 }}>
                  {item.status === 'delivered' ? '환급된 금액' : '환급 예정 금액'}
                </div>
                <div style={{ color: config?.amountColor || '#4BBF9F', fontWeight: 700 }}>
                  {item?.refund_currency?.symbol}
                  {formatCurrency(item?.refund_price, role === 'free_t' ? 2 : 0)}
                </div>
              </div>
            </div>
          );
        })}
      </Card>
    </>
  );
};

export default ProductCard;
