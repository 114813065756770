import { Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect } from 'react';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfoThunk } from '../../../redux/reducers/authSlice';
import BusinessInfoForm from './BusinesInfoForm';
import ProfileForm from './ProfileForm';
import { CURRENCIES } from '../../../common/utilities/constants';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const currency = userInfo.role == 'free_t' ? CURRENCIES.EUR : CURRENCIES.KRW;

  useEffect(() => {
    dispatch(fetchUserInfoThunk());
  }, []);

  return (
    <>
      <HeaderFT2 title={'회원 정보'} />
      <Space direction="vertical" style={{ backgroundColor: '#F2F4F7' }}>
        <Content style={{ padding: '24px 16px 24px 16px', backgroundColor: 'white' }}>
          <ProfileForm userInfo={userInfo} />
        </Content>
        {userInfo?.role == 'free_t' && (
          <Content style={{ padding: '24px 16px 24px 16px', backgroundColor: 'white' }}>
            <BusinessInfoForm businessInfo={userInfo?.business} currency={currency} />
          </Content>
        )}
      </Space>
    </>
  );
};

export default ProfilePage;
