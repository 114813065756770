import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import UploadFileCustom from '../../../../common/components/UploadFile';
import UploadSingleImage from '../../../../common/components/UploadSingleImage';

const ProductImagesModal = ({ isOpen, onCancel, isUpload = false, onSubmit }) => {
  const [productImg, setProductImg] = useState(null);
  const getProductImg = (file) => {
    setProductImg({
      file_name: 'Product Image URL',
      file_view: file,
      file_path: file,
      file_size: 1
    });
  };
  return (
    <>
      <Modal
        closable={false}
        maskClosable={false}
        open={isOpen}
        footer={false}
        centered
        title={<div className="text-16 gray-900 fw-7 mb-3">{isUpload ? '이미지 업로드' : '이미지 URL'}</div>}>
        <div className="w-100">
          {/* {isUpload ? <UploadFileCustom maxCount={1} onChange={getProductImg} /> : <Input placeholder="URL" size="large" onChange={(e) => {getProductImg()}} />} */}
          {isUpload ? (
            <UploadSingleImage value={productImg} onChangeValue={setProductImg} />
          ) : (
            <Form.Item
              name="product_image_url"
              rules={[
                {
                  validator: async (rule, value) => {
                    if (value && !/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+(\/[^\s]*)?$/.test(value)) {
                      throw new Error('잘못된 URL 형식');
                    }
                  }
                }
              ]}
              validateTrigger="onChange">
              <Input
                placeholder="URL"
                size="large"
                onChange={(e) => {
                  getProductImg(e.target.value);
                }}
              />
            </Form.Item>
          )}
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={onCancel} className="me-3" size="large">
            닫기
          </Button>
          <Button
            block
            type="primary"
            size="large"
            disabled={!productImg}
            onClick={() => {
              onSubmit(productImg);
              onCancel();
            }}>
            저장
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ProductImagesModal;
