import { Button } from 'antd';
import React, { useState } from 'react';
import TransferDrawer from './TransferDrawer';

const ApplyTransferButton = ({ withDrawer = true, buttonProps, drawerProps }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style={{
          position: 'fixed',
          width: '420px',
          bottom: 0,
          height: '48px',
          color: 'white',
          backgroundColor: '#10264D',
          border: 'none',
          borderRadius: 0,
          fontSize: 20
        }}
        {...buttonProps}>
        이체 신청
      </Button>
      {withDrawer && (
        <TransferDrawer
          open={open}
          onSubmitted={() => setOpen(false)}
          onClose={() => setOpen(false)}
          {...drawerProps}
        />
      )}
    </>
  );
};

export default ApplyTransferButton;
