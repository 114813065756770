import { forEach } from 'lodash';
import apiClient from '../../services/axios';

export const fetchDeposits = async (query) => {
  const params = new URLSearchParams();
  forEach(query, (value, key) => value && params.append(key, value));
  const response = await apiClient.get('/deposit', { params });
  return response.status === 200 ? response.data : null;
};


export const fetchDepositAggs = async () => {
  const response = await apiClient.get('/deposit/agg');
  return response.status === 200 ? response.data : null;
};

export const createDeposit = async (data) => {
  try {
    const response = await apiClient.post('/deposit', data);
    return response.status === 201 ? response.data : null;
  } catch (error) {
    console.error('Error creating deposit:', error);
    return null;
  }
}

export const getInvoiceFile = async (deposit_id) => {
  try {
    const response = await apiClient.get(`/deposit/${deposit_id}/invoice`, { responseType: 'blob' });
    return response.status === 200 ? response.data : null;
  } catch (error) {
    console.error('Error getting invoice:', error);
    return null;
  }
};
